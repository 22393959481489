import _ from "lodash";
import { NavigateOptions, URLSearchParamsInit } from "react-router-dom";

export const resetQueryParam = (
  searchParams: URLSearchParams,
  setSearchParams: {
    (
      nextInit?:
        | URLSearchParamsInit
        | ((prev: URLSearchParams) => URLSearchParamsInit)
        | undefined,
      navigateOpts?: NavigateOptions | undefined
    ): void;
    (
      nextInit?:
        | URLSearchParamsInit
        | ((prev: URLSearchParams) => URLSearchParamsInit)
        | undefined,
      navigateOpts?: NavigateOptions | undefined
    ): void;
    (arg0: (prev: any) => any): void;
  },
  param: string,
  newValue?: any | null
) => {
  // Check if same value
  if (_.isNil(newValue) || searchParams.get(param) !== _.toString(newValue)) {
    // Reset if different or empty
    setSearchParams((prev) => {
      prev.delete(param);
      if (!_.isNil(newValue)) prev.set(param, _.toString(newValue));
      return prev;
    });
  }
};

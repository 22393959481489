import { ComponentType, LazyExoticComponent, ReactNode, Suspense } from "react";

import Loader, { LoaderProps } from "./Loader";

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable =
  (
    Component: LazyExoticComponent<() => JSX.Element> | ComponentType<ReactNode>
  ) =>
  (props: LoaderProps) => (
    <Suspense fallback={<Loader />}>
      {/* @ts-ignore */}
      <Component {...props} />
    </Suspense>
  );

export default Loadable;

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { useFormikContext } from "formik";
import _ from "lodash";

import { formatStringForDisplay } from "components/form/standard/utils/formatting";
import { useEditing } from "contexts/EditingContext";
import { CreoneFieldProps } from "types/standardForm";

export function FieldLabel({
  required,
  fieldName,
  disabled,
  displayName,
}: {
  required: boolean;
  fieldName: string;
  disabled?: boolean;
  displayName: string;
}) {
  return (
    <InputLabel required={required} htmlFor={fieldName} disabled={disabled}>
      {displayName}
    </InputLabel>
  );
}
export const LightTooltip = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const InfoTooltip = (props: { title: string }) => {
  const { title } = props;
  return (
    <LightTooltip title={title}>
      {/*@ts-ignore*/}
      <InfoOutlinedIcon fontSize={"11px"} />
    </LightTooltip>
  );
};

function FieldValueReadOnly(props: {
  formatForDisplay: ((x: any) => string | null) | undefined;
  fieldName: string;
}) {
  const { getFieldProps } = useFormikContext();
  const { value } = getFieldProps(props.fieldName);

  return (
    <Typography variant={"subtitle1"}>
      {formatStringForDisplay(
        typeof props.formatForDisplay === "function"
          ? props.formatForDisplay(value)
          : value
      )}
    </Typography>
  );
}

export function CreoneField({
  children,
  displayName,
  fieldName,
  required = false,
  showLabel = true,
  showError = true,
  disabled = false,
  isVerticalLabel,
  topMargin = 0,
  componentProps,
  formatForDisplay,
}: CreoneFieldProps) {
  const { touched, errors, submitCount } = useFormikContext();
  const theme = useTheme();
  const { isEditing } = useEditing();

  const _is_vertical_label =
    isVerticalLabel ?? componentProps?.isVerticalLabel ?? true;
  return (
    <Box sx={{ mt: topMargin, width: "100%", overflow: "visible" }}>
      <Stack
        direction={_is_vertical_label ? "column" : "row"}
        alignItems={_is_vertical_label ? "start" : "center"}
        spacing={theme.spacing(0.5)}
      >
        {showLabel && (
          <Stack direction={"row"} spacing={1}>
            <FieldLabel
              required={required && !!displayName && isEditing}
              disabled={disabled}
              displayName={displayName || "\u00A0"}
              fieldName={fieldName}
            />
            {componentProps && componentProps?.tooltip && (
              <InfoTooltip title={componentProps?.tooltip} />
            )}
          </Stack>
        )}
        {isEditing ? (
          <FormControl fullWidth>
            {children}
            {showError &&
              submitCount > 0 &&
              _.get(touched, fieldName) &&
              _.get(errors, fieldName) && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text"
                  sx={{ mx: 0 }}
                >
                  {_.get(errors, fieldName)}
                </FormHelperText>
              )}
          </FormControl>
        ) : (
          <FieldValueReadOnly
            fieldName={fieldName}
            formatForDisplay={formatForDisplay}
          />
        )}
      </Stack>
    </Box>
  );
}

import { InputAdornment } from "@mui/material";
import { useField } from "formik";
import _ from "lodash";
import NumberFormat from "react-number-format";

import { CurrencyTextField } from "components/form/NumberFormatTextField";
import { FieldComponentProps } from "types/standardForm";

import { CreoneField } from "./basic/creone_field";

interface TextFieldCurrencyProps extends FieldComponentProps {
  size?: any;
}

function GetTextFieldCurrency({
  size,
  fieldName,
  disabled,
}: TextFieldCurrencyProps) {
  const [field, , helpers] = useField(fieldName);

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <NumberFormat
      size={size}
      fullWidth
      id={fieldName}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      placeholder="0.00"
      value={field.value}
      disabled={disabled}
      onValueChange={(val) => {
        helpers.setValue(_.isUndefined(val.floatValue) ? null : val.floatValue);
      }}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      onBlur={handleBlur}
      customInput={CurrencyTextField}
    />
  );
}

export function TextFieldCurrency(props: TextFieldCurrencyProps) {
  return (
    <CreoneField {...props}>
      <GetTextFieldCurrency
        displayName={props.displayName}
        size={"medium"}
        fieldName={props.fieldName}
        disabled={props.disabled}
        additionalChangeHandler={props.additionalChangeHandler}
      />
    </CreoneField>
  );
}

import { dateFormats } from "pages/data_import/constants/common";
import { getUserFullName } from "pages/deal/utils/deal";
import { SimpleTeamMember } from "types/auth";

import { getActitivtyTypeValidators } from "./newContactColumns";

export const getNewActivityImportColumns = (
  brokerValues: Array<{ value: string; display_label: string }>,
  clientUser?: SimpleTeamMember
) => {
  return [
    {
      column_name: "contact_external_id",
      display_label: "Contact External ID",
      type: "text",
      required: true,
    },
    {
      column_name: "owner",
      display_label: "Activity Owner",
      type: "list",
      validators: {
        values: brokerValues,
        case_sensitive: false,
      },
      required: false,
      default_value: getUserFullName(clientUser),
      matching_keywords: "Owner",
    },
    {
      column_name: "subject",
      display_label: "Subject",
      type: "text",
      required: false,
    },
    {
      column_name: "date",
      display_label: "Date",
      type: "date",
      validators: {
        format: dateFormats,
      },
      required: true,
      matching_keywords: "Activity Date",
    },
    {
      column_name: "activity_type_v2",
      display_label: "Activity Type",
      type: "list",
      validators: getActitivtyTypeValidators(),
      default_value: "Call",
      required: false,
    },
    {
      column_name: "is_complete",
      display_label: "Is Complete?",
      type: "boolean",
      required: false,
    },
    {
      column_name: "notes_plaintext",
      display_label: "Notes",
      type: "text",
      required: false,
    },
  ];
};

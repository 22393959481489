import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { AppBar, AppBarProps, Toolbar, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactNode, useMemo } from "react";

import IconButton from "components/@extended/IconButton";
import useAuth from "hooks/useAuth";

import AppBarStyled from "./AppBarStyled";
import HeaderContent from "./HeaderContent";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

export const HeaderBar = ({
  open,
  isBottom = false,
  children,
}: {
  open: boolean;
  isBottom?: boolean;
  children: ReactNode;
}) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  // app-bar params
  const appBar: AppBarProps = isBottom
    ? {
        position: "fixed",
        color: "inherit",
        elevation: 0,
        sx: {
          borderTop: `1px solid ${theme.palette.divider}`,
          zIndex: 1200,
          bottom: 0,
          top: "auto",
        },
      }
    : {
        position: "fixed",
        color: "inherit",
        elevation: 0,
        sx: {
          borderBottom: `1px solid ${theme.palette.divider}`,
          zIndex: 1200,
        },
      };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {children}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{children}</AppBar>
      )}
    </>
  );
};

const Header = ({
  open,
  handleDrawerToggle,
}: {
  open: boolean;
  handleDrawerToggle?: () => void;
}) => {
  const theme = useTheme();
  const { user } = useAuth();
  const isImpersonation = user?.impersonation;

  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);

  const iconBackColorOpen =
    theme.palette.mode === "dark" ? "grey.200" : "grey.100";
  const iconBackColor =
    theme.palette.mode === "dark" ? "background.default" : "grey.100";

  // common header
  const mainHeader: ReactNode = (
    <Toolbar sx={isImpersonation ? { backgroundColor: "#9377A7" } : undefined}>
      <IconButton
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        color="secondary"
        variant="light"
        sx={{
          color: "text.primary",
          bgcolor: open ? iconBackColorOpen : iconBackColor,
          ml: { xs: 0, lg: -2 },
        }}
      >
        {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </IconButton>
      {headerContent}
    </Toolbar>
  );

  return <HeaderBar open={open}>{mainHeader}</HeaderBar>;
};

export default Header;

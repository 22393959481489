import { Call, EmailOutlined, EventNote } from "@mui/icons-material";
import dayjs from "dayjs";
import _ from "lodash";
import { ElementType } from "react";

import { LightTooltip } from "pages/deal/components/InfoTooltip";
import { ActivityType } from "types/activity";
import { Activity, ActivityRead } from "types/api/deal/activity";

export const getDetailsFromActivityType = (
  activityType: ActivityType | string
): {
  icon: ElementType;
  label: string;
} => {
  if (activityType === ActivityType.call) return { icon: Call, label: "Call" };
  if (activityType === ActivityType.email)
    return { icon: EmailOutlined, label: "Email" };
  return { icon: EventNote, label: "Other" };
};

export const getIconFromActivityType = (
  activityType: ActivityType | string
): ElementType => {
  const { icon } = getDetailsFromActivityType(activityType);
  return icon;
};

export const getIconNodeFromActivityType = (
  activityType: ActivityType | string
): React.ReactNode => {
  const { icon: Icon, label } = getDetailsFromActivityType(activityType);
  return (
    <LightTooltip title={label}>
      <Icon />
    </LightTooltip>
  );
};

export function getActivityPreviewFormat(
  record: ActivityRead | Partial<Activity>,
  usersDisplay: Record<string, string>
) {
  const datetime = dayjs(record.date).toDate();
  const ownerName = _.get(usersDisplay, `${record.owner}`, null);
  const avatarContents = getIconNodeFromActivityType(
    _.get(record, "activity_type_v2", "other")
  );
  const title = record.subject ?? "";
  const activityBodyPreview = !!record.notes_plaintext
    ? record.notes_plaintext.length > 255
      ? record.notes_plaintext.substring(0, 255)
      : record.notes_plaintext
    : "";
  const activityBody = record.notes_plaintext ?? "";
  return {
    datetime,
    ownerName,
    avatarContents,
    title,
    activityBodyPreview,
    activityBody,
  };
}

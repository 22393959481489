import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DealFormType } from "constants/formLayouts/dealForms";
import { defaultModalColumns } from "pages/deal/constants/dealTables";
import { Deal } from "types/deal";
import { DealFilter } from "types/navigation/dealNavigation";

import { dispatch } from "../index";

interface DealState {
  editing: boolean;
  editingNotes: boolean;
  currentEditing: number | null;
  currentEditingForm: DealFormType | null;
  currentEditingIncomingChanges?: Partial<Deal>;
  viewing: boolean;
  currentViewing: DealFilter | null;
  currentViewingColumns: string[] | null;
}

const initialState: DealState = {
  editing: false,
  editingNotes: false,
  currentEditing: null,
  currentEditingForm: DealFormType.main,
  currentEditingIncomingChanges: {},
  viewing: false,
  currentViewing: null,
  currentViewingColumns: null,
};

const slice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    // Start Viewing
    startViewing: {
      reducer(
        state: DealState,
        action: PayloadAction<DealFilter, string, { viewColumns: string[] }>
      ) {
        state.viewing = true;
        state.currentViewing = action.payload;
        state.currentViewingColumns = action.meta.viewColumns;
      },
      prepare: (payload: DealFilter, viewColumns: string[]) => {
        return { payload, meta: { viewColumns } };
      },
    },
    // Stop Viewing
    stopViewing(state: DealState) {
      state.viewing = false;
      state.currentViewing = null;
      state.currentViewingColumns = null;
    },
    // Start Editing
    startEditing: {
      reducer(
        state: DealState,
        action: PayloadAction<
          number | null,
          string,
          { formType: DealFormType; incomingChanges?: Partial<Deal> }
        >
      ) {
        state.editing = true;
        state.currentEditing = action.payload;
        state.currentEditingForm = action.meta.formType;
        state.currentEditingIncomingChanges = action.meta.incomingChanges;
      },
      prepare: (
        payload: number | null,
        formType: DealFormType,
        incomingChanges?: Partial<Deal>
      ) => {
        return { payload, meta: { formType, incomingChanges } };
      },
    },
    // Stop Editing
    stopEditing(state: DealState) {
      state.editing = false;
      state.currentEditing = null;
      state.currentEditingForm = null;
      state.currentEditingIncomingChanges = {};
    },
    // Start Editing Notes
    startEditingNotes(state: DealState, action: PayloadAction<number>) {
      state.editingNotes = true;
      state.currentEditing = action.payload;
    },
    // Stop Editing Notes
    stopEditingNotes(state: DealState) {
      state.editingNotes = false;
      state.currentEditing = null;
    },
  },
});

// Reducer
export default slice.reducer;

export function startViewingDeals(filter: DealFilter, viewColumns?: string[]) {
  dispatch(
    slice.actions.startViewing(filter, viewColumns ?? defaultModalColumns)
  );
}

export function stopViewingDeals() {
  dispatch(slice.actions.stopViewing());
}

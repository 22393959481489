import { SimpleOption } from "types/api/deal/form";
import { DealOption } from "types/dealOption";

export interface KeyDateRead {
  id: number;
  deal_id?: number | null;
  date_type_id: number;
  date: Date;
  date_type: DealOption;
}

export interface KeyDatesUpdate {
  id?: number;
  date_type_id?: number;
  date_type: SimpleOption | null;
  date?: Date;
  is_delete: boolean;
}

export const emptyKeyDateUpdate = {
  date: null,
  date_type: null,
  is_delete: false,
};

export interface CalendarEventInput {
  id: string;
  title: string;
  start: Date;
  allDay?: boolean;
  color?: string;

  // Additional properties
  deal_id?: number;
  is_key_date: boolean;
  date_type_id?: number;
  deal_field_name?: string;
  description?: string;
}

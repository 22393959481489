import { InfoCircleFilled } from "@ant-design/icons";
import { Alert, Box, Link } from "@mui/material";
import { useEffect, useState } from "react";

import { axiosUserServices } from "utils/axios";

const ExpiredSubscriptionAlert = () => {
  const [stripeSession, setStripeSession] = useState("");

  useEffect(() => {
    axiosUserServices.post("/account/portal").then((response) => {
      setStripeSession(response.data.url);
    });
  }, []);

  return (
    <Box sx={{ pb: 2 }}>
      <Link href={stripeSession} style={{ textDecoration: "none" }}>
        <Alert color="error" icon={<InfoCircleFilled />}>
          You have an expired subscription. Click here to update your
          subscription.
        </Alert>
      </Link>
    </Box>
  );
};

export default ExpiredSubscriptionAlert;

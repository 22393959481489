import _ from "lodash";

import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { OptionCreate, OptionUpdate } from "types/api/deal/option";
import { DealOption } from "types/dealOption";
import { dealService } from "utils/axios";
import { handleRecordActionResponseV2 } from "utils/record";

export function createDealOption(DealOption: DealOption) {
  return dealService
    .post(`/option/`, DealOption)
    .then(function (response) {
      const data = _.get(response.data, "data");
      return data;
    })
    .catch(function (error) {
      dispatch(openErrorNotification("Error creating option."));
    });
}

export async function createOptionAsync(option: OptionCreate) {
  try {
    const response = await dealService.post("/option/", option);
    handleRecordActionResponseV2(`Value`, "created", null, response);
    return response.data.data;
  } catch (error) {
    dispatch(openErrorNotification("Error creating value."));
    throw error;
  }
}

export async function updateOptionAsync(
  id: number | string,
  option: OptionUpdate
) {
  try {
    const response = await dealService.put(`/option/${id}`, option);
    handleRecordActionResponseV2(`Value`, "updated", null, response);
    return response.data.data;
  } catch (error) {
    dispatch(openErrorNotification("Error updating value."));
    throw error;
  }
}

export function syncDealOptions() {
  dealService
    .post(`/option/sync/`, null)
    .then(function (response) {})
    .catch(function (error) {
      console.error(error);
    });
}

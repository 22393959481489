import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { Company } from "types/company";
import { dealService } from "utils/axios";
import { handleRecordActionResponseV4 } from "utils/record";

export async function createCompanyAsync(company: Company) {
  try {
    const response = await dealService.post(`/company/`, company);
    handleRecordActionResponseV4(response);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification("Error creating company."));
    throw error;
  }
}

export async function updateCompanyAsync(
  id: number | string,
  company: Partial<Company>
) {
  try {
    const response = await dealService.put(`/company/${id}`, company);
    handleRecordActionResponseV4(response);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification("Error updating company."));
    throw error;
  }
}

export async function deleteCompanyAsync(id: number | string) {
  try {
    const response = await dealService.delete(`/company/${id}`);
    handleRecordActionResponseV4(response);
  } catch (error) {
    dispatch(openErrorNotification("Error deleting company."));
    throw error;
  }
}

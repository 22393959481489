import { Container, DialogContent, DialogTitle, Grid } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import * as Sentry from "@sentry/react";
import _ from "lodash";
import { useMemo } from "react";

import StandardField from "components/form/standard/StandardField";
import { StandardForm } from "components/form/standard/StandardForm";
import MainCard from "components/MainCard";
import { DealMetadata } from "constants/objectMetadata/dealMetadata";
import { OptionMetadata } from "constants/objectMetadata/optionMetadata";
import { CloseButton } from "pages/deal/components/CloseButton";
import { startEditingRecord } from "store/reducers/record";
import { Option, OptionCreate, OptionUpdate } from "types/api/deal/option";
import { FormIdentifier, StandardFormProps } from "types/record";
import { FormSubmitAction } from "types/standardForm";
import { createOptionAsync, updateOptionAsync } from "utils/dealOption";

export const OptionValueForm = ({
  record,
  incomingChanges,
  onCancel,
  successCallback,
  open,
  showAddAnother,
}: StandardFormProps<Option>) => {
  const createFnAsync = async (
    option: OptionCreate,
    submitAction: FormSubmitAction = FormSubmitAction.save
  ) => {
    try {
      const record = await createOptionAsync(option);

      if (typeof successCallback === "function" && _.isObject(record))
        successCallback(record);

      if (submitAction === FormSubmitAction.saveAndAddAnother) {
        await new Promise((resolve) => setTimeout(resolve, 200));
        startEditingRecord(
          null,
          FormIdentifier.OptionForm,
          { ...incomingChanges },
          true
        );
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const updateFnAsync = async (id: number | string, option: OptionUpdate) => {
    try {
      // Only permit updates for value
      const updatedOption = { value: option.value };
      const record = await updateOptionAsync(id, updatedOption);
      if (typeof successCallback === "function" && _.isObject(record))
        successCallback(record);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const editingOptionName = useMemo(
    () =>
      _.get(DealMetadata, [
        `${_.get(record, "field") || _.get(incomingChanges, "field")}`,
        "displayName",
      ]),
    [record, incomingChanges]
  );

  return (
    <StandardForm<Option>
      metadata={OptionMetadata}
      record={record}
      updateFn={updateFnAsync}
      createFn={createFnAsync}
      displayName={editingOptionName}
      onCancel={onCancel}
      incomingChanges={incomingChanges}
      showAddAnother={showAddAnother}
      fullWidth={true}
      open={open}
      maxWidth={"xs"}
    >
      {({ StandardFormActionsInstance }) => (
        <>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <DialogTitle>
                {!!record ? "Edit" : "Add"} {editingOptionName} Value
              </DialogTitle>
            </Grid>
            <Grid item sx={{ mr: 1.5 }}>
              <CloseButton onClose={onCancel} />
            </Grid>
          </Grid>
          <DialogContent>
            <Container>
              <MainCard>
                <StandardField field={OptionMetadata.value} />
              </MainCard>
            </Container>
          </DialogContent>
          <DialogActions>{StandardFormActionsInstance}</DialogActions>
        </>
      )}
    </StandardForm>
  );
};

export enum ChartToggleOption {
  table = "table",
  pie = "pie",
  bar = "bar",
}

export type BrokerWidgetsConfig = {
  executing_broker: ChartToggleOption;
  sourcing_broker: ChartToggleOption;
  shared_brokerage: ChartToggleOption;
  shared_broker: ChartToggleOption;
  outside_brokerage: ChartToggleOption;
  outside_broker: ChartToggleOption;
};

export const defaultBrokerWidgetsConfig: BrokerWidgetsConfig = {
  executing_broker: ChartToggleOption.table,
  sourcing_broker: ChartToggleOption.table,
  shared_brokerage: ChartToggleOption.bar,
  shared_broker: ChartToggleOption.bar,
  outside_brokerage: ChartToggleOption.bar,
  outside_broker: ChartToggleOption.bar,
};

import _ from "lodash";
import * as Yup from "yup";

import { CheckboxField } from "components/form/CheckboxField";
import { DatePicker } from "components/form/DatePicker";
import OwnerField from "components/form/OwnerField";
import ContactQuickView from "components/form/section/ContactQuickView";
import { SelectOption } from "components/form/SelectOption";
import { userOptionLoadHandler } from "components/form/standard/utils/metadata";
import {
  TextFieldString,
  TextFieldStringMultiline,
} from "components/form/TextFieldString";
import { useGetCurrentUserAsOption } from "hooks/useGetUserAsOption";
import { ContactLookupField } from "pages/deal/components/LookupField";
import { ACTIVITY_TYPE_OPTIONS } from "pages/deal/constants/activity";
import {
  dateSubmitHandler,
  parseContactAsSimpleOption,
  simpleOptionSubmitHandler,
} from "pages/deal/utils/deal_form";
import { ActivityTypeEnum } from "types/api/deal/enum";
import { SimpleOption } from "types/api/deal/form";
import { ObjectMetadata } from "types/standardForm";

export const ActivityMetadata: ObjectMetadata = {
  _contact_quick_view: {
    fieldName: "_contact_quick_view",
    displayName: "",
    initialValue: false,
    _schema: Yup.boolean(),
    virtual: true,
    component: ContactQuickView,
  },
  owner: {
    fieldName: "owner",
    displayName: "Activity Owner",
    visibility: () => true,
    initialValue: useGetCurrentUserAsOption,
    _schema: Yup.object()
      .required(`Activity Owner is required.`)
      .typeError("Activity Owner is required."),
    component: OwnerField,
    submitHandler: (x: SimpleOption) => x.key,
    loadHandler: userOptionLoadHandler,
  },
  name: {
    fieldName: "name",
    displayName: "Contact Name",
    initialValue: "",
    _schema: Yup.string().max(255, "Name must be less than 256 characters."),
    component: TextFieldString,
  },
  subject: {
    fieldName: "subject",
    displayName: "Subject",
    initialValue: "",
    _schema: Yup.string()
      .required(`Activity Subject is required.`)
      .max(255, "Subject must be less than 256 characters."),
    component: TextFieldString,
  },
  notes_plaintext: {
    fieldName: "notes_plaintext",
    displayName: "Notes",
    initialValue: "",
    _schema: Yup.string()
      .max(10000, "Notes must be less than 10,000 characters.")
      .nullable(),
    component: TextFieldStringMultiline,
  },
  activity_type_v2: {
    fieldName: "activity_type_v2",
    displayName: "Activity Type",
    options: ACTIVITY_TYPE_OPTIONS,
    initialValue: ActivityTypeEnum.call,
    _schema: Yup.string().required(`Activity Type is required.`),
    component: SelectOption,
    formatForDisplay: (x) =>
      _.get(_.find(ACTIVITY_TYPE_OPTIONS, { key: x }), "label", ""),
  },
  completed_date: {
    fieldName: "completed_date",
    displayName: "Completed Date",
    initialValue: null,
    _schema: Yup.date().nullable().typeError(`Completed Date must be a date.`),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
  },
  date: {
    fieldName: "date",
    displayName: "Date",
    initialValue: null,
    _schema: Yup.date()
      .required(`Activity Date is required.`)
      .typeError(`Activity Date must be a date.`),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
  },
  is_complete: {
    fieldName: "is_complete",
    displayName: "Completed?",
    initialValue: false,
    _schema: Yup.boolean(),
    component: CheckboxField,
    additionalChangeHandler: (value, setFieldValue, _prevValue, values) => {
      if (value) {
        // Checkbox set to true
        setFieldValue("completed_date", new Date());
        setFieldValue("date", new Date());
      }
      // Handle followup if record is being edited
      if (_.get(values, "id") && _.isNumber(values?.id)) {
        if (value) {
          setFieldValue("is_followup", true);
        } else {
          setFieldValue("is_followup", null);
        }
      }
    },
  },
  contact: {
    fieldName: "contact",
    displayName: "Contact",
    initialValue: null,
    _schema: Yup.object().required("Contact is required."),
    component: ContactLookupField,
    submitHandler: simpleOptionSubmitHandler,
    loadHandler: parseContactAsSimpleOption,
    fieldCorrespondence: "contact_id",
  },
};

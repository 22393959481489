// action - state management
import { AuthActionProps, AuthProps } from "types/auth";

import { LOGIN, LOGOUT, REFRESH, REGISTER } from "./actions";

// initial state
export const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  token_header_payload: null,
};

// ==============================|| AUTH REDUCER ||============================== //

const auth = (state = initialState, action: AuthActionProps) => {
  switch (action.type) {
    case REGISTER: {
      const { user, token_header } = action.payload!;
      return {
        ...state,
        user,
        token_header,
      };
    }
    case LOGIN: {
      const { user } = action.payload!;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user,
      };
    }
    case REFRESH: {
      const { user } = action.payload!;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null,
        token_header_payload: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default auth;

import { Button, Stack, Typography } from "@mui/material";
import React from "react";

// ============================|| JWT - LOGIN ||============================ //
const AuthRegisterNoProduct = () => {
  // Define the subject and body for the email
  const emailSubject = encodeURIComponent("Need Help with Signing Up");
  const emailBody = encodeURIComponent(
    "Hello,\n\nI encountered an issue while trying to access my account and require assistance.\n\nThank you."
  );

  return (
    <Stack spacing={2} direction={"column"}>
      <Typography>
        This registration link has expired. Please contact support to continue
        Signing Up.
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
        spacing={2}
      >
        <Button
          variant="contained"
          href={`mailto:support@creonesource.com?subject=${emailSubject}&body=${emailBody}`}
          fullWidth
        >
          Contact Support
        </Button>
      </Stack>
    </Stack>
  );
};

export default AuthRegisterNoProduct;

import { createSlice } from "@reduxjs/toolkit";

import { dispatch } from "store";

export type LenderDealFilters = {
  search: string | undefined;
};

const initialState: { initial: LenderDealFilters; current: LenderDealFilters } =
  {
    initial: {
      search: undefined,
    },
    current: {
      search: undefined,
    },
  };

const lenderDealFilters = createSlice({
  name: "lenderDealFilter",
  initialState,
  reducers: {
    setFilters(state, action) {
      state.current = action.payload;
    },
    setInitialFilters(state, action) {
      state.initial = action.payload;
      state.current = action.payload;
    },
    resetFilters(state) {
      state.current = state.initial;
    },
  },
});

export default lenderDealFilters.reducer;

export const setFilters = (filters: LenderDealFilters) =>
  dispatch(lenderDealFilters.actions.setFilters(filters));
export const setInitialFilters = (filters: LenderDealFilters) =>
  dispatch(lenderDealFilters.actions.setInitialFilters(filters));
export const resetFilters = () =>
  dispatch(lenderDealFilters.actions.resetFilters());

import { GridSize } from "@mui/material";
import { FormikValues } from "formik";
import { ExoticComponent } from "react";

import { DealOrgPreferences } from "types/deal";

import { Product } from "./license";

export type SelectOptionType = {
  key: string | number;
  label: string | any | null;
}[];

export type AdditionalChangeHandlerType = (
  value: any,
  setFieldValue: any,
  prevValue?: any,
  values?: FormikValues
) => void;

export type FieldMetadata = {
  // Yup schema used in the formik object for data validation
  _schema: object | ((dealOrgPreferences: DealOrgPreferences | null) => object);
  // Name seen above the input field
  displayName: string;
  // name used in the formik object to set and retrieve values.  Should be snake_case
  fieldName: string;
  // initial value of the field when created
  initialValue: (() => object) | any;
  options?: SelectOptionType;
  /**
   * function that is used to see a form field.  It is passed the record value. Defaults to being visible.
   */
  visibility?: (
    values: FormikValues,
    dealOrgPreferences: DealOrgPreferences | null
  ) => boolean;
  disabled?: (values: FormikValues) => boolean;
  component: (props: FieldComponentProps) => JSX.Element;
  // props passed onto the component.  Component needs to specify how they are being used
  componentProps?: CREOneFieldProps & {
    sx?: object;
    tooltip?: any;
  };
  submitHandler?: (value: any) => any;
  loadHandler?: (
    value: any,
    userOptionsDictionary: Record<string, string>
  ) => any;
  fieldCorrespondence?: string;
  virtual?: boolean;
  additionalChangeHandler?: AdditionalChangeHandlerType;
  // Products able to see this form field
  products?: Product[];
  formatForDisplay?: (value: any) => string | null;
};

export type ObjectMetadata = Record<string, FieldMetadata>;

export type generateFieldComponentFormFieldProps = {
  field: FieldMetadata;
  // These are props that are determined by the dealForm constant to give different properties depending on the form
  formFieldProps?: ChangeableComponentProps;
  width?: boolean | GridSize;
  sx?: object;
};

export type FormRow = generateFieldComponentFormFieldProps[];

export type StandardFormLayout = {
  title?: {
    formRow?: FormRow;
  };
  content: Array<{
    component: ((props: any) => JSX.Element) | ExoticComponent | any;
    formRows: FormRow[][] | FormRow[];
    headerProps?: {
      heading?: string;
      visibility?: (values: FormikValues) => boolean;
      defaultExpanded?: (values: FormikValues) => boolean;
      collapsible?: boolean;
      // Products able to see this section
      products?: Product[];
    };
  }>;
};

export interface CREOneFieldProps {
  displayName?: string;
  options?: SelectOptionType;
  additionalChangeHandler?: (
    value: any,
    setFieldValue: any,
    prevValue?: any,
    values?: FormikValues
  ) => void;
  // if true the filed will be required for submitting
  required?: boolean;
  // if true the label will be shown
  showLabel?: boolean;
  // if true the error message will be shown
  showError?: boolean;
  // if true the field will not be editable and shown disabled to user
  disabled?: boolean;
  // If true the label is put on top
  isVerticalLabel?: boolean;
  // A value to define the top margin
  topMargin?: number;
  // track if the field has been touched
  touch?: boolean;
  formatForDisplay?: (x: any) => string | null;
}

export interface ChangeableComponentProps extends CREOneFieldProps {
  // props passed onto the component.  Component needs to specify how they are being used
  componentProps?: CREOneFieldProps & {
    sx?: object;
    tooltip?: string;
  };
}

export interface FieldComponentProps extends ChangeableComponentProps {
  // props passed onto the component.  Component needs to specify how they are being used
  fieldName: string;
}

export type CreoneFieldProps = FieldComponentProps & { children: any };

export enum FormSubmitAction {
  save = "save",
  saveAndAddAnother = "saveAndAddAnother",
}

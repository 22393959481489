import { FormikValues } from "formik";
import _ from "lodash";
import * as Yup from "yup";

import { DatePicker } from "components/form/DatePicker";
import { SelectOption } from "components/form/SelectOption";
import { TextFieldString } from "components/form/TextFieldString";
import { DealOptionAutocompleteNew } from "pages/deal/components/PlaintextAutocomplete";
import { ACTIVITY_TYPE_OPTIONS } from "pages/deal/constants/activity";
import {
  dateSubmitHandler,
  parseDealOptionAsString,
} from "pages/deal/utils/deal_form";
import { FollowupToggleButton } from "pages/prospects/components/form/FollowupToggleButton";
import {
  ActivityFollowupOption,
  getDateForFollowupOption,
  SelectFollowupOption,
} from "pages/prospects/components/form/SelectFollowupOption";
import { ActivityTypeEnum } from "types/api/deal/enum";
import { ObjectMetadata } from "types/standardForm";

export const isLostReasonRequired = (
  is_followup: boolean | null,
  should_require_lost_reason: boolean | null
) => is_followup === false && should_require_lost_reason === true;

export const ActivityFollowupMetadataSubset: ObjectMetadata = {
  is_followup: {
    fieldName: "is_followup",
    displayName: "",
    initialValue: null,
    _schema: Yup.boolean().nullable(),
    component: FollowupToggleButton,
  },
  followup_option: {
    fieldName: "followup_option",
    displayName: "Due",
    initialValue: ActivityFollowupOption.oneWeek,
    _schema: Yup.string()
      .nullable()
      .when("is_followup", {
        is: true,
        then: (schema) => schema.required(`Activity Date is required.`),
      }),
    component: SelectFollowupOption,
  },
  followup_date: {
    fieldName: "followup_date",
    displayName: "Date",
    visibility: (values: FormikValues) =>
      _.get(values, "followup_option", null) === ActivityFollowupOption.custom,
    initialValue: getDateForFollowupOption(ActivityFollowupOption.oneWeek),
    _schema: Yup.date()
      .nullable()
      .when("is_followup", {
        is: true,
        then: (schema) => schema.required(`Activity Date is required.`),
      }),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
  },
  followup_activity_type_v2: {
    fieldName: "followup_activity_type_v2",
    displayName: "Activity Type",
    options: ACTIVITY_TYPE_OPTIONS,
    initialValue: ActivityTypeEnum.call,
    _schema: Yup.string()
      .nullable()
      .when("is_followup", {
        is: true,
        then: (schema) => schema.required(`Activity Type is required.`),
      }),
    component: SelectOption,
  },
  followup_subject: {
    fieldName: "followup_subject",
    displayName: "Subject",
    initialValue: "",
    _schema: Yup.string()
      .max(255, "Activity Subject must be less than 256 characters.")
      .when("is_followup", {
        is: true,
        then: (schema) => schema.required(`Activity Subject is required.`),
      }),
    component: TextFieldString,
  },
  should_require_lost_reason: {
    fieldName: "should_require_lost_reason",
    displayName: "",
    initialValue: null,
    _schema: Yup.boolean().nullable(),
    component: () => <></>,
  },
  lost_reason: {
    fieldName: "lost_reason",
    displayName: "Lost Reason",
    initialValue: null,
    _schema: Yup.string()
      .nullable()
      .when(["is_followup", "should_require_lost_reason"], {
        is: (
          is_followup: boolean | null,
          should_require_lost_reason: boolean | null
        ) => isLostReasonRequired(is_followup, should_require_lost_reason),
        then: (schema) => schema.required(`A Lost Reason is required.`),
      }),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: "lost_reason_name",
  },
};

import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";
import NumberFormat from "react-number-format";

import { colorRaisinBlack } from "../utils/reporting";

export const AggFooter = (props: {
  sum: number | null;
  average: number | null;
  decimalScale: number;
  prefix: string;
}) => {
  const { sum, average, decimalScale, prefix } = props;

  return (
    <Typography variant="subtitle1">
      <Stack color={colorRaisinBlack}>
        <Stack
          direction="row"
          spacing={1.5}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant={"caption"}>Sum</Typography>
          <NumberFormat
            value={sum}
            displayType="text"
            decimalScale={decimalScale}
            fixedDecimalScale
            prefix={prefix}
            thousandSeparator
          />
        </Stack>
        <Stack
          direction="row"
          spacing={1.5}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant={"caption"}>Avg</Typography>
          <NumberFormat
            value={average}
            displayType="text"
            decimalScale={decimalScale}
            fixedDecimalScale
            prefix={prefix}
            thousandSeparator
          />
        </Stack>
      </Stack>
    </Typography>
  );
};

export const SumFooter = (props: {
  rows: any;
  fieldName: string;
  decimalScale: number;
  prefix: string;
}) => {
  const { rows, fieldName, decimalScale, prefix } = props;

  const total = useMemo(() => _.sumBy(rows, `values.${fieldName}`), [rows]);

  return (
    <Typography variant="subtitle1">
      <Stack color={colorRaisinBlack}>
        <Stack
          direction="row"
          spacing={1.5}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant={"caption"}>Sum</Typography>
          <NumberFormat
            value={total}
            displayType="text"
            decimalScale={decimalScale}
            fixedDecimalScale
            prefix={prefix}
            thousandSeparator
          />
        </Stack>
      </Stack>
    </Typography>
  );
};

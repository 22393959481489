import { EntityEnum } from "types/api/deal/enum";

export enum EntityAction {
  view = "view",
  edit = "edit",
  delete = "delete",
  create = "create",
}

export interface RecordActionResponse {
  id: number;
  type: EntityEnum;
  action: EntityAction;
}

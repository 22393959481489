import { Typography } from "@mui/material";

import MainCard from "components/MainCard";

// ==============================|| SAMPLE PAGE ||============================== //

const SamplePage = () => (
  <MainCard title="Upgrade Required">
    <Typography variant="body2">
      Thank you for your interest in our product. To view this product, you will
      need to upgrade to our premium version. To upgrade, please go to the
      settings menu and select the "billing" option or speak to our customer
      service. Thank you for choosing our app!
    </Typography>
  </MainCard>
);

export default SamplePage;

import { InfoCircleFilled } from "@ant-design/icons";
import { Alert, Button, Grid, Stack } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { useFormikContext } from "formik";
import { size } from "lodash";

import { usePopForm } from "contexts/FormDialogsContext";

export function FormDialogActions({
  saveButtonText = "Save",
}: {
  saveButtonText?: string;
}) {
  const { submitCount, errors, isSubmitting, handleSubmit } =
    useFormikContext();
  const popForm = usePopForm();

  return (
    <DialogActions>
      <Grid container style={{ display: "flex" }} alignItems="center">
        <Grid item xs sx={{ flexGrow: 1 }}></Grid>
        <Grid item>
          {submitCount > 0 && size(errors) ? (
            <Alert color="error" icon={<InfoCircleFilled />}>
              Please correct one or more errors on the form.
            </Alert>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs>
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            alignItems="center"
            sx={{ mr: 1 }}
          >
            <Button color="secondary" onClick={popForm} size={"large"}>
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={() => handleSubmit()}
              size={"large"}
              variant="contained"
              disabled={isSubmitting}
            >
              {saveButtonText}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </DialogActions>
  );
}

import { DealOption } from "types/dealOption";

import { ContactRead } from "./api/deal/contact";
import { BaseState, Keyed } from "./common";

export enum DealType {
  sale_both = "sale_both",
  acquisition = "acquisition",
  disposition = "disposition",
  lease_both = "lease_both",
  landlord_agency = "landlord_agency",
  tenant_representation = "tenant_representation",
  referral = "referral",
  consulting = "consulting",
  other = "other",
}

export enum DealStatus {
  open = "open",
  lost = "lost",
  closed = "closed",
}

export enum SizeType {
  square_feet = "square_feet",
  acres = "acres",
  units = "units",
}

export enum BrokerRoleEnum {
  EXECUTING_AND_SOURCING = "executing_and_sourcing",
  SOURCING = "sourcing",
  EXECUTING = "executing",
  NONE = "none",
}

export interface CommissionRead {
  user_id: number;
  deal_id: number | null;
  estimate_percent?: number | null;
  actual_percent?: number | null;
  broker_role?: BrokerRoleEnum | null;
}

export type Deal = {
  id?: number;
  deal_type: DealType;
  close_date: string | null;
  commission: number | null;
  square_feet?: number | null;
  hire_date?: Date | null;
  name?: string | null;
  close_date_est: string | null;
  commission_est: number | null;
  square_feet_est?: number | null;
  move_in_date?: Date | null;
  relocation_date?: Date | null;
  move_in_date_est?: Date | null;
  relocation_date_est?: Date | null;
  deleted_at?: Date | null;
  deleted_by?: number | null;
  created_at?: Date | null;
  updated_at?: Date | null;
  created_by?: number | null;
  updated_by?: number | null;
  status?: DealStatus | null;
  price?: number | null;
  organization?: number | null;
  team?: number | null;
  broker_ran?: number | null;
  broker_sourced?: number | null;
  commissions: CommissionRead[];
  commission_due_date_first?: Date | null;
  commission_due_date_amount_first?: number | null;
  commission_due_date_paid_first?: boolean | null;
  commission_due_date_second?: Date | null;
  commission_due_date_amount_second?: number | null;
  commission_due_date_paid_second?: boolean | null;
  stage_id?: number | null;
  asset_type_id?: number | null;
  source_type_id?: number | null;
  counterparty_broker_company_id?: number | null;
  outside_broker_id?: number | null;
  client_industry_id?: number | null;
  lost_reason_id?: number | null;
  lost_date?: string | Date | null;
  property_name: {
    field: string;
    id: number;
    value: string;
  };
  property_name_id?: number | null;
  shared_broker_company_id?: number | null;
  shared_broker_id?: number | null;
  submarket_id?: number | null;
  tenant_name: {
    field: string;
    id: number;
    value: string;
  };
  tenant_name_id?: number | null;
  shared_deal?: boolean | null;
  lease_expiration?: Date | null;
  transaction_value?: number | null;
  transaction_value_est?: number | null;
  size?: number | null;
  size_est?: number | null;
  size_type?: SizeType | null;
};

// checkout-cart billing address
export type Address = {
  id?: string | number | undefined;
  name: string;
  destination: string;
  building: string;
  street: string;
  city: string;
  state: string;
  country: string;
  post: string | number;
  phone: string | number;
  isDefault: boolean;
};

export interface DealState extends BaseState<Keyed<Deal>> {
  currentView: Array<number>;
}

export interface TabsProps {
  children?: React.ReactElement | React.ReactNode | string;
  value: string | number;
  index: number;
}

export type DealStage = {
  id: number | string;
  value: string | null;
  order: number;
  deal_type: DealType;
  probability: number | null;
};

export interface DealStageType extends DealStage {
  inputValue?: string;
}

export type DealCommissionDueDate = {
  deal_id?: number | string;
  due_date?: Date | null;
  amount?: number | null;
  is_paid?: boolean | null;
};

export interface DealExtended extends Deal {
  // Option fields
  asset_type?: DealOption | null;
  counterparty_broker_company?: DealOption | null;
  source_type?: DealOption | null;
  client_industry?: DealOption | null;
  shared_broker_company?: DealOption | null;
  submarket?: DealOption | null;
  stage?: DealStage | null;
  // Contact fields
  contact?: ContactRead | null;
  shared_broker?: ContactRead | null;
  outside_broker?: ContactRead | null;
  // Calculated fields
  estimated_commission?: number | null;
  close_date_effective?: Date | null;
  commission_effective?: number | null;
  commission_effective_unweighted?: number | null;
  commission_effective_filter_aware?: number | null;
  hire_to_close_days?: string | null;
  outstanding_commission?: number | null;
  //
  probability_override?: number | null;
}

export interface DealOrgPreferences {
  enable_deal_stage_probability: boolean;
  deal_features: DealFeature[];
  deal_fields_visible: DealField[];
  deal_fields_required: DealField[];
}

export enum DealFeature {
  KEY_DATES = "key_dates",
  COMMISSION_SPLITS = "commission_splits",
  COMMISSION_PAYMENTS = "commission_payments",
  WEIGHTED_COMMISSION = "weighted_commission",
  WEIGHTED_TRANSACTION_VALUE = "weighted_transaction_value",
}

export enum DealField {
  ADDRESS = "address",
  CLIENT_INDUSTRY = "client_industry",
  CONTACT = "contact",
  HIRE_DATE = "hire_date",
  LEASE_EXPIRATION = "lease_expiration",
  MOVE_IN_DATE = "move_in_date",
  OUTSIDE_BROKER = "outside_broker",
  PROPERTY_NAME = "property_name",
  RELOCATION_DATE = "relocation_date",
  SHARED_BROKER = "shared_broker",
  SIZE = "size",
  SOURCE_TYPE = "source_type",
  STAGE = "stage",
  SUBMARKET = "submarket",
  TENANT_NAME = "tenant_name",
}

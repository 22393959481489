import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import _ from "lodash";

import { CreoneField } from "components/form/basic/creone_field";
import { UserChip } from "components/form/basic/UserChip";
import { LightTooltip } from "pages/deal/components/InfoTooltip";
import { UserLookupField } from "pages/deal/components/LookupField";
import { FieldComponentProps } from "types/standardForm";

function NameAvatar(props: FieldComponentProps) {
  const { getFieldProps, setFieldTouched, touched } = useFormikContext();

  const { value } = getFieldProps(props.fieldName);

  return !_.get(touched, props.fieldName) && !_.isNil(value) ? (
    <CreoneField {...props}>
      <LightTooltip title={props.displayName}>
        <Stack
          sx={{ cursor: "pointer", height: "32.25px" }}
          onClick={() => setFieldTouched(props.fieldName, true)}
          justifyContent={"center"}
        >
          <UserChip fullName={_.get(value, "label", "")} />
        </Stack>
      </LightTooltip>
    </CreoneField>
  ) : (
    <UserLookupField {...props} />
  );
}

export default NameAvatar;

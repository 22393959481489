import {
  AppstoreOutlined,
  StarFilled,
  UserOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { Place } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

import config from "config";
import { Product } from "types/license";
import { NavItemType } from "types/menu";

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //
const prospects: NavItemType = {
  id: "group-prospects",
  title: <FormattedMessage id="prospects" />,
  type: "group",
  products: [Product.prospects],
  children: [
    {
      id: "dashboard",
      title: <FormattedMessage id="dv-dashboard" />,
      type: "item",
      url: "/prospects/dashboard",
      icon: AppstoreOutlined,
    },
    {
      id: "insights",
      title: <FormattedMessage id="insights" />,
      type: "item",
      url: "/prospects/insights",
      icon: LineChartOutlined,
    },
    {
      id: "prospects",
      title: <FormattedMessage id="prospects" />,
      type: "item",
      url: "/prospects/overview",
      icon: StarFilled,
    },
    {
      id: "contacts",
      title: <FormattedMessage id="contacts" />,
      type: "item",
      url: "/prospects/contacts",
      icon: UserOutlined,
    },
    ...(config.features.property_database
      ? [
          {
            id: "properties",
            title: <FormattedMessage id="properties" />,
            type: "item",
            url: "/properties/overview",
            icon: Place,
          },
        ]
      : []),
  ],
};

export default prospects;

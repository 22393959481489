import Snackbar from "components/@extended/Snackbar";
import Locales from "components/Locales";
import MobileAppBanner from "components/MobileAppBanner";
import ScrollTop from "components/ScrollTop";
import Notistack from "components/third-party/Notistack";
import { JWTProvider as AuthProvider } from "contexts/JWTContext";
import Routes from "routes";
import ThemeCustomization from "themes";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
  <ThemeCustomization>
    <Locales>
      <ScrollTop>
        <AuthProvider>
          <>
            <Notistack>
              <Routes />
              <Snackbar />
              <MobileAppBanner />
            </Notistack>
          </>
        </AuthProvider>
      </ScrollTop>
    </Locales>
  </ThemeCustomization>
);

export default App;

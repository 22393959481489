import { ArrowDropDown } from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  Grow,
  Popper,
  useTheme,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/styles";
import {
  Fragment,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
} from "react";

const ButtonLabel = styled("span")({
  textOverflow: "ellipsis",
  overflow: "hidden",
});

export function FilterSelectButton({
  id,
  children,
  conditionalSx,
  label,
  open,
  setOpen,
  disabled = false,
  onOpen = () => {},
}: {
  id: string;
  conditionalSx: { backgroundColor: string; fontWeight: number } | {};
  label: ReactNode | string;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  onOpen?: () => void;
}) {
  const theme = useTheme();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => {
      if (!prevOpen) onOpen();
      return !prevOpen;
    });
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  // Return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Fragment>
      <Button
        ref={anchorRef}
        endIcon={
          <ArrowDropDown
            style={{
              fontSize: 24,
              transform: open ? `rotate(180deg)` : undefined,
            }}
          />
        }
        aria-describedby={id}
        type="button"
        disableRipple
        onClick={handleToggle}
        variant="outlined"
        sx={{
          height: "44px",
          justifyContent: "space-between",
          color: theme.palette.grey[800],
          borderColor: theme.palette.grey[800],
          "&:hover": {
            borderColor: theme.palette.primary.light,
          },
          width: "auto",
          whiteSpace: "nowrap",
          paddingRight: "8px",
          ...conditionalSx,
        }}
        disabled={disabled}
        fullWidth
      >
        <ButtonLabel>{label}</ButtonLabel>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        sx={{ zIndex: 1500, marginTop: "8px !important" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {children}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
}

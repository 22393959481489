import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { CellProps } from "react-table";

import { formatDateString } from "pages/deal/utils/reporting";
import { ProspectStar } from "pages/prospects/components/ProspectStar";
import { Contact } from "types/api/deal/contact";
export const activityTableColumns = () => {
  // NOTE: Since sorting is managed on the backend, be sure to update the sort field mapping in the ActivityFilter object
  return [
    {
      Header: "Contact Name",
      accessor: "contact_fullname", // accessor is the "key" in the data,
      Cell: ({ value, row }: CellProps<Contact>) => (
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Box>{value}</Box>
          <ProspectStar isProspect={_.get(row, "original.is_prospect")} />
        </Stack>
      ),
      disableSortBy: true,
    },
    {
      Header: "Activity Subject",
      accessor: "subject",
      disableSortBy: true,
    },
    {
      Header: "Activity Type",
      accessor: "activity_type_v2_name",
      disableSortBy: true,
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ value }: { value: any }) => {
        return formatDateString(value);
      },
      disableSortBy: true,
    },
  ];
};

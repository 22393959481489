import { Switch } from "@mui/material";
import { useFormikContext } from "formik";
import _ from "lodash";

import { CreoneField } from "components/form/basic/creone_field";
import { FieldComponentProps } from "types/standardForm";

interface SwitchFieldProps extends FieldComponentProps {
  showLabel?: boolean;
}

export function SwitchField(props: SwitchFieldProps) {
  const { fieldName, additionalChangeHandler } = props;
  const { setFieldValue, values } = useFormikContext();

  const value = _.get(values, fieldName);

  return (
    <CreoneField {...props}>
      <Switch
        id={fieldName}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const value = event.target.checked;
          setFieldValue(fieldName, value);
          if (typeof additionalChangeHandler === "function")
            additionalChangeHandler(value, setFieldValue);
        }}
        checked={value}
        value={value}
        disableRipple
      />
    </CreoneField>
  );
}

import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import _ from "lodash";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import { CreoneField } from "components/form/basic/creone_field";
import { getBaseQueryOptionsDealService } from "pages/deal/utils/api";
import { parseDealStageDict } from "pages/deal/utils/deal";
import { DealStage } from "types/deal";
import { FieldComponentProps } from "types/standardForm";

// TODO: Refactor to merge with SelectUserAsync
export function SelectStageAsync(props: FieldComponentProps) {
  const { setFieldValue, values } = useFormikContext<any>();
  const [open, setOpen] = useState(false);

  const queryOptions = useMemo(
    () => getBaseQueryOptionsDealService<Record<number, DealStage>>("/stage/"),
    []
  );
  const {
    data: dealStages = [],
    isLoading: dealStagesLoading,
    refetch,
  } = useQuery<Record<number, DealStage>>(queryOptions);

  const dealType = _.get(values, "deal_type");

  const options = useMemo(
    () => parseDealStageDict(dealStages, dealType),
    [dealStages, dealType]
  );
  const value = _.get(values, props.fieldName, null);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  const loading = open && dealStagesLoading;

  return (
    <CreoneField {...props}>
      <Box sx={{ width: "auto" }}>
        <Autocomplete
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          value={value}
          onChange={(_event, value) => setFieldValue(props.fieldName, value)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.value}
          options={options}
          loading={loading}
          disabled={props.disabled}
          size={"medium"}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={props.displayName}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
      </Box>
    </CreoneField>
  );
}

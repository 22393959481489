import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";
import * as React from "react";
import { Fragment, useMemo, useState } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";

import { FilterComponentProps } from "components/filter/FilterBar/FilterBar";
import { FilterSelectButton } from "components/filter/FilterBar/FilterComponents/FilterSelectButton";
import { getCommaSeparatedOptionLabels } from "pages/deal/utils/deal";
import {
  getConditionalFilterSx,
  isFilterEmpty,
} from "pages/deal/utils/dealFilters";
import { SimpleOption, SimpleOptionGrouped } from "types/api/deal/form";
import { filterEmpty, filterUnassigned } from "types/navigation/common";

interface FilterAutocompleteProps extends FilterComponentProps {
  label: string;
  value: (string | number | null)[];
  onChange: (value: (string | number | null)[]) => void;
  options?: SimpleOption[] | SimpleOptionGrouped[];
  isLoading?: boolean;
  sx?: object;
  onOpen?: () => void;
  disabled?: boolean;
  isFilterBar?: boolean;
  error?: boolean;
  grouped?: boolean;
}

// Define the ListboxComponent for virtualization
const LISTBOX_PADDING = 6; // px

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const item = data[index];

  return React.cloneElement(item, {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.useMemo(
    () => React.Children.toArray(children),
    [children]
  );

  const itemCount = itemData.length;
  const itemSize = 40;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemCount * itemSize;
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={itemSize}
          overscanCount={5}
          itemCount={itemCount}
          itemKey={(index, data) => {
            // Use the key prop from the item to ensure stable identity
            const item = data[index] as React.ReactElement;
            return item.key ?? index;
          }}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export function FilterAutocomplete(props: FilterAutocompleteProps) {
  const {
    label,
    value,
    onChange,
    options = [],
    sx,
    onOpen = () => {},
    disabled = false,
    isFilterBar = true,
    isLoading = false,
    grouped = false,
  } = props;
  const theme = useTheme();

  const id = `${_.kebabCase(label)}-filter`;
  const conditionalSx = isFilterBar
    ? (getConditionalFilterSx(value, theme) ?? {})
    : {};

  // Convert the filter value to a format that satisfies the Autocomplete typescript types
  const autocompleteValue: SimpleOption[] = useMemo(
    () => _.map(value, (x) => ({ key: x, label: "" })),
    [value]
  );

  const groupKeysMap = useMemo(() => {
    if (!grouped) return null;
    // Group items by the 'group' property
    const groupedItems = _.groupBy(options, (item) => _.get(item, "group"));
    // Transform the grouped items into the desired dictionary structure
    return _.mapValues(groupedItems, (groupItems) =>
      _.map(groupItems, (item) => item.key)
    );
  }, [options, grouped]);

  const renderLabel = () =>
    isFilterBar
      ? `${label}${isFilterEmpty(value) ? "" : ` (${_.isArray(value) ? value.length : 1})`}`
      : getCommaSeparatedOptionLabels(options, value);

  const [open, setOpen] = useState(false);

  return (
    <FormControl sx={{ minWidth: 120, maxWidth: 200, ...sx }}>
      <FilterSelectButton
        id={id}
        conditionalSx={conditionalSx}
        label={renderLabel()}
        open={open}
        setOpen={setOpen}
        disabled={disabled}
        onOpen={onOpen}
      >
        <Stack direction="column" sx={{ px: 1, py: 1 }}>
          <Autocomplete
            size={"small"}
            disableClearable
            disableCloseOnSelect
            disablePortal={true}
            groupBy={
              grouped ? (option) => _.get(option, "group", "") : undefined
            }
            renderGroup={
              grouped
                ? (params) => {
                    const groupKeys = _.get(groupKeysMap, params.group, []);
                    const differenceCount = _.difference(
                      groupKeys,
                      value
                    ).length;
                    const isChecked = differenceCount === 0;
                    const isIndeterminate =
                      differenceCount > 0 && differenceCount < groupKeys.length;

                    return (
                      <Fragment key={_.get(params, "key")}>
                        <MenuItem
                          id={`team-${_.kebabCase(params.group)}`}
                          onClick={() => {
                            if (!isChecked || isIndeterminate) {
                              const unCheckedGroupValues = _.filter(
                                groupKeys,
                                (x) => !_.includes(value, x)
                              );
                              onChange([...value, ...unCheckedGroupValues]);
                            } else {
                              onChange(
                                _.filter(
                                  value,
                                  (x) => !_.includes(groupKeys, x)
                                )
                              );
                            }
                          }}
                          selected={isChecked || isIndeterminate}
                          disabled={_.isEqual(value, filterUnassigned)}
                        >
                          <Checkbox
                            style={{ marginRight: 8, marginLeft: -10 }}
                            checked={isChecked}
                            indeterminate={isIndeterminate}
                          />
                          <Typography
                            key={`header-${_.get(params, "key")}`}
                            sx={{ fontWeight: 600, color: "inherit" }}
                            component={"span"}
                          >
                            {params.group}
                          </Typography>
                        </MenuItem>
                        {params.children}
                      </Fragment>
                    );
                  }
                : undefined
            }
            getOptionDisabled={() => _.isEqual(value, filterUnassigned)}
            getOptionLabel={(option) => option.label}
            id={`${id}-autocomplete`}
            isOptionEqualToValue={(
              option: SimpleOption,
              value: SimpleOption | string
            ) => {
              return _.isObject(value)
                ? option?.key === value?.key
                : option?.key === value;
            }}
            ListboxProps={{ style: { maxHeight: 259 } }}
            // Use virtualization when not grouped
            ListboxComponent={grouped ? undefined : ListboxComponent}
            multiple
            onChange={(event, value, _reason) => {
              const parsedValues = _.filter(
                _.map(value, (x) => (_.isObject(x) ? x?.key : x)),
                (x) => x !== filterEmpty[0]
              );
              onChange(parsedValues);
            }}
            open
            options={options}
            popupIcon={<SearchOutlinedIcon />}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                sx={{ height: "40px" }}
                {...params}
                onKeyDown={(event: any) => {
                  // Stop the backspace key from removing selected options
                  if (event.key === "Backspace" || event.key === "Delete") {
                    event.stopPropagation();
                  }
                }}
                placeholder="Search"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderOption={(props, option, state) => {
              return (
                <MenuItem {...props} onMouseOver={undefined}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={state.selected}
                  />
                  {option.label}
                </MenuItem>
              );
            }}
            renderTags={() => null}
            slotProps={{
              popper: { sx: { transform: "translate(8px, 49px)!important" } },
              paper: { sx: { boxShadow: "none", borderRadius: 0 } },
            }}
            sx={{
              width: 300,
              [`& .${autocompleteClasses.popupIndicator}`]: {
                transform: "none",
              },
              height: 300,
            }}
            value={autocompleteValue}
          />
          {isFilterBar && (
            <Fragment>
              <Divider sx={{ mx: -1 }} />
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ px: 2, mb: -1, height: 50 }}
              >
                <FormControl
                  component="fieldset"
                  variant="standard"
                  margin="none"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="unassigned"
                        checked={_.isEqual(value, filterUnassigned)}
                        onChange={() =>
                          _.isEqual(value, filterUnassigned)
                            ? onChange(filterEmpty)
                            : onChange(filterUnassigned)
                        }
                      />
                    }
                    label="Unassigned"
                  />
                </FormControl>
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  onClick={() => onChange(_.map(options, "key"))}
                >
                  Select All
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  onClick={() => onChange(filterEmpty)}
                >
                  Clear
                </Button>
              </Stack>
            </Fragment>
          )}
        </Stack>
      </FilterSelectButton>
    </FormControl>
  );
}

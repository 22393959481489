import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { Hierarchy } from "types/api/user_management/organization";
import { FormIdentifier } from "types/record";
import { axiosUserServices } from "utils/axios";
import { handleRecordActionResponseV2 } from "utils/record";

export async function createTeamAsync(team: Hierarchy) {
  try {
    const response = await axiosUserServices.post(`/user/team/`, team);
    handleRecordActionResponseV2(
      "Team",
      "created",
      FormIdentifier.TeamForm,
      response
    );
    return response.data.data;
  } catch (error) {
    dispatch(openErrorNotification("Error creating team."));
    throw error;
  }
}

export async function updateTeamAsync(
  id: number | string,
  team: Partial<Hierarchy>
) {
  try {
    const response = await axiosUserServices.put(`/user/team/${id}`, team);
    handleRecordActionResponseV2(
      "Team",
      "updated",
      FormIdentifier.TeamForm,
      response
    );
    return response.data.data;
  } catch (error) {
    dispatch(openErrorNotification("Error updating team."));
    throw error;
  }
}

export async function deleteTeamAsync(id: number | string) {
  try {
    const response = await axiosUserServices.delete(`/user/team/${id}`);
    handleRecordActionResponseV2(
      "Team",
      "deleted",
      FormIdentifier.TeamForm,
      response
    );
  } catch (error) {
    dispatch(
      openErrorNotification(
        "Error deleting team. Make sure to move any team members to another team before deleting."
      )
    );
    throw error;
  }
}

import { FormikValues } from "formik";
import React, { createContext, useContext, useMemo } from "react";
import { AnyObject, ObjectSchema, SchemaObjectDescription } from "yup";

// Initialize context
const YupDescriptionContext = createContext<SchemaObjectDescription | null>(
  null
);

// Create context provider
export function YupDescriptionProvider({
  objectSchema,
  values,
  children,
}: {
  children: React.ReactNode;
  objectSchema: ObjectSchema<{}, AnyObject, {}, "">;
  values: FormikValues;
}) {
  // Get the live schema description hydrated with values
  const yupDescription = useMemo(
    () => objectSchema.describe({ value: values }),
    [objectSchema, values]
  );

  return (
    <YupDescriptionContext.Provider value={yupDescription}>
      {children}
    </YupDescriptionContext.Provider>
  );
}

// Custom hook to use this context
export function useYupDescription() {
  const context = useContext(YupDescriptionContext);
  if (!context) {
    throw new Error(
      "useYupDescription must be used within a YupDescriptionProvider"
    );
  }
  return context;
}

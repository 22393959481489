import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { parseParamsAsFilters } from "pages/deal/utils/deal";
import { baseContactFilters } from "pages/prospects/constants/contactFilters";
import { dispatch } from "store";
import { SortOrderEnum } from "types/navigation/common";
import {
  ContactFilter,
  ContactNavigation,
} from "types/navigation/contactNavigation";
import { RecordIdentifier } from "types/record";
import { captureUIEvent } from "utils/eventCapture";

const initialState: {
  initial: ContactFilter;
  published: ContactFilter;
  pending: Partial<ContactFilter>;
  navigation: ContactNavigation;
} = {
  initial: {
    ...baseContactFilters,
    sort: ["full_name"],
    sort_order: [SortOrderEnum.asc],
  },
  published: {
    ...baseContactFilters,
    sort: ["full_name"],
    sort_order: [SortOrderEnum.asc],
  },
  pending: {},
  navigation: {
    hiddenFilters: [],
    disabledFilters: [],
  },
};

const contactNavigation = createSlice({
  name: "contactNavigation",
  initialState,
  reducers: {
    setFilters(state, action) {
      state.pending = action.payload;
    },
    setInitialFilters(state, action) {
      state.initial = action.payload;
      state.pending = {};
    },
    setPublishedFilters(state, action) {
      state.published = action.payload;
      state.pending = {};
    },
    setInitialAndPublishedFilters(state, action) {
      state.initial = action.payload;
      state.published = action.payload;
      state.pending = {};
    },
    applyFilters(state) {
      captureUIEvent("filters_applied", {
        recordType: RecordIdentifier.Contact,
        filters: { ...state.published, ...state.pending },
      });

      state.published = { ...state.published, ...state.pending };
      state.pending = {};
    },
    resetFilters(state) {
      captureUIEvent("filters_reset", {
        recordType: RecordIdentifier.Contact,
      });

      state.published = state.initial;
      state.pending = {};
    },
    setNavigation(state, action) {
      state.navigation = action.payload;
    },
    setSort(state, action) {
      const { sort, sort_order } = action.payload;

      if (
        !_.isEqual(state.published.sort, sort) ||
        !_.isEqual(state.published.sort_order, sort_order)
      ) {
        state.published = { ...state.published, ...action.payload };
      }
    },
  },
});

export default contactNavigation.reducer;

export const setInitialContactFilters = (filters: ContactFilter) =>
  dispatch(contactNavigation.actions.setInitialFilters(filters));
export const setPublishedContactFilters = (filters: ContactFilter) =>
  dispatch(contactNavigation.actions.setPublishedFilters(filters));
export const setInitialAndPublishedContactFilters = (filters: ContactFilter) =>
  dispatch(contactNavigation.actions.setInitialAndPublishedFilters(filters));
export const setContactFilters = (filters: Partial<ContactFilter>) =>
  dispatch(contactNavigation.actions.setFilters(filters));
export const applyContactFilters = () =>
  dispatch(contactNavigation.actions.applyFilters());
export const resetContactFilters = () =>
  dispatch(contactNavigation.actions.resetFilters());

export const setContactNavigation = (navigation: ContactNavigation) =>
  dispatch(contactNavigation.actions.setNavigation(navigation));
export const setContactSort = (sort: string[], sort_order: string[]) =>
  dispatch(contactNavigation.actions.setSort({ sort, sort_order }));

export function setInitialContactFilterState(
  initialFilters: ContactFilter,
  searchParams: URLSearchParams
) {
  const { queryFilters, queryFiltersExist } = parseParamsAsFilters(
    baseContactFilters,
    searchParams
  );
  if (queryFiltersExist) {
    setInitialContactFilters(initialFilters);
    setPublishedContactFilters({ ...initialFilters, ...queryFilters });
  } else {
    setInitialAndPublishedContactFilters(initialFilters);
  }
}

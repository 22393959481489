import { SvgIconComponent } from "@mui/icons-material";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import CodeIcon from "@mui/icons-material/Code";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { get } from "lodash";
import { ReactElement } from "react";

// Define a mapping between content types and icons
const contentTypeToIconMap: Record<string, SvgIconComponent> = {
  "application/pdf": PictureAsPdfIcon,
  "image/jpeg": ImageIcon,
  "image/png": ImageIcon,
  "image/gif": ImageIcon,
  "image/webp": ImageIcon,
  "audio/mpeg": AudiotrackIcon,
  "audio/wav": AudiotrackIcon,
  "video/mp4": VideoLibraryIcon,
  "video/webm": VideoLibraryIcon,
  "text/plain": DescriptionIcon,
  "text/html": CodeIcon,
  "application/json": CodeIcon,
  "application/xml": CodeIcon,
};

// Default icon for unknown content types
const DefaultIcon = InsertDriveFileIcon;

export const getIconForContentType = (contentType: string): ReactElement => {
  const IconComponent = get(contentTypeToIconMap, contentType) ?? DefaultIcon;

  // Return the corresponding icon component
  return <IconComponent />;
};

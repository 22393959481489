import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useField } from "formik";
import * as React from "react";

import { FieldComponentProps } from "types/standardForm";

import { CreoneField } from "./creone_field";

interface dollarPercentToggleProps extends FieldComponentProps {
  showLabel?: boolean;
}

export function DollarPercentToggle(props: dollarPercentToggleProps) {
  const [field, , helpers] = useField(props.fieldName);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    value: string | null
  ) => {
    if (value !== null) {
      helpers.setValue(value);
    }
  };

  return (
    <CreoneField {...props}>
      <ToggleButtonGroup
        color="primary"
        id={props.fieldName}
        {...field}
        exclusive
        onChange={handleAlignment}
        aria-label={props.fieldName}
      >
        <ToggleButton
          id={`${props.fieldName}-dollar`}
          size="small"
          value={false}
          aria-label="left aligned"
        >
          $
        </ToggleButton>
        <ToggleButton
          id={`${props.fieldName}-percent`}
          size="small"
          value={true}
          aria-label="centered"
        >
          %
        </ToggleButton>
      </ToggleButtonGroup>
    </CreoneField>
  );
}

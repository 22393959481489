export const defaultModalColumns = [
  "brokers",
  "close_date_effective",
  "commission_effective",
  "deal_type",
  "name",
  "transaction_value_effective",
  "size_effective",
  "status",
];

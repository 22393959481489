import { FormikValues, useFormikContext } from "formik";
import { ReactNode } from "react";

interface DynamicVisibilitySectionProps {
  children: ReactNode;
  visibilityFunction: (values: FormikValues) => boolean;
}

const DynamicVisibilitySection = ({
  children,
  visibilityFunction,
}: DynamicVisibilitySectionProps) => {
  const { values } = useFormikContext<FormikValues>();

  const isVisible = visibilityFunction(values);

  return isVisible ? children : null;
};

export default DynamicVisibilitySection;

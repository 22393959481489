import {
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FormikValues, useFormikContext } from "formik";
import _ from "lodash";

import { FieldComponentProps } from "types/standardForm";

import { CreoneField } from "./basic/creone_field";

export function GetSelect({
  disabled,
  fieldName,
  additionalChangeHandler,
  displayName,
  options,
  componentProps,
}: FieldComponentProps) {
  const theme = useTheme();
  const { setFieldValue, values } = useFormikContext();
  return (
    <Select
      displayEmpty
      size="medium"
      disabled={disabled}
      value={_.get(values, fieldName, "")}
      onChange={(event: SelectChangeEvent<string>) => {
        const value = event.target.value as string;
        if (typeof additionalChangeHandler === "function") {
          additionalChangeHandler(
            value,
            setFieldValue,
            _.get(values, fieldName, ""),
            values as FormikValues
          );
        }
        setFieldValue(fieldName, value);
      }}
      input={
        <OutlinedInput
          size="medium"
          id="select-column-hiding"
          placeholder={displayName}
        />
      }
      renderValue={(selected: string) => {
        if (!selected) {
          return (
            <Typography variant="inherit" color={theme.palette.grey["400"]}>
              {displayName}
            </Typography>
          );
        }
        return (
          <Typography variant="subtitle2">
            {_.get(_.find(options, { key: selected }), "label", "")}
          </Typography>
        );
      }}
    >
      {/* This first mapping supports a different data structure, ex: dealTypeDisplayCategoryOptions */}
      {_.map(options, ({ key, label }) =>
        _.isObject(label) ? (
          [
            <ListSubheader
              key={`header-${key}`}
              sx={{
                fontWeight: 600,
                color: "inherit",
                textTransform: "capitalize",
              }}
            >
              {key}
            </ListSubheader>,
            _.map(label, (value: any, k: string) => (
              <MenuItem key={`${fieldName}.${k}`} value={k} sx={{ ml: 1 }}>
                <ListItemText primary={value} />
              </MenuItem>
            )),
          ]
        ) : (
          <MenuItem key={`${fieldName}.${key}`} value={key}>
            <ListItemText primary={label} />
          </MenuItem>
        )
      )}
    </Select>
  );
}

export function SelectOption(props: FieldComponentProps) {
  return (
    <CreoneField {...props}>
      <GetSelect
        disabled={props.disabled}
        fieldName={props.fieldName}
        componentProps={props.componentProps}
        additionalChangeHandler={props.additionalChangeHandler}
        displayName={props.displayName}
        options={props.options}
      />
    </CreoneField>
  );
}

import { DialogContent, DialogTitle } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import * as Sentry from "@sentry/react";
import _ from "lodash";
import React from "react";

import StandardField from "components/form/standard/StandardField";
import { StandardForm } from "components/form/standard/StandardForm";
import { ContactMetadata } from "constants/objectMetadata/contactMetadata";
import { DealMetadata } from "constants/objectMetadata/dealMetadata";
import { Contact, ContactRead } from "types/api/deal/contact";
import { StandardFormProps } from "types/record";
import { updateContactAsync } from "utils/contact";

export const ContactNotesForm = ({
  record,
  incomingChanges,
  onCancel,
  successCallback,
  open,
}: StandardFormProps<ContactRead>) => {
  const updateFnAsync = async (
    id: number | string,
    contact: Partial<Contact>
  ) => {
    try {
      const record = await updateContactAsync(id, contact);
      if (typeof successCallback === "function" && _.isObject(record))
        successCallback(record);
    } catch (error) {
      Sentry.captureException("Unable to update Contact.");
    }
  };

  const { notes_plaintext } = ContactMetadata;
  const NotesModalMetadata = { notes_plaintext };

  return (
    <StandardForm<Partial<ContactRead>>
      metadata={NotesModalMetadata}
      record={record}
      updateFn={updateFnAsync}
      displayName={"Notes"}
      onCancel={onCancel}
      incomingChanges={incomingChanges}
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
    >
      {({ StandardFormActionsInstance }) => (
        <>
          <DialogTitle>
            {!!record ? `Edit Notes: ${record?.full_name}` : "Edit Notes"}
          </DialogTitle>
          <DialogContent dividers>
            <StandardField
              field={DealMetadata.notes_plaintext}
              showLabel={false}
            />
          </DialogContent>
          <DialogActions>{StandardFormActionsInstance}</DialogActions>
        </>
      )}
    </StandardForm>
  );
};

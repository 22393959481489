import { SimpleOptionWithColor } from "types/api/deal/form";

export const PROSPECT_TYPE_OPTIONS = [
  { key: "acquisition", label: "Buyer" },
  { key: "disposition", label: "Seller" },
  { key: "landlord_agency", label: "Landlord" },
  { key: "tenant_representation", label: "Tenant" },
  { key: "other", label: "Other" },
];

export const PROSPECT_STATUS_DISPLAY_NAMES = {
  open: "Open",
  lost: "Lost",
  converted: "Converted",
};

export const PROSPECT_STATUS_OPTIONS: SimpleOptionWithColor[] = [
  { key: "open", label: "Open", color: "info" },
  {
    key: "converted",
    label: "Converted",
    color: "primary",
  },
  {
    key: "lost",
    label: "Lost",
    color: "secondary",
  },
];

import * as Sentry from "@sentry/react";
import { feedbackIntegration } from "@sentry/react";
import "assets/third-party/apex-chart.css";
import "assets/third-party/react-table.css";
import { ConfigProvider as ANTConfigProvider } from "antd";
import "regenerator-runtime";
import "simplebar/src/simplebar.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import config from "config";
import { persister, store } from "store";

import App from "./App";
import { FeatureFlagProvider } from "./contexts/FeatureFlagContext";
import reportWebVitals from "./reportWebVitals";

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //
export const feedbackInstance = feedbackIntegration({
  autoInject: false,
  colorScheme: "light",
  useSentryUser: {
    name: "fullName",
    email: "username",
  },
});

if (config.envName !== "local") {
  Sentry.init({
    dsn: "https://0633f2ce3ed44c20a81582e86ccff763@o4504888473812992.ingest.sentry.io/4504888503566336",
    tracesSampleRate: 1.0,
    environment: config.envName,
    integrations: [feedbackInstance],
  });
}

const queryClient = new QueryClient();
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <FeatureFlagProvider>
            <ANTConfigProvider
              theme={{
                token: {
                  zIndexPopupBase: 1500,
                },
              }}
            >
              <App />
            </ANTConfigProvider>
          </FeatureFlagProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </PersistGate>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import {
  LendersStateProps,
  Lender,
  LendersFilter,
  LenderTablePaginationProps,
} from "types/lender";
import { axiosLenderServices } from "utils/axios";

import { dispatch } from "../index";

// ----------------------------------------------------------------------

const initialState: LendersStateProps = {
  error: null,
  count: 0,
  lenders: [],
  lenderStates: [],
  lenderAssets: [],
  lenderTypes: [],
  lenderOptions: {
    lender_types: [],
    loan_products: [],
    fixed_loan_products: [],
    prepayment_penalties: [],
    recourse_provisions: [],
    borrower_requirements: [],
    lender_address_states: [],
    interest_only_options: [],
    lender_states: [],
    asset_classes: [],
    lender_ui_mappings: [],
    lender_contact_ui_mappings: [],
    lender_notification_ui_mappings: [],
    quote_request_statuses: [],
  },
  currentLender: null,
};

export const updateNotificationStatusOnCreate = createAsyncThunk(
  "lenderNotification/createLenderNotification",
  async (lenderNotification: any) => {
    return lenderNotification;
  }
);

export const updateNotificationStatusOnEdit = createAsyncThunk(
  "lenderNotification/updateLenderNotification",
  async (lenderNotification: any) => {
    return lenderNotification;
  }
);

const lender = createSlice({
  name: "lender",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },

    getLendersSuccess(state, action) {
      state.lenders = action.payload.data;
      state.count = action.payload.count;
    },

    getLenderSuccess(state, action) {
      state.currentLender = action.payload;
      state.count = action.payload.count;
    },

    getLendersStatesSuccess(state, action) {
      state.lenderStates = action.payload;
    },

    getLendersAssetsSuccess(state, action) {
      state.lenderAssets = action.payload;
    },

    getLendersTypesSuccess(state, action) {
      state.lenderTypes = action.payload;
    },

    getLenderOptionsSuccess(state, action) {
      state.lenderOptions = action.payload;
    },

    addLenderSuccess(state, action) {
      state.lenders.push(action.payload);
    },

    updateLenderSuccess(state, action) {
      const id = _.get(action.payload, "id");
      const idx = _.findIndex(state.lenders, { id: id });
      state.lenders[idx] = _.merge(state.lenders[idx], action.payload);
      state.lenders[idx].states_lending = action.payload.states_lending;
      state.lenders[idx].asset_classes = action.payload.asset_classes;
      state.lenders[idx].supported_loan_products =
        action.payload.supported_loan_products;
      state.lenders[idx].fixed_loan_products =
        action.payload.fixed_loan_products;
      state.lenders[idx].prepayment_penalties =
        action.payload.prepayment_penalties;
      state.lenders[idx].recourse_provisions =
        action.payload.recourse_provisions;
      state.lenders[idx].borrower_requirements =
        action.payload.borrower_requirements;
    },

    updateCurrentLenderSuccess(state, action) {
      state.currentLender = action.payload;
      state.count = action.payload.count;
    },

    deleteLenderSuccess(state, action) {
      const id = _.get(action.payload, "id");
      state.lenders = _.remove(state.lenders, (lender) => lender.id != id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      updateNotificationStatusOnCreate.fulfilled,
      (state, action) => {
        const id = _.get(action.payload, "lender_id");
        const idx = _.findIndex(state.lenders, { id: id });
        state.lenders[idx].notification_status =
          action.payload.notification_status;
      }
    ),
      builder.addCase(
        updateNotificationStatusOnEdit.fulfilled,
        (state, action) => {
          const id = _.get(action.payload, "lender_id");
          const idx = _.findIndex(state.lenders, { id: id });
          state.lenders[idx].notification_status =
            action.payload.notification_status;
        }
      );
  },
});

export default lender.reducer;

// ----------------------------------------------------------------------

export function getLenders(
  query_params: LendersFilter = {},
  pagination: LenderTablePaginationProps
) {
  axiosLenderServices
    .get(`/`, { params: { ...query_params, ...pagination } })
    .then((response) => {
      dispatch(lender.actions.getLendersSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lender.actions.hasError(error));
    });
}

export function getLender(lenderId: number) {
  axiosLenderServices
    .get(`/${lenderId}`)
    .then((response) => {
      dispatch(lender.actions.getLenderSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lender.actions.hasError(error));
    });
}

export function getLendersStates() {
  axiosLenderServices
    .get("/states")
    .then((response) => {
      dispatch(lender.actions.getLendersStatesSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lender.actions.hasError(error));
    });
}

export function getLendersAssetClasses() {
  axiosLenderServices
    .get("/asset_classes")
    .then((response) => {
      dispatch(lender.actions.getLendersAssetsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lender.actions.hasError(error));
    });
}

export function getLenderTypes() {
  axiosLenderServices
    .get("/types")
    .then((response) => {
      dispatch(
        lender.actions.getLendersTypesSuccess(response.data["lender_types"])
      );
    })
    .catch((error) => {
      dispatch(lender.actions.hasError(error));
    });
}

export function getLenderOptions() {
  axiosLenderServices
    .get("/options")
    .then((response) => {
      dispatch(lender.actions.getLenderOptionsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lender.actions.hasError(error));
    });
}

export function createLender(newLender: Lender) {
  axiosLenderServices
    .post("/", newLender)
    .then((response) => {
      dispatch(lender.actions.addLenderSuccess(response.data));
      getLenderOptions();
    })
    .catch((error) => {
      dispatch(lender.actions.hasError(error));
    });
}

export function updateLender(updateLender: any) {
  axiosLenderServices
    .patch(`/${updateLender.id}`, updateLender)
    .then((response) => {
      dispatch(lender.actions.updateLenderSuccess(response.data));
      dispatch(lender.actions.updateCurrentLenderSuccess(response.data));
      getLenderOptions();
    })
    .catch((error) => {
      dispatch(lender.actions.hasError(error));
    });
}

export function deleteLender(lenderId: number, closeDrawer: any) {
  axiosLenderServices
    .delete(`/${lenderId}`)
    .then((response) => {
      dispatch(lender.actions.deleteLenderSuccess(response.data));
      closeDrawer();
    })
    .catch((error) => {
      dispatch(lender.actions.hasError(error));
    });
}

import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";

import { DEAL_TOOLTIP } from "pages/deal/constants/tooltip";
import { isDealViewPro } from "pages/deal/utils/deal_form";

export const ChartOverlay = (props: {
  showChart: boolean;
  text: string | JSX.Element;
  height: string;
  width: string;
  children: React.ReactNode;
}) => {
  const { children, showChart, text, height, width } = props;

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ width: width, height: height }}
    >
      <Box
        sx={{
          width: width,
          height: height,
          filter: showChart ? null : "blur(3px)",
          pointerEvents: showChart ? "inherit" : "none",
        }}
      >
        {children}
      </Box>
      {!showChart && (
        <Box
          sx={{
            display: "block",
            position: "absolute",
            borderRadius: "4px",
            padding: "4px 8px",
            margin: "2px",
            backgroundColor: "#fff",
            boxShadow:
              "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            transformOrigin: "center top",
            marginTop: "14px",
          }}
        >
          {typeof text === "string" ? (
            <Typography align="center">{text}</Typography>
          ) : (
            text
          )}
        </Box>
      )}
    </Stack>
  );
};

export const LoadingOverlay = (props: {
  showChart: boolean;
  height: string;
  width: string;
  children: React.ReactNode;
}) => {
  const { children, showChart, height, width } = props;

  return (
    <ChartOverlay
      showChart={showChart}
      text={
        <Stack direction="row" spacing={1.5} alignItems="center">
          <Typography>Gathering data... </Typography>
          <CircularProgress size={12} />
        </Stack>
      }
      height={height}
      width={width}
    >
      {children}
    </ChartOverlay>
  );
};

export const ProFeatureOverlay = (props: {
  children: React.ReactNode;
  height?: string;
  width?: string;
}) => {
  const { children, height = "auto", width = "100%" } = props;

  return (
    <ChartOverlay
      showChart={isDealViewPro()}
      text={DEAL_TOOLTIP.dealViewProFeature}
      height={height}
      width={width}
    >
      {children}
    </ChartOverlay>
  );
};

import authLogoFile from "/CREOneSource_R_Logo_Full-Colour2.png";

import {
  Box,
  Grid,
  MobileStepper,
  Stack,
  StepButton,
  StepConnector,
} from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Step from "@mui/material/Step";
import { stepConnectorClasses } from "@mui/material/StepConnector";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import _ from "lodash";

import { registrationSteps } from "pages/auth/constants/registration";
import AuthRegisterNoProduct from "pages/auth/register-no-product";
import { SubscriptionForm } from "pages/auth/sections/auth-forms/register-wizard/SubscriptionForm";
import { SubscriptionFormV2 } from "pages/auth/sections/auth-forms/register-wizard/SubscriptionFormV2";
import { TeamMembersForm } from "pages/auth/sections/auth-forms/register-wizard/TeamMembersForm";
import { UserDetailsForm } from "pages/auth/sections/auth-forms/register-wizard/UserDetailsForm";
import { UserPasswordForm } from "pages/auth/sections/auth-forms/register-wizard/UserPasswordForm";
import AuthCard from "pages/auth/sections/AuthCard";
import AuthFooterV2 from "pages/auth/sections/AuthFooterV2";
import AuthWrapper from "pages/auth/sections/AuthWrapper";

import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import { RootState, useSelector } from "store";
import {
  setRegistrationProduct,
  setRegistrationTrial,
  setRegistrationVersion,
} from "store/reducers/registrationData";
import { resetQueryParam } from "utils/resetQueryParam";

const getStepContent = (
  step: number,
  setActiveStep: (x: number) => void,
  setErrorIndex: (i: number | null) => void,
  version: any
) => {
  switch (step) {
    case 0:
      return (
        <UserDetailsForm
          handleNext={() => setActiveStep(0.1)}
          handleBack={() => setActiveStep(0)}
          setErrorIndex={setErrorIndex}
        />
      );
    case 0.1:
      return (
        <UserPasswordForm
          handleNext={() => setActiveStep(1)}
          handleBack={() => setActiveStep(0)}
          setErrorIndex={setErrorIndex}
        />
      );
    case 1:
      return (
        <TeamMembersForm
          handleNext={() => setActiveStep(2)}
          handleBack={(sso?: boolean) =>
            sso ? setActiveStep(0) : setActiveStep(0.1)
          }
          setErrorIndex={setErrorIndex}
        />
      );
    case 2:
      return version === "v2" ? (
        <SubscriptionFormV2
          handleNext={() => setActiveStep(3)}
          handleBack={() => setActiveStep(1)}
          setErrorIndex={setErrorIndex}
        />
      ) : (
        <SubscriptionForm
          handleNext={() => setActiveStep(3)}
          handleBack={() => setActiveStep(1)}
          setErrorIndex={setErrorIndex}
        />
      );
    default:
      console.error("Registration flow: Unknown step", step);
      throw new Error("Registration flow: Unknown step");
  }
};

const InvisibleConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    display: "none",
  },
}));

const RegistrationFlow = () => {
  const { accountData, product, version } = useSelector(
    (state: RootState) => state.registrationData
  );

  const [activeStep, setActiveStep] = useState<number>(0);
  const [errorIndex, setErrorIndex] = useState<number | null>(null);

  // Sync product to redux state
  const params = useParams();
  useEffect(() => {
    if (params.product) setRegistrationProduct(params.product);
  }, [params]);

  // Get query parameters
  const [searchParams, setSearchParams] = useSearchParams();

  // Sync trial status to redux state
  useEffect(() => {
    if (searchParams.get("trial") === "START_TRIAL") setRegistrationTrial(true);
  }, [searchParams]);

  // Sync version status to redux state
  useEffect(() => {
    if (searchParams.get("version") === "v2") setRegistrationVersion("v2");
  }, [searchParams]);

  // Navigate if SSO parameter is true
  useEffect(() => {
    const sso = searchParams.get("sso");
    if (sso && (sso || "").toLowerCase() === "true") {
      resetQueryParam(searchParams, setSearchParams, "sso");
      // Navigate to team members page
      setActiveStep(1);
    }
  }, [searchParams]);

  // Navigate if user has already completed the main register flow and is returning to the page
  useEffect(() => {
    if (accountData.orgSize && accountData.tokenHeader) {
      // Navigate to the subscription page
      setActiveStep(2);
    }
  }, []);

  const handleReset = () => {
    setActiveStep(0);
  };

  function handleStepClick(index: number) {
    // Prevent back nav if they've already completed registration
    if (!accountData.orgSize || !accountData.tokenHeader) {
      setActiveStep(index);
    }
  }

  return !params.product && !product ? (
    <AuthWrapper>
      <AuthCard>
        <AuthRegisterNoProduct />
      </AuthCard>
    </AuthWrapper>
  ) : (
    <Box sx={{ minHeight: "100vh" }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        sx={{
          minHeight: "100vh",
        }}
        columns={24}
      >
        <Grid item xs={5} sx={{ p: 3 }}>
          <Stack justifyContent="space-between" height={"100%"}>
            <Stack spacing={12}>
              <img src={authLogoFile} alt="CRE OneSource" width="211" />
              <Box sx={{ maxWidth: 400, px: 3 }}>
                <Stepper
                  activeStep={_.floor(activeStep)}
                  orientation="vertical"
                  connector={<InvisibleConnector />}
                >
                  {registrationSteps.map((step, index) => (
                    <Step key={step.title}>
                      <StepButton
                        color="inherit"
                        onClick={() => handleStepClick(index)}
                        optional={
                          <Typography variant="caption">
                            {step.subtitle}
                          </Typography>
                        }
                      >
                        {step.title}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                {activeStep === registrationSteps.length && (
                  <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                      Reset
                    </Button>
                  </Paper>
                )}
              </Box>
            </Stack>
            <AuthFooterV2 />
          </Stack>
        </Grid>
        <Grid item xs={true} sx={{ backgroundColor: "#FFF" }}>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            height={"100%"}
          >
            <Grid item xs={10} sm={8} md={6} lg={5} sx={{ minWidth: 650 }}>
              <Stack alignItems={"center"} spacing={1}>
                {getStepContent(
                  activeStep,
                  setActiveStep,
                  setErrorIndex,
                  version
                )}
                <MobileStepper
                  variant="dots"
                  steps={registrationSteps.length}
                  position="static"
                  activeStep={_.floor(activeStep)}
                  sx={{ maxWidth: 400, flexGrow: 1, backgroundColor: "#FFF" }}
                  nextButton={null}
                  backButton={null}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegistrationFlow;

import { Grid, Skeleton, Stack, Switch, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import * as yup from "yup";

import LoadingButton from "components/@extended/LoadingButton";
import MainCard from "components/MainCard";
import { registrationSteps } from "pages/auth/constants/registration";
import { formatCurrencyWholeNumber } from "pages/deal/utils/reporting";
import { dispatch, RootState, useSelector } from "store";
import { openErrorNotification } from "store/reducers/common";
import {
  setRegisterAccountData,
  setSubscriptionData,
} from "store/reducers/registrationData";
import { AccessTokenRead } from "types/api/user_management/access_token";
import { StripeData } from "types/api/user_management/user";
import { UserRegister } from "types/auth";
import {
  RegisterAccountData,
  RegistrationStepProps,
  SSODetailsData,
  TeamMembersData,
  UserDetailsData,
} from "types/authRegister";
import { Plan } from "types/billing";
import { axiosUserServices } from "utils/axios";

const validationSchema = yup.object({});

async function getMainRegisterFlow(
  sso: boolean,
  ssoDetailsData: SSODetailsData,
  userDetailsData: UserDetailsData,
  password: string,
  product: any,
  teamMembersData: TeamMembersData,
  price_id: string | undefined,
  trial: boolean
): Promise<any> {
  let orgSize = 1;

  const userRegister: UserRegister = {
    first_name: sso ? ssoDetailsData.first_name : userDetailsData.first_name,
    last_name: sso ? ssoDetailsData.last_name : userDetailsData.last_name,
    company: sso
      ? `${ssoDetailsData.first_name} ${ssoDetailsData.last_name} Company`
      : userDetailsData.company ||
        `${userDetailsData.first_name} ${userDetailsData.last_name} Company`,
    username: sso ? ssoDetailsData.username : userDetailsData.username,
    password: sso ? null : password,
    license_name: product, // Pass in license as string name
  };

  try {
    const registerResponse = await axiosUserServices.post(
      "/user/register/",
      userRegister,
      {
        params: {
          product_id: product,
          sso: sso,
        },
      }
    );

    if (registerResponse.status >= 400 && registerResponse.status < 500) {
      throw new Error("Registration failed");
    }

    const {
      user,
      token_header: tokenHeader,
    }: { user: AccessTokenRead; token_header: string } =
      registerResponse.data.data;

    // Add the team members, ignoring blank rows
    const pendingTeamData = _.reduce(
      teamMembersData,
      (acc: any[], value, idx) => {
        if (value.username) {
          return [
            ...acc,
            {
              username: value.username,
              first_name: value.first_name,
              last_name: value.last_name,
              organization_id: _.get(user, "organization_id", null),
              hierarchy_id: _.get(user, "hierarchy_id", null),
              role_name: value.role_type, // Pass in role as string name
              license_name: product, // Pass in license as string name
            },
          ];
        } else {
          return acc;
        }
      },
      []
    );

    // Update the org size
    if (pendingTeamData && pendingTeamData.length) {
      orgSize += pendingTeamData.length;
    }

    // Save progress to local storage
    setRegisterAccountData({ orgSize, tokenHeader });

    // Create team members if necessary
    if (orgSize > 1) {
      await axiosUserServices.post(`/organization/member/`, pendingTeamData, {
        headers: { Authorization: `Bearer ${tokenHeader}` },
      });
    }
    const checkoutData: StripeData = {
      price_id,
      quantity: orgSize,
      trial,
      sso,
    };
    const checkoutResponse = await axiosUserServices.post(
      `/account/checkout`,
      checkoutData,
      {
        headers: { Authorization: `BEARER ${tokenHeader}` },
      }
    );

    return checkoutResponse;
  } catch (error) {
    console.error("Error in registration flow:", error);
    Sentry.captureException("Unable to register new user.");
    throw error;
  }
}

async function getSkipRegisterFlow(
  price_id: string | undefined,
  accountData: RegisterAccountData,
  trial: boolean,
  sso: boolean
) {
  const checkoutData: StripeData = {
    price_id,
    quantity: accountData.orgSize,
    trial,
    sso,
  };
  const response = await axiosUserServices.post(
    `/account/checkout`,
    checkoutData,
    {
      headers: { Authorization: `BEARER ${accountData.tokenHeader}` },
    }
  );
  return response;
}

export const SubscriptionForm = ({
  handleNext,
  handleBack,
  setErrorIndex,
}: RegistrationStepProps) => {
  const {
    userDetailsData,
    password,
    teamMembersData,
    subscriptionData,
    sso,
    ssoDetailsData,
    product,
    trial,
    accountData,
  } = useSelector((state: RootState) => state.registrationData);

  const app_products: string[] = [product as string];

  const { data: planData = [] } = useQuery<Plan[]>({
    queryKey: ["stripePlans", app_products],
    queryFn: () =>
      axiosUserServices
        .post(`/account/pricing`, { plans: app_products })
        .then((response) => {
          if (_.isObject(response.data.data)) {
            return response.data.data;
          }
        }),
    retry: 3,
    staleTime: Infinity,
  });

  // Get the first plan returned
  const plan = useMemo(() => planData[0], [planData]);

  const formik = useFormik({
    initialValues: {
      isYearly: subscriptionData?.isYearly,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const price_id =
        plan?.price[values.isYearly ? "year" : "month"]?.price_external_id;

      setSubscriptionData({
        isYearly: values.isYearly,
      });

      // Check if the user has already been registered and is attempting to checkout again
      const registerFlow =
        accountData.orgSize && accountData.tokenHeader
          ? () => getSkipRegisterFlow(price_id, accountData, trial, sso)
          : () =>
              getMainRegisterFlow(
                sso,
                ssoDetailsData,
                userDetailsData,
                password,
                product,
                teamMembersData,
                price_id,
                trial
              );

      // Execute the desired register flow and redirect the user to the checkout screen
      try {
        const registerFlowResponse = await registerFlow();
        window.location.href = registerFlowResponse.data.url;
      } catch (error) {
        console.error("Registration error: ", error);
        dispatch(
          openErrorNotification(
            `An error occurred. Please contact Support to finish registering`
          )
        );
        throw new Error("Registration error");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { values, setFieldValue, isSubmitting } = formik;

  useEffect(() => {
    setSubscriptionData({
      isYearly: values.isYearly,
    });
  }, [values.isYearly]);

  const yearlyDiscount = useMemo(
    () =>
      12 * (plan?.price["month"]?.price ?? 0) -
      (plan?.price["year"]?.price ?? 0),
    [plan?.price]
  );

  return (
    <>
      <Typography variant="h3" gutterBottom sx={{ mb: 2 }}>
        {registrationSteps[2].title}
      </Typography>
      <form onSubmit={formik.handleSubmit} id="validation-forms">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Stack direction="row" spacing={1.5} alignItems="center">
                <Typography
                  variant="subtitle1"
                  color={values.isYearly ? "textSecondary" : "textPrimary"}
                >
                  Billed Monthly
                </Typography>
                <Switch
                  name="isYearly"
                  checked={values.isYearly}
                  onChange={(_event, checked) =>
                    setFieldValue("isYearly", checked)
                  }
                />
                <Typography
                  variant="subtitle1"
                  color={values.isYearly ? "textPrimary" : "textSecondary"}
                >
                  Billed Yearly
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <MainCard sx={{ pt: 1.75, height: "100%" }}>
                {!!plan ? (
                  <Stack direction={"column"}>
                    <Stack
                      direction="row"
                      spacing={2}
                      textAlign="center"
                      justifyContent={"space-between"}
                    >
                      <Typography variant="h4">{plan.name}</Typography>
                      <Stack>
                        <Typography variant="h2" component={"span"}>
                          {formatCurrencyWholeNumber(
                            plan.price[!values.isYearly ? "month" : "year"]
                              ?.price
                          )}
                          <Typography variant="caption"> / License</Typography>
                        </Typography>
                        <Typography sx={{ fontStyle: "italic" }}>
                          {values.isYearly && yearlyDiscount > 0
                            ? `${formatCurrencyWholeNumber(yearlyDiscount)} Discount!`
                            : "\u00A0"}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Typography>{plan.description}</Typography>
                  </Stack>
                ) : (
                  <Stack spacing={2} direction={"column"}>
                    <Stack
                      direction="row"
                      spacing={2}
                      textAlign="center"
                      justifyContent="space-between"
                    >
                      <Skeleton
                        variant="text"
                        width={125}
                        height={40}
                        animation="wave"
                      />
                      <Skeleton
                        variant="text"
                        width={90}
                        height={40}
                        animation="wave"
                      />
                    </Stack>
                    <Skeleton
                      variant="text"
                      width="100%"
                      height={22}
                      animation="wave"
                    />
                  </Stack>
                )}
              </MainCard>
            </Grid>
          </Grid>
          <Grid container item xs={12} alignItems={"center"}>
            <Grid item xs={2}>
              <LoadingButton
                disableElevation
                fullWidth
                size="large"
                variant="text"
                color="primary"
                onClick={() => handleBack()}
                disabled={!!(accountData.orgSize && accountData.tokenHeader)}
              >
                Back
              </LoadingButton>
            </Grid>
            <Grid item xs={true}></Grid>
            <Grid item xs={3}>
              <LoadingButton
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                loading={isSubmitting}
              >
                Subscribe
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

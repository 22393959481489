import { Box, Grid } from "@mui/material";
import _ from "lodash";
import { SchemaObjectDescription } from "yup";

import { FormRow } from "types/standardForm";

export const FragmentSection = ({
  formRows,
  yupDescription,
}: {
  heading: string;
  defaultExpanded: boolean;
  collapsable: boolean;
  formRows: FormRow[];
  yupDescription: SchemaObjectDescription;
}) => {
  return (
    <Box sx={{ px: 2, pt: 1, width: "100%" }}>
      <Grid
        container
        spacing={0}
        rowSpacing={1}
        columnSpacing={2}
        justifyContent={"start"}
        alignItems={"end"}
      >
        {_.map(formRows, (formRow) => {
          return _.map(formRow, (formField, field_index) => {
            return (
              <Grid
                item
                xs={formField.width}
                sx={formField.sx}
                key={field_index}
              >
                {/*{generateFieldComponent(*/}
                {/*  formField,*/}
                {/*  values,*/}
                {/*  yupDescription,*/}
                {/*  `${formField.field.fieldName}-${field_index}`,*/}
                {/*  dealOrgPreferences,*/}
                {/*  user*/}
                {/*)}*/}
              </Grid>
            );
          });
        })}
      </Grid>
    </Box>
  );
};

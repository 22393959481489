import { Theme } from "@mui/material/styles";

// ==============================|| OVERRIDES - INPUT LABEL ||============================== //

export default function InputLabel(theme: Theme) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          "&.MuiFormLabel-asterisk": {
            color: theme.palette.error.main,
            fontSize: "1.25rem",
            lineHeight: "0.8rem",
            position: "relative",
            top: "0.1rem", // Adjust this to fine-tune the position
          },
        },
        root: {
          color: theme.palette.text.primary,
          fontSize: "12px",
          fontWeight: 500,
          marginBottom: "2px !important",
          "&.Mui-disabled": {
            color: theme.palette.text.disabled,
          },
        },
        outlined: {
          lineHeight: "0.8em",
          "&.MuiInputLabel-sizeSmall": {
            lineHeight: "1em",
          },
          "&.MuiInputLabel-shrink": {
            background: theme.palette.background.paper,
            padding: "0 8px",
            marginLeft: -6,
            lineHeight: "1.4375em",
          },
        },
      },
    },
  };
}

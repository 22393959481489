import { Box, Stack } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import _ from "lodash";
import React from "react";
import { CellProps } from "react-table"; // v7

import { contactTableColumnsV8 } from "components/table/columns/contact";
import { ProspectStatusChip } from "components/table/field/MenuChip";
import { ContactMetadata } from "constants/objectMetadata/contactMetadata";
import { formatDateString } from "pages/deal/utils/reporting";
import { ProspectStar } from "pages/prospects/components/ProspectStar";
import { ContactTable } from "types/api/deal/contactTables";
import { ProspectStatusEnum } from "types/api/deal/enum";
import { Prospect } from "types/prospect";

export const defaultProspectsTableColumnOrder = [
  "full_name",
  "company_name",
  "next_activity_date",
  "next_activity_type",
  "next_activity_subject",
  "status",
  "notes",
];

export const prospectTableColumns = (
  theme: any,
  usersDisplay: { [p: string]: any; [p: number]: any }
) => {
  // NOTE: Since sorting is managed on the backend, be sure to update the sort field mapping in the ContactFilter object
  return [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ value, row }: CellProps<ContactTable>) => (
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Box>{value}</Box>
          <ProspectStar isProspect={_.get(row, "original.is_prospect")} />
        </Stack>
      ),
    },
    {
      Header: "Company Name",
      accessor: "company_name",
    },
    {
      Header: "Next Activity Date",
      accessor: "next_activity_date",
      Cell: ({ value }: any) => <>{formatDateString(value)}</>,
      className: "cell-right",
    },
    {
      Header: "Next Activity Type",
      accessor: "next_activity_type_name",
    },
    {
      Header: "Next Activity Subject",
      accessor: "next_activity_subject",
    },
    {
      Header: "Status",
      accessor: "status",
      id: "status",
      Cell: ({ value, row }: CellProps<Prospect>) => (
        <ProspectStatusChip value={value} recordId={row.original.id!} />
      ),
      onClick: () => null,
    },
  ];
};

export const prospectTableColumnsV8 = (
  theme: any,
  usersDisplay: { [p: string]: any; [p: number]: any }
): ColumnDef<ContactTable>[] => {
  const contactTableColumns = contactTableColumnsV8(theme, usersDisplay);
  // NOTE: Since sorting is managed on the backend, be sure to update the sort field mapping in the ContactFilter object
  return [
    ...contactTableColumns,
    {
      header: ContactMetadata.prospect_types.displayName,
      accessorKey: "prospect_types",
      id: "prospect_types",
      meta: {
        className: "cell-min-width-md",
      },
    },
    {
      header: ContactMetadata.asset_types.displayName,
      accessorKey: "asset_type_name",
      id: "asset_type_name",
      meta: {
        className: "cell-min-width-md",
      },
    },
    {
      header: ContactMetadata.property_names.displayName,
      accessorKey: "property_name_name",
      id: "property_name_name",
      meta: {
        className: "cell-min-width-md",
      },
    },
    {
      header: ContactMetadata.submarkets.displayName,
      accessorKey: "submarket_name",
      id: "submarket_name",
      meta: {
        className: "cell-min-width-md",
      },
    },
    {
      header: ContactMetadata.source_type.displayName,
      accessorKey: "source_type_name",
      id: "source_type_name",
      meta: {
        className: "cell-min-width-md",
      },
    },
    {
      header: ContactMetadata.client_industry.displayName,
      accessorKey: "client_industry_name",
      id: "client_industry_name",
      meta: {
        className: "cell-min-width-md",
      },
    },
    {
      header: "Estimated Size",
      accessorKey: "size_est_name",
      id: "size_est",
      meta: {
        className: "cell-min-width-md",
      },
    },
    {
      header: ContactMetadata.lease_expiration.displayName,
      accessorKey: "lease_expiration",
      id: "lease_expiration",
      meta: {
        className: "cell-right cell-min-width-md",
      },
    },
    {
      header: "Status",
      accessorKey: "status",
      id: "status",
      cell: ({ getValue, row }) => (
        <ProspectStatusChip
          value={getValue() as ProspectStatusEnum}
          recordId={row.original.id!}
        />
      ),
      meta: {
        onClick: () => {},
      },
    },
  ];
};

import { createBaseSlice } from "store/reducers/common";
import { BaseState, Keyed } from "types/common";
import { Deal } from "types/deal";

// ----------------------------------------------------------------------

const initialState: BaseState<Keyed<Deal>> = {
  loading: false,
  editing: false,
  saving: false,
  deleting: false,
  viewing: false,
  loadError: null,
  saveError: null,
  deleteError: null,
  data: {},
  current: null,
};

const slice = createBaseSlice({
  name: "deal",
  initialState,
  reducers: {},
});

// Reducer
export default slice.reducer;

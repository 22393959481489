import { useFormikContext } from "formik";
import { chain, get } from "lodash";
import React, { createContext, useContext, useMemo } from "react";
import { AnyObject, ObjectSchema } from "yup";

// Initialize context
const YupRequiredFieldsContext = createContext<Set<string>>(new Set());

// Create context provider
export function YupRequiredFieldsProvider({
  validationSchema,
  children,
}: {
  children: React.ReactNode;
  validationSchema: ObjectSchema<{}, AnyObject, {}, "">;
}) {
  const { values } = useFormikContext();

  // Get the live schema description hydrated with values
  const yupDescription = useMemo(
    () => validationSchema.describe({ value: values }),
    [validationSchema, values]
  );

  const requiredFieldsSet = useMemo(() => {
    const requiredFields = chain(yupDescription.fields)
      .pickBy((field) => !get(field, "optional", true)) // Pick fields where `optional` is `false`
      .keys() // Extract the keys (field names)
      .value(); // Get the final array

    return new Set(requiredFields);
  }, [yupDescription]);

  return (
    <YupRequiredFieldsContext.Provider value={requiredFieldsSet}>
      {children}
    </YupRequiredFieldsContext.Provider>
  );
}

// Custom hook to check membership in this context
export function useIsFieldRequired(fieldName: string): boolean {
  const requiredFieldsSet = useContext(YupRequiredFieldsContext);
  return requiredFieldsSet.has(fieldName);
}

import { FormikConfig } from "formik";
import { get, reduce } from "lodash";
import * as Yup from "yup";

import { AccessTokenRead } from "types/api/user_management/access_token";
import { ObjectMetadataV2 } from "types/standardFormV2";

export const deriveYupSchemaFromMetadataV2 = (metadata: ObjectMetadataV2) => {
  return Yup.object().shape(
    reduce(
      metadata,
      (acc, value) => ({
        ...acc,
        [value?.fieldName]: value?._schema,
      }),
      {}
    ),
    [
      ["email_address", "telephone1"],
      ["email_address", "telephone2"],
      ["telephone1", "telephone2"],
    ]
  );
};

// Custom hook to generate initial values based on metadata
export const getInitialValues = <
  R extends Record<string, any>,
  IC extends Record<string, any>,
>(
  metadata: ObjectMetadataV2,
  record: R | undefined,
  changes: IC,
  usersDisplay: Record<number, string>,
  user: AccessTokenRead
) => {
  // Create a new record with default/baseline values for fields
  const newRecord = reduce(
    metadata,
    (acc, value) => ({
      ...acc,
      [value?.fieldName]:
        typeof value?.initialValue === "function"
          ? value.initialValue(user)
          : value?.initialValue,
    }),
    {}
  );

  if (record) {
    const uiRecordObj = reduce(
      metadata,
      (acc, value) => {
        // Extract field name and load handler from metadata
        const { fieldName: fieldKey, loadHandler = (x: any) => x } = value;
        // Get field value via load handler. This allows any transformation needed between the api value and the UI value for the field.
        const fieldValue = loadHandler(
          get(record, value?.fieldName),
          usersDisplay
        );
        return { ...acc, [fieldKey]: fieldValue };
      },
      {}
    );
    // Merge actual record values over top of defaults/baselines
    return { ...newRecord, ...record, ...uiRecordObj, ...changes };
  } else {
    return { ...newRecord, ...changes };
  }
};

// Generate an onSubmit handler that will transform the form values as needed for the API
export function getOnSubmit(
  metadata: ObjectMetadataV2,
  submitData: (record: any) => Promise<unknown>
): FormikConfig<any>["onSubmit"] {
  return async (values, { setSubmitting }) => {
    try {
      const newRecord = reduce(
        metadata,
        (acc, value) => {
          const { virtual: isVirtual, submitFieldName, fieldName } = value;
          // Skip fields flagged as virtual
          if (isVirtual) return acc;

          const fieldKey = submitFieldName || fieldName;
          const fieldValue = get(
            value,
            "submitHandler",
            (x: any) => x
          )(get(values, fieldName));
          return { ...acc, [fieldKey]: fieldValue };
        },
        {}
      );
      await submitData(newRecord);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };
}

import { Grid } from "@mui/material";
import React, { Fragment } from "react";

import StandardField from "components/form/standard/StandardField";
import { ActivityFollowupMetadataSubset } from "constants/objectMetadata/activityFollowupMetadata";

export function ActivityFollowupFields() {
  return (
    <Fragment>
      <Grid item xs={12}>
        <StandardField
          field={ActivityFollowupMetadataSubset.followup_subject}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StandardField
          field={ActivityFollowupMetadataSubset.followup_activity_type_v2}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StandardField field={ActivityFollowupMetadataSubset.followup_option} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StandardField field={ActivityFollowupMetadataSubset.followup_date} />
      </Grid>
    </Fragment>
  );
}

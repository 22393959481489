// Data type for the registration User Details Form
export type UserDetailsData = {
  // User first name
  first_name: string;
  // User last name
  last_name: string;
  // User email
  username: string;
  // User company
  company: string;
};

export type SSODetailsData = {
  first_name: string;
  last_name: string;
  username: string;
  code: string | null;
  client_info: string | null;
  state: string | null;
  session_state: string | null;
};

export enum RoleType {
  lead = "lead",
  member = "member",
}

export enum RoleTypeV2 {
  super_admin = "Super Admin",
  team_member = "Team Member",
  individual = "Individual",
}

export enum SubscriptionInterval {
  six_months = "six_months",
  one_year = "one_year",
  two_years = "two_years",
}

// Data type for individual team members being added
export type TeamMemberInviteData = {
  // User first name
  first_name: string;
  // User last name
  last_name: string;
  // User email
  username: string;
  // User role
  role_type: RoleType | "";
};

// Data type for the registration Team Members Form
export type TeamMembersData = TeamMemberInviteData[];

// Data type for the registration Subscription Form
export type SubscriptionData = {
  // User selection for subscription time period
  isYearly: boolean; // Deprecated
  interval?: SubscriptionInterval | null;
};

// Data type for caching account data as the user is registering
export type RegisterAccountData = {
  tokenHeader: string | null;
  orgSize: number | null;
};

// Data type for management of the Registration Flow data and active step
export interface RegistrationData {
  // User Details form data
  userDetailsData: UserDetailsData;
  // SSO Details data
  ssoDetailsData: SSODetailsData;
  // User password (kept separate to not persist to local storage)
  password: string;
  // Team Members form data
  teamMembersData: TeamMembersData;
  // Subscription form data
  subscriptionData: SubscriptionData;
  // Whether the user registered through SSO or not
  sso: boolean;
  // The product the user is subscribing to
  product: string | null;
  // Whether the user is registering with a Trial or not
  trial: boolean;
  // Created account data
  accountData: RegisterAccountData;
  // Feature flag for new pricing rollout
  version: "v1" | "v2";
}

// Data type for unified registration step form props
export interface RegistrationStepProps {
  // Function to advance the registration flow to the next step
  handleNext: (sso?: boolean) => void;
  // Function to go back in the registration flow to the previous step
  handleBack: (sso?: boolean) => void;
  // Function to set the stepper error index if validation fails for a step
  setErrorIndex: (i: number | null) => void;
}

import {
  BusinessCenterOutlined,
  HandshakeOutlined,
  InterpreterModeOutlined,
  LocationCityOutlined,
  MoreHorizOutlined,
  OtherHousesOutlined,
  PermPhoneMsgOutlined,
  PortraitOutlined,
  SellOutlined,
  StoreOutlined,
} from "@mui/icons-material";

import { SimpleOptionWithColor } from "types/api/deal/form";

export const DEAL_CATEGORY_OPTIONS = [
  { key: "sale", label: "Sale", icon: LocationCityOutlined, pro: false },
  { key: "lease", label: "Lease", icon: StoreOutlined, pro: false },
  { key: "other", label: "Other", icon: OtherHousesOutlined, pro: true },
];
export const DEAL_TYPE_OPTIONS = [
  {
    key: "sale_both",
    label: "Sale - Both",
    category: "sale",
    icon: SellOutlined,
  },
  {
    key: "acquisition",
    label: "Acquisition",
    category: "sale",
    icon: HandshakeOutlined,
  },
  {
    key: "disposition",
    label: "Disposition",
    category: "sale",
    icon: SellOutlined,
  },
  {
    key: "lease_both",
    label: "Lease - Both",
    category: "lease",
    icon: BusinessCenterOutlined,
  },
  {
    key: "landlord_agency",
    label: "Landlord Agency",
    category: "lease",
    icon: BusinessCenterOutlined,
  },
  {
    key: "tenant_representation",
    label: "Tenant Representation",
    category: "lease",
    icon: PortraitOutlined,
  },
  {
    key: "referral",
    label: "Referral",
    category: "other",
    icon: PermPhoneMsgOutlined,
  },
  {
    key: "consulting",
    label: "Consulting",
    category: "other",
    icon: InterpreterModeOutlined,
  },
  { key: "other", label: "Other", category: "other", icon: MoreHorizOutlined },
];

export const DEAL_STATUS_OPTIONS: SimpleOptionWithColor[] = [
  { key: "open", label: "Open", color: "info" },
  { key: "closed", label: "Closed", color: "primary" },
  { key: "lost", label: "Lost", color: "secondary" },
];

export const IS_SHARED_OPTIONS = [
  { key: "true", label: "Shared" },
  { key: "false", label: "Solo" },
];

export const BROKER_ROLE_TOGGLE_OPTIONS = [
  { key: "executing", label: "Executing" },
  { key: "sourcing", label: "Sourcing" },
];

export const dealTypeDisplay = {
  acquisition: "Acquisition",
  disposition: "Disposition",
  sale_both: "Sale - Both",
  landlord_agency: "Landlord Agency",
  tenant_representation: "Tenant Representation",
  lease_both: "Lease - Both",
  referral: "Referral",
  consulting: "Consulting",
  other: "Other",
};

export const dealStatusDisplay = {
  open: "Open",
  lost: "Lost",
  closed: "Closed",
};

export const sizeTypeDisplay = {
  square_feet: "Sq. Ft.",
  acres: "Acres",
  units: "Units",
};

export const SIZE_TYPE_OPTIONS: any = [
  { key: "square_feet", label: sizeTypeDisplay.square_feet },
  { key: "acres", label: sizeTypeDisplay.acres },
  { key: "units", label: sizeTypeDisplay.units },
];

export const brokerRoleDisplay = {
  executing_and_sourcing: "Executing and Sourcing",
  sourcing: "Sourcing",
  executing: "Executing",
  none: "N/A",
};

export const BROKER_ROLE_OPTIONS = [
  { key: "executing_and_sourcing", label: "Executing and Sourcing" },
  { key: "executing", label: "Executing" },
  { key: "sourcing", label: "Sourcing" },
  { key: "none", label: "N/A" },
];

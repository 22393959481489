import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { Role } from "types/api/user_management/user";
import { FormIdentifier } from "types/record";
import { axiosUserServices } from "utils/axios";
import { handleRecordActionResponseV2 } from "utils/record";

export async function createRoleAsync(role: Role) {
  try {
    const response = await axiosUserServices.post(`/organization/role/`, role);
    handleRecordActionResponseV2(
      "Role",
      "created",
      FormIdentifier.RoleForm,
      response
    );
    return response.data.data;
  } catch (error) {
    dispatch(openErrorNotification("Error creating role."));
    throw error;
  }
}

export async function updateRoleAsync(
  id: number | string,
  role: Partial<Role>
) {
  try {
    const response = await axiosUserServices.put(
      `/organization/role/${id}`,
      role
    );
    handleRecordActionResponseV2(
      "Role",
      "updated",
      FormIdentifier.RoleForm,
      response
    );
    return response.data.data;
  } catch (error) {
    dispatch(openErrorNotification("Error updating role."));
    throw error;
  }
}

export async function deleteRoleAsync(id: number | string) {
  try {
    const response = await axiosUserServices.delete(`/organization/role/${id}`);
    handleRecordActionResponseV2(
      "Role",
      "deleted",
      FormIdentifier.RoleForm,
      response
    );
  } catch (error) {
    dispatch(
      openErrorNotification(
        "Error deleting role. Make sure to move any team members to another role before deleting."
      )
    );
    throw error;
  }
}

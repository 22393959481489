import { Fragment } from "react";

import { DeleteContactBulkForm } from "components/form/forms/DeleteContactBulkForm";
import { EditContactBulkForm } from "components/form/forms/EditContactBulkForm";
import { ShareContactBulkForm } from "components/form/forms/ShareContactBulkForm";
import { ShareContactForm } from "components/form/forms/ShareContactForm";
import {
  FormInstance,
  useFormDialogs,
  usePopForm,
} from "contexts/FormDialogsContext";

const formComponentMap = {
  ShareContactForm: ShareContactForm,
  ShareContactBulkForm: ShareContactBulkForm,
  EditContactBulkForm: EditContactBulkForm,
  DeleteContactBulkForm: DeleteContactBulkForm,
};

export const FormDialogsStack = () => {
  const forms = useFormDialogs();

  if (forms.length === 0) {
    return null;
  }

  return (
    <Fragment>
      {forms.map((form, index) => (
        <FormDialog key={index} form={form} />
      ))}
    </Fragment>
  );
};

const FormDialog = ({ form }: { form: FormInstance }) => {
  const popForm = usePopForm();

  const FormComponent = formComponentMap[form.identifier];

  return (
    <FormComponent
      id={form.id}
      changes={form.changes}
      successCallback={form.successCallback}
      handleClose={popForm}
      meta={form.meta}
    />
  );
};

import { InputAdornment } from "@mui/material";
import { useField } from "formik";
import _ from "lodash";
import NumberFormat from "react-number-format";

import { PercentTextField } from "components/form/NumberFormatTextField";
import { FieldComponentProps } from "types/standardForm";

import { CreoneField } from "./basic/creone_field";

interface TextFieldPercentProps extends FieldComponentProps {
  size?: any;
}

function GetTextFieldPercent({
  size,
  fieldName,
  disabled,
  additionalChangeHandler,
}: TextFieldPercentProps) {
  const [field, , helpers] = useField(fieldName);
  const handleBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <NumberFormat
      size={size}
      fullWidth
      id={fieldName}
      thousandSeparator={false}
      fixedDecimalScale={false}
      decimalScale={2}
      placeholder="0"
      disabled={disabled}
      value={_.isNumber(field.value) ? field.value * 100 : field.value}
      onValueChange={(val, sourceInfo) => {
        if (sourceInfo.source === "event") {
          const floatVal = _.isNumber(val.floatValue)
            ? val.floatValue / 100
            : val.floatValue;
          helpers.setValue(floatVal);
        }
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      onBlur={handleBlur}
      customInput={PercentTextField}
    />
  );
}

export function TextFieldPercent(props: TextFieldPercentProps) {
  return (
    <CreoneField {...props}>
      <GetTextFieldPercent
        displayName={props.displayName}
        size={"medium"}
        fieldName={props.fieldName}
        disabled={props.disabled}
        additionalChangeHandler={props.additionalChangeHandler}
      />
    </CreoneField>
  );
}

import StarIcon from "@mui/icons-material/Star";
import React from "react";

import { LightTooltip } from "pages/deal/components/InfoTooltip";

export function ProspectStar({ isProspect }: { isProspect?: boolean }) {
  return isProspect ? (
    <LightTooltip title={"This contact is a Prospect"}>
      <StarIcon sx={{ fontSize: 24, color: "gold", mb: -0.6 }} />
    </LightTooltip>
  ) : null;
}

export function PrintProspectStar({
  isProspect,
  size,
}: {
  isProspect?: boolean;
  size: number;
}) {
  return isProspect ? (
    <StarIcon sx={{ fontSize: size, color: "gold", mb: -0.6 }} />
  ) : null;
}

import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";

import StandardField from "components/form/standard/StandardField";
import useFieldVisibility from "hooks/useFieldVisibility";

export const DealFormSection = (props: {
  title?: string;
  sx?: any;
  children: any;
  titleAction?: any;
  divider?: boolean;
  sectionIcon?: any;
  sectionIconColor?: string;
}) => {
  const {
    title,
    titleAction,
    children,
    sx,
    divider = true,
    sectionIcon,
    sectionIconColor,
  } = props;

  const Icon = sectionIcon;

  return (
    <Box component="section" sx={{ overflow: "visible", ...sx }}>
      <Stack sx={{ my: divider ? 5 : 0 }}>
        <Grid container rowSpacing={5} alignItems="center">
          {title && (
            <>
              <Grid item xs={12} md={titleAction ? 4 : 12}>
                <Grid container gap={2}>
                  {sectionIcon && (
                    <Grid item sx={{ position: "relative" }}>
                      <div
                        style={{
                          height: "30px",
                          width: "30px",
                          borderRadius: "50%",
                          backgroundColor: sectionIconColor,
                        }}
                      />
                      <Icon
                        fontSize="small"
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translateX(-50%) translateY(-50%)",
                          color: "white",
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <Typography variant="h4" sx={{ lineHeight: 1.5 }}>
                      {title}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {titleAction && (
                <Grid item xs={12} md={8}>
                  {titleAction}
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12}>
            <Box sx={{ overflow: "visible" }}>{children}</Box>
          </Grid>
        </Grid>
        {divider && <Divider sx={{ mt: 5 }} />}
      </Stack>
    </Box>
  );
};

export const GridField = ({ field, children, ...rest }: any) => {
  const isVisible = useFieldVisibility(field);

  if (isVisible) {
    return <Grid {...rest}>{children}</Grid>;
  }

  return null;
};

export const StandardGridField = ({ field, ...rest }: any) => {
  const isVisible = useFieldVisibility(field);

  if (isVisible) {
    return (
      <Grid {...rest}>
        <StandardField {...rest} field={field} />
      </Grid>
    );
  }

  return null;
};

import { ActivityForm } from "components/form/forms/ActivityForm";
import { CommissionGoalForm } from "components/form/forms/CommissionGoalForm";
import { CompanyForm } from "components/form/forms/CompanyForm";
import { ContactForm } from "components/form/forms/ContactForm";
import { ContactNameOnlyForm } from "components/form/forms/ContactNameOnlyForm";
import { ContactNotesForm } from "components/form/forms/ContactNotesForm";
import { ContactQuickCreateForm } from "components/form/forms/ContactQuickCreateForm";
import { ConvertToProspectForm } from "components/form/forms/ConvertToProspectForm";
import { DealClosedForm } from "components/form/forms/DealClosedForm";
import { DealLostForm } from "components/form/forms/DealLostForm";
import { DealNotesForm } from "components/form/forms/DealNotesForm";
import { DealPreviewForm } from "components/form/forms/DealPreviewForm";
import { DealViewProForm } from "components/form/forms/DealViewProForm";
import { OptionValueForm } from "components/form/forms/OptionValueForm";
import { QuickAddWizardForm } from "components/form/forms/QuickAddWizardForm";
import { RoleForm } from "components/form/forms/RoleForm";
import { TeamForm } from "components/form/forms/TeamForm";
import { UserForm } from "components/form/forms/UserForm";
import { FormIdentifier, FormIdentifierMapType } from "types/record";

export const formIdentifierMap: FormIdentifierMapType = {
  [FormIdentifier.DealViewProForm]: {
    component: DealViewProForm,
    loadUrl: "/",
  },
  [FormIdentifier.DealPreviewForm]: {
    component: DealPreviewForm,
    loadUrl: "/",
  },
  [FormIdentifier.DealNotesForm]: {
    component: DealNotesForm,
    loadUrl: "/",
  },
  [FormIdentifier.DealLostForm]: {
    component: DealLostForm,
    loadUrl: "/",
  },
  [FormIdentifier.DealClosedForm]: {
    component: DealClosedForm,
    loadUrl: "/",
  },
  [FormIdentifier.CommissionGoalForm]: {
    component: CommissionGoalForm,
    loadUrl: "/commission_goal/",
  },
  [FormIdentifier.CompanyForm]: {
    component: CompanyForm,
    loadUrl: "/company/",
  },
  [FormIdentifier.ContactForm]: {
    component: ContactForm,
    loadUrl: "/contact/",
  },
  [FormIdentifier.ContactQuickCreateForm]: {
    component: ContactQuickCreateForm,
    loadUrl: "/contact/",
  },
  [FormIdentifier.ContactNameOnlyForm]: {
    component: ContactNameOnlyForm,
    loadUrl: "/contact/",
  },
  [FormIdentifier.ContactNotesForm]: {
    component: ContactNotesForm,
    loadUrl: "/contact/",
  },
  [FormIdentifier.ConvertToProspectForm]: {
    component: ConvertToProspectForm,
    loadUrl: "/contact/",
  },
  [FormIdentifier.ActivityForm]: {
    component: ActivityForm,
    loadUrl: "/activity/",
  },
  [FormIdentifier.QuickAddWizardForm]: {
    component: QuickAddWizardForm,
    loadUrl: null,
  },
  [FormIdentifier.RoleForm]: {
    component: RoleForm,
    loadUrl: "/organization/role/",
    service: "user",
  },
  [FormIdentifier.UserForm]: {
    component: UserForm,
    loadUrl: "/user/team/member/v2/",
    service: "user",
  },
  [FormIdentifier.TeamForm]: {
    component: TeamForm,
    loadUrl: "/user/team/",
    service: "user",
  },
  [FormIdentifier.OptionForm]: {
    component: OptionValueForm,
    loadUrl: "/option/",
  },
};

import { useTheme } from "@mui/material/styles";

// platform
import logoIconFile from "../../assets/images/logo-text-white.png";
import logoIconDarkFile from "../../assets/images/onesource-logo-mark.png";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'asset/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = () => {
  const theme = useTheme();

  return (
    <img
      src={theme.palette.mode === "dark" ? logoIconDarkFile : logoIconFile}
      alt="Mantis"
      width="211"
    />
  );
};

export default LogoMain;

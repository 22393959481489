import { FeatureFlagsType, FeatureFlagName } from "./types/feature_flag";

// User Groups
const developers = {
  "alex.hicks@creonesource.com": true,
};

const product_org = {
  ...developers,
};

const creonesource = {
  "sam.absalom@creonesource.com": true,
  "charlie.coppola@creonesource.com": true,
  ...product_org,
};

const creonesource_org = {
  "1": true,
};

const demo_org = {
  "2": true,
};

const craig_coppola_org = {
  "29": true,
};

/**
 * ================================================================================================
 * FEATURE FLAGS
 * ================================================================================================
 */
export const feature_flag: FeatureFlagsType = {
  /**
   * Description: Dashboard for quick insights to users deal data
   * Date: 2023-05-24
   */
  [FeatureFlagName.LICENSE_MANAGEMENT_FLAG]: {
    flag: import.meta.env.VITE_LICENSE_MANAGEMENT_FLAG === "true",
    user: {
      ...developers,
    },
    organization: {},
  },
};

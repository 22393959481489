import { Box, Checkbox } from "@mui/material";
import { FormikValues, useFormikContext } from "formik";
import React from "react";

import { FieldComponentProps } from "types/standardForm";

import { CreoneField } from "./basic/creone_field";

export function CheckboxField(props: FieldComponentProps) {
  const { getFieldProps, setFieldValue, values } = useFormikContext();
  const fieldProps = getFieldProps(props.fieldName);
  // Extract the 'checked' state from Formik and spread the rest of the props
  const { value, onChange, ...checkboxProps } = fieldProps;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof props.additionalChangeHandler === "function") {
      props.additionalChangeHandler(
        e.target.checked,
        setFieldValue,
        value,
        values as FormikValues
      );
    }
    onChange(e);
  };
  return (
    <CreoneField {...props}>
      <Box sx={{ mt: -0.5 }}>
        <Checkbox
          className="size-medium"
          id={props.fieldName}
          checked={value} // Use the 'value' from Formik as 'checked' state
          onChange={handleChange}
          {...checkboxProps} // Spread other props like 'onChange' and 'onBlur'
        />
      </Box>
    </CreoneField>
  );
}

import { DialogContent, Grid } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import * as Sentry from "@sentry/react";
import React from "react";

import StandardField from "components/form/standard/StandardField";
import { StandardForm } from "components/form/standard/StandardForm";
import { StandardRecordFormTitle } from "components/form/StandardRecordFormTitle";
import MainCard from "components/MainCard";
import { UserMetadata } from "constants/objectMetadata/userMetadata";
import { User } from "types/api/user_management/user";
import { StandardFormProps } from "types/record";
import { FormSubmitAction } from "types/standardForm";
import { createUserAsync, updateUserAsync } from "utils/user";

export const UserForm = ({
  record,
  incomingChanges,
  onCancel,
  successCallback,
  open,
  showAddAnother = true,
}: StandardFormProps<User>) => {
  const createFn = async (
    obj: User,
    submitAction: FormSubmitAction = FormSubmitAction.save
  ) => {
    try {
      const record = await createUserAsync(obj);
      if (typeof successCallback === "function") successCallback(record);
    } catch (error) {
      Sentry.captureException("Unable to create User.");
    }
  };

  const updateFn = async (id: number | string, obj: Partial<User>) => {
    try {
      const record = await updateUserAsync(id, obj);
      if (typeof successCallback === "function") successCallback(record);
    } catch (error) {
      Sentry.captureException("Unable to update User.");
    }
  };

  return (
    <StandardForm<User>
      metadata={UserMetadata}
      record={record}
      updateFn={updateFn}
      createFn={createFn}
      displayName={"User"}
      onCancel={onCancel}
      incomingChanges={incomingChanges}
      showAddAnother={showAddAnother}
      open={open}
      maxWidth={"sm"}
      fullWidth
    >
      {({ StandardFormActionsInstance }) => (
        <>
          <StandardRecordFormTitle
            recordName={"User"}
            isEdit={!!record?.id}
            onClose={onCancel}
          />
          <DialogContent>
            <MainCard>
              <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item xs={6}>
                  <StandardField field={UserMetadata.first_name} />
                </Grid>
                <Grid item xs={6}>
                  <StandardField field={UserMetadata.last_name} />
                </Grid>
                <Grid item xs={12}>
                  <StandardField field={UserMetadata.username} />
                </Grid>
                <Grid item xs={12}>
                  <StandardField field={UserMetadata.hierarchy} />
                </Grid>
                <Grid item xs={12}>
                  <StandardField field={UserMetadata.role} />
                </Grid>
                <Grid item xs={12}>
                  <StandardField field={UserMetadata.licenses} />
                </Grid>
              </Grid>
            </MainCard>
          </DialogContent>
          <DialogActions>{StandardFormActionsInstance}</DialogActions>
        </>
      )}
    </StandardForm>
  );
};

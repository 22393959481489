import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

// ==============================|| NAVIGATION - SCROLL TO TOP ||============================== //

export const handleScrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

const ScrollTop = ({ children }: { children: ReactNode | null }) => {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    handleScrollToTop();
  }, [pathname]);

  return children || null;
};

export default ScrollTop;

import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import DrawerContent from "./DrawerContent";
import DrawerHeader from "./DrawerHeader";
import DrawerStyled from "./DrawerStyled";

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const MainDrawer = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box component="nav" aria-label="mailbox folders">
      <DrawerStyled
        variant={!matchDownMd ? "permanent" : "temporary"}
        open={open}
        onClose={handleDrawerToggle}
      >
        <DrawerHeader open={open} />
        <DrawerContent />
      </DrawerStyled>
    </Box>
  );
};

export default MainDrawer;

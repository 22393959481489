import * as Yup from "yup";

import { userOptionsLoadHandler } from "components/form/standard/utils/metadata";
import { EditType } from "types/api/deal/contact_bulk";
import { SimpleOption } from "types/api/deal/form";
import { ObjectMetadataV2 } from "types/standardFormV2";

export const ShareContactBulkMetadata: ObjectMetadataV2 = {
  accessible_by_user_ids: {
    fieldName: "accessible_by_user_ids",
    displayName: "Shared users",
    initialValue: [],
    _schema: Yup.array(),
    submitHandler: (x: SimpleOption[]) => x.map((y) => y.key),
    loadHandler: userOptionsLoadHandler,
  },
  edit_type: {
    fieldName: "edit_type",
    displayName: "Share Type",
    initialValue: EditType.append,
    _schema: Yup.string().oneOf(["append", "replace"]),
  },
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  BrokerWidgetsConfig,
  ChartToggleOption,
  defaultBrokerWidgetsConfig,
} from "pages/deal/sections/constants/dealWidgets";

import { dispatch } from "../index";

interface DealWidgetsConfigState {
  brokerInsights: BrokerWidgetsConfig;
}

const initialState: DealWidgetsConfigState = {
  brokerInsights: defaultBrokerWidgetsConfig,
};

const slice = createSlice({
  name: "dealWidgetsConfig",
  initialState,
  reducers: {
    // Update Broker Insights config
    updateBrokerInsightsConfig(
      state: DealWidgetsConfigState,
      action: PayloadAction<Record<string, ChartToggleOption>>
    ) {
      state.brokerInsights = { ...state.brokerInsights, ...action.payload };
    },
  },
});

// Reducer
export default slice.reducer;

export function updateBrokerInsightsWidgetConfig(
  x: Record<string, ChartToggleOption>
) {
  dispatch(slice.actions.updateBrokerInsightsConfig(x));
}

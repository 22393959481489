import _ from "lodash";
import React, { createContext, useContext } from "react";
import { useQuery } from "react-query";

import { DealQueryKeys } from "pages/deal/constants/dealQueryKeys";
import { DealFeature, DealField, DealOrgPreferences } from "types/deal";
import { dealService } from "utils/axios";

interface DealOrgPreferencesContextType {
  data: DealOrgPreferences | null;
  isSuccess: boolean;
}

// Initialize context
const DealOrgPreferencesContext = createContext<DealOrgPreferencesContextType>({
  data: null,
  isSuccess: false,
});

// Create context provider
export function DealOrgPreferencesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // Get org preferences
  const { data = null, isSuccess } = useQuery<DealOrgPreferences>({
    queryKey: [DealQueryKeys.dealOrgPreferences],
    queryFn: () => {
      return dealService.get("/org_preferences/").then((res) => {
        if (res.data) {
          return res.data;
        }
      });
    },
    retry: 3,
    staleTime: Infinity,
  });

  return (
    <DealOrgPreferencesContext.Provider value={{ data, isSuccess }}>
      {children}
    </DealOrgPreferencesContext.Provider>
  );
}

// Custom hook to use this context
export function useDealOrgPreferences() {
  const context = useContext(DealOrgPreferencesContext);
  if (!context) {
    throw new Error(
      "useDealOrgPreferences must be used within a DealOrgPreferencesProvider"
    );
  }
  return context;
}

export function isFeatureEnabledOrgPreferences(
  dealOrgPreferences: DealOrgPreferences | null,
  feature: DealFeature
) {
  return _.includes(_.get(dealOrgPreferences, "deal_features"), feature);
}

export function isWeightedEnabledOrgPreferences(
  dealOrgPreferences: DealOrgPreferences | null
) {
  return (
    isFeatureEnabledOrgPreferences(
      dealOrgPreferences,
      DealFeature.WEIGHTED_COMMISSION
    ) ||
    isFeatureEnabledOrgPreferences(
      dealOrgPreferences,
      DealFeature.WEIGHTED_TRANSACTION_VALUE
    )
  );
}

export function isFieldVisibleOrgPreferences(
  dealOrgPreferences: DealOrgPreferences | null,
  field: DealField
) {
  return _.includes(_.get(dealOrgPreferences, "deal_fields_visible"), field);
}

export function isFieldRequiredOrgPreferences(
  dealOrgPreferences: DealOrgPreferences | null,
  field: DealField
) {
  return (
    isFieldVisibleOrgPreferences(dealOrgPreferences, field) &&
    _.includes(_.get(dealOrgPreferences, "deal_fields_required"), field)
  );
}

import { Grid, Link, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import _ from "lodash";
import React, { Fragment, ReactNode, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { ActivityFollowupFields } from "components/form/forms/sections/ActivityFollowupFields";
import { ActivityTimeline } from "components/form/forms/sections/ActivityTimeline/ActivityTimeline";
import { EditableActivityTimelineEntry } from "components/form/forms/sections/ActivityTimeline/EditableActivityTimelineEntry";
import {
  EmailTimelineEntry,
  OutlookEmailTimelineEntry,
} from "components/form/forms/sections/ActivityTimeline/EmailTimelineEntry";
import { PendingActivityTimelineEntry } from "components/form/forms/sections/ActivityTimeline/PendingActivityTimelineEntry";
import { TimelineEntry } from "components/form/forms/sections/ActivityTimeline/TimelineEntry";
import MainCard from "components/MainCard";
import { useCounter } from "contexts/CounterContext";
import { ActivityRead } from "types/api/deal/activity";
import { ContactRead } from "types/api/deal/contact";
import {
  TimelineObject,
  TimelineObjectTypeEnum,
} from "types/api/deal/contact_timeline";
import { EmailRead } from "types/api/deal/email";

interface TimelineEntryDetails {
  id: number | string | null;
  datetime: Date;
  component: ReactNode;
}

export const TimelineObjectEntry = ({
  record,
  timelineRefetch,
}: {
  record: TimelineObject;
  timelineRefetch: () => void;
}) => {
  if (record.type === TimelineObjectTypeEnum.email)
    return (
      <EmailTimelineEntry
        record={record.metadata as EmailRead}
        timelineRefetch={timelineRefetch}
      />
    );
  if (record.type === TimelineObjectTypeEnum.outlook_email)
    return (
      <OutlookEmailTimelineEntry
        record={record.metadata as EmailRead}
        timelineRefetch={timelineRefetch}
      />
    );

  return (
    <EditableActivityTimelineEntry
      record={record.metadata as ActivityRead}
      key={`activity-${record?.id}`}
      defaultExpanded={false}
    />
  );
};

const convertActivityIntoTimelineRecord = (
  record: TimelineObject,
  timelineRefetch: () => void
): TimelineEntryDetails => {
  return {
    id: record?.id ?? null,
    datetime: dayjs(record.date).toDate(),
    component: (
      <TimelineObjectEntry record={record} timelineRefetch={timelineRefetch} />
    ),
  };
};

function ContactFollowupSection() {
  return (
    <TimelineEntry
      datetime={null}
      color={"primary"}
      avatarContents={null}
      title={null}
      activityBody={
        <MainCard>
          <Grid container columnSpacing={2} rowSpacing={1}>
            <ActivityFollowupFields />
          </Grid>
        </MainCard>
      }
      activityBodyPreview={""}
      expanded={true}
      setExpanded={() => {}}
    />
  );
}

export const ActivityTimelineSection = ({
  children,
  contact_record,
  upcoming,
  completed,
  activityTimelineRefetch,
}: {
  children: ReactNode;
  contact_record: ContactRead | null;
  upcoming: TimelineObject[];
  completed: TimelineObject[];
  activityTimelineRefetch: () => void;
}) => {
  const [pendingUpcomingActivities, setPendingUpcomingActivities] = useState<
    Array<Record<string, any>>
  >([]);
  const [pendingCompletedActivities, setPendingCompletedActivities] = useState<
    Array<Record<string, any>>
  >([]);

  const { values: contactValues } = useFormikContext();
  const { increment: incrementOpenActivityCount } = useCounter();

  // Prepare functions in component scope
  const convertToUpcomingTimelineRecord = (x: TimelineObject) =>
    convertActivityIntoTimelineRecord(x, activityTimelineRefetch);
  const convertToCompletedTimelineRecord = (x: TimelineObject) =>
    convertActivityIntoTimelineRecord(x, activityTimelineRefetch);

  const upcomingActivities = useMemo(
    () => _.map(upcoming, (x) => convertToUpcomingTimelineRecord(x)),
    [upcoming]
  );
  const completedActivities = useMemo(
    () => _.map(completed, (x) => convertToCompletedTimelineRecord(x)),
    [completed]
  );

  const addNewActivity = (is_complete: boolean) => {
    const newActivityIncomingChanges = {
      id: uuidv4(),
      contact_id: contact_record?.id,
      contact: { key: contact_record?.id, label: contact_record?.full_name },
      is_complete,
      date: is_complete ? new Date() : null,
      _pending: true,
    };

    incrementOpenActivityCount();

    if (is_complete) {
      setPendingCompletedActivities((x) => [...x, newActivityIncomingChanges]);
    } else {
      setPendingUpcomingActivities((x) => [...x, newActivityIncomingChanges]);
    }
  };

  const isUpcomingSectionEmpty =
    !_.get(contactValues, "is_followup") &&
    _.isEmpty(pendingUpcomingActivities) &&
    _.isEmpty(upcomingActivities);
  const isCompletedSectionEmpty =
    _.isEmpty(pendingCompletedActivities) && _.isEmpty(completedActivities);

  return (
    <Stack spacing={3} sx={{ width: "100%" }}>
      <MainCard
        title={"Upcoming Activities"}
        sx={
          !isUpcomingSectionEmpty
            ? {
                backgroundColor: "#CFE0D1",
                ".MuiCardHeader-root": {
                  backgroundColor: "#B3CBB6",
                },
              }
            : {}
        }
        secondary={
          <>
            {!_.isEmpty(upcomingActivities) ? (
              <Link
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  addNewActivity(false);
                }}
              >
                <Typography fontWeight={700}>+ Add</Typography>
              </Link>
            ) : (
              <></>
            )}
          </>
        }
      >
        <ActivityTimeline addNewActivity={() => addNewActivity(false)}>
          {!isUpcomingSectionEmpty && (
            <Fragment>
              {_.get(contactValues, "is_followup") && (
                <Grid item xs={12} key={"contact-followup-section"}>
                  <ContactFollowupSection />
                </Grid>
              )}
              {_.map(pendingUpcomingActivities, (activity) => (
                <Grid
                  item
                  xs={12}
                  key={`grid-item-pending-activity-${activity?.id}`}
                >
                  <PendingActivityTimelineEntry
                    record={activity}
                    setPendingActivities={setPendingUpcomingActivities}
                    timelineRefetch={activityTimelineRefetch}
                  />
                </Grid>
              ))}
              {_.map(upcomingActivities, (timelineRecord) => (
                <Grid
                  item
                  xs={12}
                  key={`grid-item-activity-${timelineRecord?.id}`}
                >
                  {timelineRecord.component}
                </Grid>
              ))}
            </Fragment>
          )}
        </ActivityTimeline>
      </MainCard>
      {children}
      {!!contact_record?.id && (
        <MainCard
          title={"Completed Activities"}
          secondary={
            <>
              {!_.isEmpty(completedActivities) ? (
                <Link
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    addNewActivity(true);
                  }}
                >
                  + Add
                </Link>
              ) : (
                <></>
              )}
            </>
          }
        >
          <ActivityTimeline addNewActivity={() => addNewActivity(true)}>
            {!isCompletedSectionEmpty && (
              <Fragment>
                {_.map(pendingCompletedActivities, (activity) => (
                  <Grid
                    item
                    xs={12}
                    key={`grid-item-pending-activity-${activity?.id}`}
                  >
                    <PendingActivityTimelineEntry
                      record={activity}
                      setPendingActivities={setPendingCompletedActivities}
                      timelineRefetch={activityTimelineRefetch}
                    />
                  </Grid>
                ))}
                {_.map(completedActivities, (timelineRecord) => (
                  <Grid
                    item
                    xs={12}
                    key={`grid-item-activity-${timelineRecord.id}`}
                  >
                    {timelineRecord.component}
                  </Grid>
                ))}
              </Fragment>
            )}
          </ActivityTimeline>
        </MainCard>
      )}
    </Stack>
  );
};

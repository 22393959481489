import { InfoCircleFilled } from "@ant-design/icons";
import { Alert, Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";

const checkoutPaths = ["/settings/team/checkout", "/settings/team/member"];

export function PendingTeamAlertBar() {
  let location = useLocation();
  const [pendingTeam] = useLocalStorageState("pending-team-details", {
    defaultValue: [],
  });

  return (
    <>
      {!!pendingTeam &&
        !!pendingTeam.length &&
        !checkoutPaths.includes(location.pathname) && (
          <Box sx={{ pb: 2 }}>
            <Link
              to="/settings/team/checkout"
              style={{ textDecoration: "none" }}
            >
              <Alert color="warning" icon={<InfoCircleFilled />}>
                You have new team members pending. Click here to continue the
                checkout process.
              </Alert>
            </Link>
          </Box>
        )}
    </>
  );
}

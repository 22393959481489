import { useEffect } from "react";
import { Navigate } from "react-router";
import { useLocation } from "react-router-dom";

import Loader from "components/Loader";
import useAuth from "hooks/useAuth";
import { GuardProps } from "types/auth";

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, isInitialized, refresh } = useAuth();
  const location = useLocation();

  useEffect(() => {
    refresh();
    // @ts-ignore
  }, [location]);

  return !isInitialized ? (
    <Loader />
  ) : isLoggedIn ? (
    children
  ) : (
    <Navigate to={"/login"} replace={true} />
  );
};

export default AuthGuard;

import { InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormikContext } from "formik";
import _ from "lodash";
import { useMemo, useState } from "react";
import NumberFormat from "react-number-format";

import { CreoneField } from "components/form/basic/creone_field";
import { FieldComponentProps } from "types/standardForm";

const NumberFormatTextField = styled(TextField)({
  "& input": {
    padding: "12px 0px 12px 12px",
    oveflow: "visible",
  },
});

export function ProbabilityOverrideField(props: FieldComponentProps) {
  const { setFieldValue, values, setFieldTouched } = useFormikContext();
  const [userHasInteracted, setUserHasInteracted] = useState<boolean>(false);

  const handleBlur = () => {
    setFieldTouched(props.fieldName, true);
  };

  const defaultProbability = useMemo(
    () => (_.get(values, "stage.probability") ?? 0) * 100,
    [values]
  );

  return (
    <CreoneField {...props}>
      <NumberFormat
        size={"medium"}
        fullWidth
        id={props.fieldName}
        thousandSeparator={false}
        fixedDecimalScale={false}
        decimalScale={2}
        placeholder="0"
        value={
          _.isNil(_.get(values, props.fieldName)) && !userHasInteracted
            ? defaultProbability
            : _.get(values, props.fieldName)
        }
        disabled={props.disabled}
        onValueChange={(val, sourceInfo) => {
          if (sourceInfo?.source === "event") {
            setUserHasInteracted(true);
            // Avoid setting field value for source of type Prop
            setFieldValue(
              props.fieldName,
              _.isUndefined(val.floatValue) ? null : val.floatValue
            );
          }
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        onBlur={handleBlur}
        customInput={NumberFormatTextField}
      />
    </CreoneField>
  );
}

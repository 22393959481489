import { UserOutlined } from "@ant-design/icons";
import { Stack, Typography } from "@mui/material";

import Avatar from "components/@extended/Avatar";

export function UserChip(props: { fullName: string | null }) {
  return (
    <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
      <Avatar alt={props.fullName ?? ""} size={"xs"}>
        <UserOutlined />
      </Avatar>
      <Typography variant={"caption"} sx={{ pt: 0.25 }}>
        {props.fullName}
      </Typography>
    </Stack>
  );
}

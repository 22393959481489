export enum RecordIdentifier {
  Activity = "activity",
  Company = "company",
  Contact = "contact",
  Deal = "deal",
  Prospect = "prospect",
  OutstandingCommissionPayment = "commission_payment",
  Email = "email",
  FormLayout = "form_layout",
  FormField = "form_field",
  Property = "property",
}

interface RecordMetadataEntry {
  dataUrl: string;
  displayName: string;
  displayNamePlural: string;
  exportUrl?: string;
  mainForm?: FormIdentifier;
}

export type RecordMetadataType = {
  [key in RecordIdentifier]: RecordMetadataEntry;
};

export type StandardFormProps<T> = {
  record: T | null;
  incomingChanges?: object;
  onCancel: () => void;
  successCallback: (record?: object) => void;
  open: boolean;
  showAddAnother?: boolean;
  isDialog?: boolean;
};

// Defining the structure for individual form map entries
interface FormMapEntry {
  component: (props: StandardFormProps<any>) => JSX.Element;
  loadUrl: string | null;
  service?: "deal" | "user";
  queryKey?: string[];
}

export enum FormIdentifier {
  ActivityForm = "ActivityForm",
  CommissionGoalForm = "CommissionGoalForm",
  CompanyForm = "CompanyForm",
  ContactForm = "ContactForm",
  ContactNameOnlyForm = "ContactNameOnlyForm",
  ContactNotesForm = "ContactNotesForm",
  ContactQuickCreateForm = "ContactQuickCreateForm",
  ConvertToProspectForm = "ConvertToProspectForm",
  DealLostForm = "DealLostForm",
  DealClosedForm = "DealClosedForm",
  DealNotesForm = "DealNotesForm",
  DealPreviewForm = "DealPreviewForm",
  DealViewProForm = "DealViewProForm",
  OptionForm = "OptionForm",
  QuickAddWizardForm = "QuickAddWizardForm",
  RoleForm = "RoleForm",
  TeamForm = "TeamForm",
  UserForm = "UserForm",
}

// Defining the type for the entire formIdentifierMap
export type FormIdentifierMapType = {
  [key in FormIdentifier]: FormMapEntry;
};
export type RecordDetails = {
  id: string | number | null;
  formIdentifier: FormIdentifier;
  incomingChanges: object;
  isQuickCreate: boolean;
  callbackFunction: (record: object) => void;
};

export interface RecordState {
  editingStack: Array<RecordDetails>;
}

export enum FormCloseReason {
  Save = "save",
  Cancel = "cancel",
  Error = "error",
}

// OpenInGoogleButton.tsx
import { OpenInNewOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React from "react";

import { CreoneField } from "components/form/basic/creone_field";
import { FieldComponentProps } from "types/standardForm";

const OpenContactInGoogleButton = (props: FieldComponentProps) => {
  const { values } = useFormikContext();

  const handleOpenInGoogle = () => {
    const firstName = get(values, "first_name", "");
    const lastName = get(values, "last_name", "");
    const companyName = get(values, "company.label", "");

    const searchQuery = `${firstName} ${lastName} ${companyName}`;
    const googleSearchUrl = `https://www.google.com/search?q=${encodeURIComponent(searchQuery)}`;

    // Open the Google search in a new tab
    window.open(googleSearchUrl, "_blank");
  };

  return (
    <CreoneField {...props}>
      <Button
        variant="outlined"
        color="primary"
        size={"small"}
        onClick={handleOpenInGoogle}
        endIcon={<OpenInNewOutlined style={{ fontSize: 18 }} />}
        sx={{ height: "34.25px" }}
      >
        Open in Google
      </Button>
    </CreoneField>
  );
};

export default OpenContactInGoogleButton;

import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";

import useAuth from "hooks/useAuth";
import { cachedLicense } from "hooks/useCheckLicense";
import { useLicenseTypeDev } from "hooks/useLicenseTypeDev";
import { Product } from "types/license";
import { getDefaultRoute } from "utils/getDefaultRoute";

// ==============================|| TOGGLE BUTTON - TEXT ||============================== //

export function LicenseToggleButton() {
  const [enabledState, setEnabledState] = useLicenseTypeDev([
    Product.deal_view_pro,
  ]);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: Product | null
  ) => {
    setEnabledState(newAlignment);
    cachedLicense.cache.clear?.();
    setTimeout(function () {
      navigate(getDefaultRoute(user), { replace: true });
    }, 100);
  };

  return (
    <ToggleButtonGroup
      value={enabledState}
      onChange={handleAlignment}
      aria-label="text alignment"
    >
      <ToggleButton value={Product.deal_view} aria-label="second">
        Deal View
      </ToggleButton>
      <ToggleButton value={Product.deal_view_pro} aria-label="third">
        Deal View Pro
      </ToggleButton>
      <ToggleButton value={Product.prospects} aria-label="fourth">
        Prospects
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

import { ReactNode } from "react";
import { Box, Grid } from "@mui/material";

import AuthFooter from "pages/auth/sections/AuthFooter";

import authLogoFile from "/CREOneSource_R_Logo_Full-Colour2.png";

interface Props {
  children: ReactNode;
}

// ==============================|| AUTHENTICATION - WRAPPER ||============================== //

const AuthWrapper = ({ children }: Props) => (
  <Box sx={{ minHeight: "100vh" }}>
    <Grid
      container
      direction="column"
      justifyContent="flex-end"
      sx={{
        minHeight: "100vh",
      }}
    >
      <Grid item xs={12} sx={{ ml: 3, mt: 3 }}>
        <img src={authLogoFile} alt="CRE OneSource" width="211" />
      </Grid>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            minHeight: {
              xs: "calc(100vh - 210px)",
              sm: "calc(100vh - 134px)",
              md: "calc(100vh - 112px)",
            },
          }}
        >
          <Grid item>{children}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
        <AuthFooter />
      </Grid>
    </Grid>
  </Box>
);

export default AuthWrapper;

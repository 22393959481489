import { useRoutes } from "react-router-dom";

import IntegrationRoutes from "routes/integrationRoutes";

import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  let routes = [LoginRoutes, MainRoutes, IntegrationRoutes];
  return useRoutes(routes);
}

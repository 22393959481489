import { EditNoteOutlined } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import _ from "lodash";
import React from "react";
import { CellProps } from "react-table";

import IconButton from "components/@extended/IconButton";
import { ContactMetadata } from "constants/objectMetadata/contactMetadata";
import { LightTooltip } from "pages/deal/components/InfoTooltip";
import { ProspectStar } from "pages/prospects/components/ProspectStar";
import { startEditingRecord } from "store/reducers/record";
import { Contact } from "types/api/deal/contact";
import { ContactTable } from "types/api/deal/contactTables";
import { FormIdentifier } from "types/record";

export const defaultContactTableColumnOrder = [
  "full_name",
  "company_name",
  "title",
  "contact_type_name",
  "email_address",
  "telephone1_name",
  "owner",
  "notes_plaintext",
  "notes",
];

export const contactTableColumns = (
  theme: any,
  usersDisplay: { [p: string]: any; [p: number]: any }
) => {
  // NOTE: Since sorting is managed on the backend, be sure to update the sort field mapping in the ContactFilter object
  return [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ value, row }: CellProps<Contact>) => (
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Box>{value}</Box>
          <ProspectStar isProspect={_.get(row, "original.is_prospect")} />
        </Stack>
      ),
    },
    {
      Header: "Company Name",
      accessor: "company_name",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Contact Type",
      accessor: "contact_type_name",
    },
    {
      Header: "Email",
      accessor: "email_address",
    },
    {
      Header: "Phone",
      accessor: "telephone1_name",
      disableSortBy: true,
    },
    {
      Header: "Contact Owner",
      accessor: ({ owner }: ContactTable) =>
        owner ? _.get(usersDisplay, owner) : null,
      disableSortBy: true,
    },
  ];
};

export const contactTableColumnsV8 = (
  theme: any,
  usersDisplay: { [p: string]: any; [p: number]: any }
): ColumnDef<ContactTable>[] => {
  // NOTE: Since sorting is managed on the backend, be sure to update the sort field mapping in the ContactFilter object
  return [
    {
      header: "Name",
      accessorKey: "full_name",
      id: "full_name",
      cell: ({ getValue, row }) => (
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Box>{getValue() as string}</Box>
          <ProspectStar isProspect={_.get(row, "original.is_prospect")} />
        </Stack>
      ),
      meta: {
        className: "cell-min-width-lg",
      },
    },
    {
      header: "Company Name",
      accessorKey: "company_name",
      id: "company_name",
      meta: {
        className: "cell-min-width-lg",
      },
    },
    {
      header: ContactMetadata.title.displayName,
      accessorKey: "title",
      id: "title",
      meta: {
        className: "cell-min-width-md",
      },
    },
    {
      header: ContactMetadata.contact_type.displayName,
      accessorKey: "contact_type_name",
      id: "contact_type_name",
    },
    {
      header: ContactMetadata.email_address.displayName,
      accessorKey: "email_address",
      id: "email_address",
    },
    {
      header: ContactMetadata.telephone1.displayName,
      accessorKey: "telephone1_name",
      id: "telephone1_name",
      enableSorting: false,
      meta: {
        className: "cell-min-width-md",
      },
    },
    {
      header: ContactMetadata.telephone2.displayName,
      accessorKey: "telephone2",
      id: "telephone2",
      enableSorting: false,
      meta: {
        className: "cell-min-width-md",
      },
    },
    {
      header: ContactMetadata.groups.displayName,
      accessorKey: "group_name",
      id: "group_name",
      meta: {
        className: "cell-min-width-md",
      },
    },
    {
      header: "Contact Owner",
      id: "owner",
      accessorFn: ({ owner }: ContactTable) =>
        owner ? _.get(usersDisplay, owner) : null,
      enableSorting: false,
      meta: {
        className: "cell-min-width-md",
      },
    },
    {
      header: "Next Activity Date",
      accessorKey: "next_activity_date",
      id: "next_activity_date",
      meta: {
        className: "cell-right cell-min-width-md",
      },
    },
    {
      header: "Next Activity Type",
      accessorKey: "next_activity_type_name",
      id: "next_activity_type_name",
      meta: {
        className: "cell-min-width-md",
      },
    },
    {
      header: "Next Activity Subject",
      accessorFn: (row) =>
        _.truncate(row.next_activity_subject, {
          length: 70,
          omission: "...",
        }),
      id: "next_activity_subject",
      meta: {
        className: "cell-min-width-xl",
      },
    },
    {
      header: "Last Activity Date",
      accessorKey: "last_activity_date",
      id: "last_activity_date",
      meta: {
        className: "cell-right cell-min-width-md",
      },
    },
    {
      header: "Last Activity Type",
      accessorKey: "last_activity_type_name",
      id: "last_activity_type_name",
      meta: {
        className: "cell-min-width-md",
      },
    },
    {
      header: "Last Activity Subject",
      accessorFn: (row) =>
        _.truncate(row.last_activity_subject, {
          length: 70,
          omission: "...",
        }),
      id: "last_activity_subject",
      meta: {
        className: "cell-min-width-xl",
      },
    },
    {
      header: "Notes",
      accessorFn: (row) =>
        _.truncate(row.notes_plaintext, {
          length: 70,
          omission: "...",
        }),
      id: "notes_plaintext",
      enableSorting: false,
      meta: {
        className: "cell-min-width-xl",
      },
    },
    {
      header: "",
      accessorKey: "notes",
      id: "notes",
      enableSorting: false,
      cell: ({ row }) => (
        <Stack direction={"row"} justifyContent={"center"}>
          <LightTooltip title={`Edit Notes`}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                startEditingRecord(
                  row.original.id!,
                  FormIdentifier.ContactNotesForm
                );
              }}
            >
              <EditNoteOutlined></EditNoteOutlined>
            </IconButton>
          </LightTooltip>
        </Stack>
      ),
      meta: {
        onClick: () => {},
      },
    },
  ];
};

import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import * as Sentry from "@sentry/react";
import { useFormikContext } from "formik";
import _ from "lodash";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useReactToPrint } from "react-to-print";

import ContactShareButton from "components/form/forms/contact/ContactShareButton";
import PrintContactForm from "components/form/forms/PrintContactForm";
import { ActivityTimelineSection } from "components/form/forms/sections/ActivityTimeline/ActivityTimelineSection";
import { TimelineObjectPrintView } from "components/form/forms/sections/ActivityTimeline/TimelineObjectPrintView";
import CompanyAddressQuickView from "components/form/forms/sections/CompanyAddressQuickView";
import { ContactAdditionalDataSection } from "components/form/forms/sections/ContactAdditionalDataSection";
import { ProspectDetailsSection } from "components/form/forms/sections/ProspectDetailsSection";
import DealPreviewPane from "components/form/section/DealPreviewPane";
import ConfirmationDialog from "components/form/standard/ConfirmationDialog";
import DynamicVisibilitySection from "components/form/standard/DynamicVisibilitySection";
import StandardField from "components/form/standard/StandardField";
import { StandardForm } from "components/form/standard/StandardForm";
import { EMPTY_FIELD } from "components/form/standard/utils/formatting";
import MainCard from "components/MainCard";
import { ActivityFollowupMetadataSubset } from "constants/objectMetadata/activityFollowupMetadata";
import { ContactMetadata } from "constants/objectMetadata/contactMetadata";
import { CounterProvider, useCounter } from "contexts/CounterContext";
import useAuth from "hooks/useAuth";
import { CloseButton } from "pages/deal/components/CloseButton";
import { getBaseQueryOptionsDealServiceV2 } from "pages/deal/utils/api";
import { PrintProspectStar } from "pages/prospects/components/ProspectStar";
import { startEditingRecord } from "store/reducers/record";
import {
  Contact,
  ContactCreate,
  ContactRead,
  ContactWithFollowup,
} from "types/api/deal/contact";
import { ContactTimeline } from "types/api/deal/contact_timeline";
import { ActivityTypeEnum } from "types/api/deal/enum";
import { CONTACT_DELETE_PERMISSIONS } from "types/permission";
import { FormIdentifier, StandardFormProps } from "types/record";
import { FormSubmitAction } from "types/standardForm";
import {
  createContactAsync,
  deleteContactAsync,
  updateContactAsync,
} from "utils/contact";

function ContactFormTitle() {
  const { values } = useFormikContext();

  const isProspect = useMemo(() => _.get(values, "is_prospect"), [values]);
  const isNew = useMemo(() => !_.get(values, "id"), [values]);

  return (
    <>
      {isNew ? "Add" : "Edit"} {isProspect ? "Prospect" : "Contact"}
    </>
  );
}

const FormMetadata = { ...ActivityFollowupMetadataSubset, ...ContactMetadata };

// Form cards
const ContactDetails = ({ printDisplay }: { printDisplay?: boolean }) => {
  return (
    <>
      <MainCard title={"Contact Details"}>
        <Grid container columnSpacing={2} rowSpacing={1}>
          {!printDisplay && (
            <>
              <Grid item xs={6} sm={6}>
                <StandardField field={ContactMetadata.first_name} />
              </Grid>
              <Grid item xs={6} sm={6}>
                <StandardField field={ContactMetadata.last_name} />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <StandardField field={ContactMetadata.contact_type} />
          </Grid>
          <Grid item xs={printDisplay ? 6 : 8} sm={8}>
            <StandardField field={ContactMetadata.telephone1} />
          </Grid>
          <Grid item xs={printDisplay ? 6 : 4} sm={4}>
            <StandardField field={ContactMetadata.telephone1_ext} />
          </Grid>
          <Grid item xs={12}>
            <StandardField field={ContactMetadata.telephone2} />
          </Grid>
          <Grid item xs={12}>
            <StandardField field={ContactMetadata.email_address} />
          </Grid>
          {!printDisplay && (
            <Grid item xs={12}>
              <StandardField field={ContactMetadata.title} />
            </Grid>
          )}
          <Grid item xs={12}>
            <StandardField field={ContactMetadata.groups} />
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};

const ContactNotes = () => {
  return (
    <>
      <MainCard title={"Contact Notes"}>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xs={12}>
            <StandardField
              field={ContactMetadata.notes_plaintext}
              showLabel={false}
            />
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};

const ProspectDetails = ({ printDisplay }: { printDisplay?: boolean }) => {
  return (
    <>
      <MainCard title={"Prospect Details"}>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <ProspectDetailsSection printDisplay={printDisplay} />
        </Grid>
      </MainCard>
    </>
  );
};

const CompanyDetails = () => {
  return (
    <MainCard title={"Company"}>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={12}>
          <StandardField field={ContactMetadata.company} showLabel={false} />
        </Grid>
        <CompanyAddressQuickView />
      </Grid>
    </MainCard>
  );
};

const ContactFormBase = ({
  record,
  incomingChanges,
  onCancel,
  successCallback,
  open,
}: StandardFormProps<ContactRead & ContactWithFollowup>) => {
  const { checkPermissions } = useAuth();
  const canDelete = checkPermissions(CONTACT_DELETE_PERMISSIONS);

  const { count: unsavedActivitiesCount } = useCounter();
  const [discardChangesDialogOpen, setDiscardChangesDialogOpen] =
    useState(false);
  const [onConfirm, setOnConfirm] = useState<() => Promise<void>>(
    () => async () => {}
  );

  // react-to-print configuration
  const printRef = useRef(null);
  const [printSummary, setPrintSummary] = useState<boolean | null>(null);

  // Opens print form
  const handlePrint = (summary: boolean) => {
    setPrintSummary(summary);
  };
  const executePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => setPrintSummary(null),
  });
  useEffect(() => {
    if (!_.isNil(printSummary)) {
      executePrint();
    }
  }, [printSummary]);

  const createFnAsync = async (
    contact: ContactWithFollowup,
    submitAction: FormSubmitAction = FormSubmitAction.save
  ) => {
    // Move the individual followup fields into a sub-object
    const contactForPost: ContactCreate = {
      ...contact,
      followup: {
        owner: _.get(contact, "owner"),
        activity_type_v2: _.get(
          contact,
          "followup_activity_type_v2"
        ) as ActivityTypeEnum,
        date: _.get(contact, "followup_date") as Date,
        subject: _.get(contact, "followup_subject") as string,
        is_complete: false,
      },
    };

    try {
      const record = await createContactAsync(contactForPost);
      if (typeof successCallback === "function" && _.isArray(record))
        successCallback(record);

      if (submitAction === FormSubmitAction.saveAndAddAnother) {
        await new Promise((resolve) => setTimeout(resolve, 200));
        startEditingRecord(
          null,
          FormIdentifier.ContactQuickCreateForm,
          {},
          true
        );
      }
    } catch (error) {
      Sentry.captureException("Unable to create Contact");
    }
  };

  const updateFnAsync = async (
    id: number | string,
    contact: Partial<Contact>,
    bypass: boolean = false
  ) => {
    try {
      if (bypass || unsavedActivitiesCount === 0) {
        const record = await updateContactAsync(id, contact);
        if (typeof successCallback === "function" && _.isArray(record))
          successCallback(record);
      } else {
        // Setup the confirmation action
        setOnConfirm(() => () => updateFnAsync(id, contact, true));
        // Open the confirmation dialog
        setDiscardChangesDialogOpen(true);
      }
    } catch (error) {
      Sentry.captureException("Unable to update Contact.");
    }
  };

  const wrappedUpdateFnAsync = async (
    id: number | string,
    contact: Partial<Contact>
  ) => {
    await updateFnAsync(id, contact);
  };

  const deleteFnAsync = async (id: number | string) => {
    try {
      await deleteContactAsync(id);
      if (typeof successCallback === "function") successCallback();
    } catch (error) {
      Sentry.captureException("Unable to delete Contact.");
    }
  };

  const activityTimelineQueryOptions = useMemo(
    () =>
      getBaseQueryOptionsDealServiceV2<ContactTimeline>(
        `/contact/timeline/${record?.id}`
      ),
    [record?.id]
  );

  const {
    data: { upcoming, completed } = { upcoming: [], completed: [] },
    refetch: activityTimelineRefetch,
  } = useQuery<ContactTimeline>({
    ...activityTimelineQueryOptions,
    enabled: !!record?.id,
  });

  return (
    <Fragment>
      <StandardForm<ContactWithFollowup>
        metadata={FormMetadata}
        record={record}
        updateFn={wrappedUpdateFnAsync}
        createFn={createFnAsync}
        deleteFn={canDelete ? deleteFnAsync : undefined}
        displayName={"Contact"}
        onCancel={onCancel}
        incomingChanges={incomingChanges}
        fullWidth={true}
        maxWidth={"xl"}
        open={open}
        handlePrint={handlePrint}
      >
        {/* Hidden print contact form -- only displays on print dialog */}
        {({ StandardFormActionsInstance }) => (
          <>
            <Box display={"none"}>
              <Box ref={printRef}>
                <PrintContactForm
                  header={
                    <>
                      <Typography variant="h3">
                        {_.get(record, "full_name")}&nbsp;
                        <PrintProspectStar
                          size={26}
                          isProspect={_.get(record, "is_prospect")}
                        />
                      </Typography>
                      <Typography variant="h6" color={"textSecondary"}>
                        {_.get(record, "title") || ""}
                      </Typography>
                    </>
                  }
                >
                  <Box flex={1}>
                    <Stack direction={"column"} spacing={2}>
                      <Grid item xs={6}>
                        <ContactDetails printDisplay={true} />
                      </Grid>
                      <Grid item xs={6}>
                        <CompanyDetails />
                      </Grid>
                      <Grid item xs={6}>
                        <ContactNotes />
                      </Grid>
                    </Stack>
                  </Box>
                  <Box flex={1}>
                    <Stack direction={"column"} spacing={2}>
                      {_.get(record, "is_prospect") && (
                        <Grid item xs={6}>
                          <ProspectDetails printDisplay={true} />
                        </Grid>
                      )}
                      <Grid item xs={6}>
                        <MainCard title={"Upcoming Activities"}>
                          {_.size(upcoming) > 0 ? (
                            <>
                              {_.map(upcoming, (x) => (
                                <TimelineObjectPrintView record={x} />
                              ))}
                            </>
                          ) : (
                            <Typography>{EMPTY_FIELD}</Typography>
                          )}
                        </MainCard>
                      </Grid>
                      <Grid item xs={6}>
                        <MainCard
                          title={
                            printSummary
                              ? "Most Recent Completed Activity"
                              : "Completed Activities"
                          }
                        >
                          {_.size(completed) > 0 ? (
                            <>
                              {_.map(
                                printSummary ? _.take(completed, 1) : completed,
                                (x) => (
                                  <TimelineObjectPrintView record={x} />
                                )
                              )}
                            </>
                          ) : (
                            <Typography>{EMPTY_FIELD}</Typography>
                          )}
                        </MainCard>
                      </Grid>
                    </Stack>
                  </Box>
                </PrintContactForm>
              </Box>
            </Box>
            <Grid container spacing={2} alignItems="flex-start" sx={{ pt: 1 }}>
              <Grid item sx={{ mt: -0.5 }}>
                <DialogTitle>
                  <ContactFormTitle />
                </DialogTitle>
              </Grid>
              <Grid item>
                <StandardField
                  field={ContactMetadata._open_in_google}
                  showLabel={false}
                />
              </Grid>
              <Grid item xs={true}></Grid>
              <Grid item>
                <StandardField
                  field={ContactMetadata.owner}
                  showLabel={false}
                />
              </Grid>
              <Grid item>
                <StandardField
                  field={ContactMetadata.is_prospect}
                  showLabel={false}
                />
              </Grid>
              <Grid item>
                <StandardField
                  field={ContactMetadata._convert_to_deal}
                  showLabel={false}
                />
              </Grid>
              <Grid item>
                <StandardField
                  field={ContactMetadata.status}
                  showLabel={false}
                />
              </Grid>
              <Grid item mt={0.5} mr={-1}>
                {!!record?.id && <ContactShareButton contactId={record?.id} />}
              </Grid>
              <Grid item mt={0.5} mr={2}>
                <CloseButton onClose={onCancel} />
              </Grid>
            </Grid>
            <DialogContent sx={{ bgcolor: "background.default" }} dividers>
              <Box sx={{ py: 3 }}>
                <Grid container alignItems="flex-start" spacing={3}>
                  <Grid item xs={12} md={3}>
                    <Stack spacing={3}>
                      <ContactDetails />
                      <ContactNotes />
                      {!!_.get(record, "additional_data.json_data") && (
                        <ContactAdditionalDataSection
                          additional_data={record?.additional_data}
                        />
                      )}
                    </Stack>
                  </Grid>
                  <Grid container alignItems="flex-start" item xs={12} md={6}>
                    <ActivityTimelineSection
                      contact_record={record}
                      completed={completed}
                      upcoming={upcoming}
                      activityTimelineRefetch={activityTimelineRefetch}
                    >
                      <DynamicVisibilitySection
                        visibilityFunction={(values) =>
                          _.get(values, "is_prospect")
                        }
                      >
                        <ProspectDetails />
                      </DynamicVisibilitySection>
                    </ActivityTimelineSection>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Stack spacing={3}>
                      <CompanyDetails />
                      {!!record?.id && (
                        <DealPreviewPane contactRecord={record} />
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>{StandardFormActionsInstance}</DialogActions>
          </>
        )}
      </StandardForm>
      <ConfirmationDialog
        open={discardChangesDialogOpen}
        onClose={() => setDiscardChangesDialogOpen(false)}
        onConfirm={async () => {
          await onConfirm(); // This executes the updateContact function
          setDiscardChangesDialogOpen(false); // Close the dialog after confirmation
        }}
        title="Confirm discard changes"
        description={`Your changes to Activities have not been saved. Discard Activity changes?`}
        confirmButtonText="OK"
        cancelButtonText="Cancel"
      />
    </Fragment>
  );
};

export const ContactForm = (
  props: StandardFormProps<ContactRead & ContactWithFollowup>
) => {
  return (
    <CounterProvider>
      <ContactFormBase {...props} />
    </CounterProvider>
  );
};

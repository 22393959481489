import { createSlice } from "@reduxjs/toolkit";

import {
  KanbanStateProps,
  KanbanColumn,
  KanbanComment,
  KanbanItem,
  KanbanUserStory,
} from "types/kanban";
import axios from "utils/axios";

import { dispatch } from "../index";

// ==============================|| SLICE - KANBAN ||============================== //

const initialState: KanbanStateProps = {
  error: null,
  columns: [],
  columnsOrder: [],
  comments: [],
  items: [],
  profiles: [],
  selectedItem: false,
  userStory: [],
  userStoryOrder: [],
};

const slice = createSlice({
  name: "kanban",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // ADD COLUMN
    addColumnSuccess(state, action) {
      state.columns = action.payload.columns;
      state.columnsOrder = action.payload.columnsOrder;
    },

    // EDIT COLUMN
    editColumnSuccess(state, action) {
      state.columns = action.payload.columns;
    },

    // UPDATE COLUMN ORDER
    updateColumnOrderSuccess(state, action) {
      state.columnsOrder = action.payload.columnsOrder;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      state.columns = action.payload.columns;
      state.columnsOrder = action.payload.columnsOrder;
    },

    // ADD ITEM
    addItemSuccess(state, action) {
      state.items = action.payload.items;
      state.columns = action.payload.columns;
      state.userStory = action.payload.userStory;
    },

    // EDIT ITEM
    editItemSuccess(state, action) {
      state.items = action.payload.items;
      state.columns = action.payload.columns;
      state.userStory = action.payload.userStory;
    },

    // UPDATE COLUMN ITEM ORDER
    updateColumnItemOrderSuccess(state, action) {
      state.columns = action.payload.columns;
    },

    // SELECT ITEM
    selectItemSuccess(state, action) {
      state.selectedItem = action.payload.selectedItem;
    },

    // ADD ITEM COMMENT
    addItemCommentSuccess(state, action) {
      state.items = action.payload.items;
      state.comments = action.payload.comments;
    },

    // DELETE ITEM
    deleteItemSuccess(state, action) {
      state.items = action.payload.items;
      state.columns = action.payload.columns;
      state.userStory = action.payload.userStory;
    },

    // ADD STORY
    addStorySuccess(state, action) {
      state.userStory = action.payload.userStory;
      state.userStoryOrder = action.payload.userStoryOrder;
    },

    // EDIT STORY
    editStorySuccess(state, action) {
      state.userStory = action.payload.userStory;
    },

    // UPDATE STORY ORDER
    updateStoryOrderSuccess(state, action) {
      state.userStoryOrder = action.payload.userStoryOrder;
    },

    // UPDATE STORY ITEM ORDER
    updateStoryItemOrderSuccess(state, action) {
      state.userStory = action.payload.userStory;
    },

    // ADD STORY COMMENT
    addStoryCommentSuccess(state, action) {
      state.userStory = action.payload.userStory;
      state.comments = action.payload.comments;
    },

    // DELETE STORY
    deleteStorySuccess(state, action) {
      state.userStory = action.payload.userStory;
      state.userStoryOrder = action.payload.userStoryOrder;
    },

    // GET COLUMNS
    getColumnsSuccess(state, action) {
      state.columns = action.payload;
    },

    // GET COLUMNS ORDER
    getColumnsOrderSuccess(state, action) {
      state.columnsOrder = action.payload;
    },

    // GET COMMENTS
    getCommentsSuccess(state, action) {
      state.comments = action.payload;
    },

    // GET PROFILES
    getProfilesSuccess(state, action) {
      state.profiles = action.payload;
    },

    // GET ITEMS
    getItemsSuccess(state, action) {
      state.items = action.payload;
    },

    // GET USER STORY
    getUserStorySuccess(state, action) {
      state.userStory = action.payload;
    },

    // GET USER STORY ORDER
    getUserStoryOrderSuccess(state, action) {
      state.userStoryOrder = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function getColumns() {
  return async () => {
    try {
      const response = {
        columns: [
          {
            id: "column-1",
            title: "New",
            itemIds: ["3542", "8903", "1381"],
          },
          {
            id: "column-2",
            title: "Active",
            itemIds: ["6739", "3950", "6924"],
          },
          {
            id: "column-3",
            title: "Resolved",
            itemIds: ["4398"],
          },
          {
            id: "column-4",
            title: "Closed",
            itemIds: ["9589", "3410", "8679"],
          },
        ],
      };
      dispatch(slice.actions.getColumnsSuccess(response.columns));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getColumnsOrder() {
  return async () => {
    try {
      const response = {
        columnsOrder: ["column-1", "column-2", "column-3", "column-4"],
      };
      dispatch(slice.actions.getColumnsOrderSuccess(response.columnsOrder));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getComments() {
  return async () => {
    try {
      const response = {
        comments: [
          {
            id: "comment-1",
            comment: "Comment 1",
            profileId: "profile-1",
          },
          {
            id: "comment-2",
            comment: "Comment 2",
            profileId: "profile-2",
          },
          {
            id: "comment-3",
            comment: "Comment 3",
            profileId: "profile-3",
          },
          {
            id: "comment-4",
            comment: "Comment 4",
            profileId: "profile-2",
          },
          {
            id: "comment-5",
            comment: "Comment 5",
            profileId: "profile-3",
          },
        ],
      };
      dispatch(slice.actions.getCommentsSuccess(response.comments));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProfiles() {
  return async () => {
    try {
      const response = {
        profiles: [
          {
            id: "profile-1",
            avatar: "avatar-3.png",
            name: "Barney Thea",
            time: "2 min ago",
          },
          {
            id: "profile-2",
            avatar: "avatar-1.png",
            name: "Maddison Wilber",
            time: "1 day ago",
          },
          {
            id: "profile-3",
            avatar: "avatar-2.png",
            name: "John Doe",
            time: "now",
          },
        ],
      };
      dispatch(slice.actions.getProfilesSuccess(response.profiles));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getItems() {
  return async () => {
    try {
      const response = {
        items: [
          {
            assign: "profile-1",
            attachments: [],
            commentIds: ["comment-1"],
            description: "Content of item 1",
            dueDate: "Fri Feb 03 2023 15:20:14 GMT+0530 (India Standard Time)",
            id: "3542",
            image: "profile-back-1.png",
            priority: "low",
            title: "Online fees payment & instant announcements",
          },
          {
            assign: "profile-2",
            attachments: [],
            commentIds: ["comment-2", "comment-5"],
            description: "Content of item 2",
            dueDate: "Sat Jan 28 2023 15:23:25 GMT+0530 (India Standard Time)",
            id: "4398",
            image: false,
            priority: "high",
            title: "Creation and Maintenance of Inventory Objects",
          },
          {
            assign: "profile-3",
            attachments: [],
            description: "Content of item 3",
            dueDate: "Tue Feb 07 2023 15:24:38 GMT+0530 (India Standard Time)",
            id: "6739",
            image: false,
            priority: "low",
            title: "Update React & TypeScript version",
          },
          {
            assign: "profile-2",
            attachments: [],
            commentIds: ["comment-4"],
            description: "Content of item 4",
            dueDate: "Thu Feb 09 2023 15:25:46 GMT+0530 (India Standard Time)",
            id: "3950",
            image: "profile-back-2.png",
            priority: "low",
            title: "Set allowing rules for trusted applications.",
          },
          {
            assign: "profile-2",
            attachments: [],
            commentIds: ["comment-1", "comment-2", "comment-5"],
            description: "Content of item 5",
            dueDate: "Mon Feb 06 2023 15:26:53 GMT+0530 (India Standard Time)",
            id: "6924",
            image: "profile-back-3.png",
            priority: "medium",
            title: "Managing Applications Launch Control",
          },
          {
            assign: "profile-3",
            attachments: [],
            commentIds: ["comment-3", "comment-4"],
            description: "Content of item 6",
            dueDate: "Wed Feb 15 2023 10:30:37 GMT+0530 (India Standard Time)",
            id: "9589",
            image: false,
            priority: "medium",
            title: "Run codemods",
          },
          {
            assign: "profile-1",
            attachments: [],
            description: "Content of item 7",
            dueDate: "Mon Feb 20 2023 15:29:58 GMT+0530 (India Standard Time)",
            id: "3410",
            image: "profile-back-4.png",
            priority: "low",
            title: "Purchase Requisitions, Adjustments, and Transfers.",
          },
          {
            assign: "profile-1",
            attachments: [],
            description: "Content of item 8",
            dueDate: "Sat Mar 04 2023 15:31:05 GMT+0530 (India Standard Time)",
            id: "8903",
            image: false,
            priority: "low",
            title: "Attendance checking & homework details",
          },
          {
            assign: "profile-3",
            attachments: [],
            commentIds: ["comment-3"],
            description: "Content of item 9",
            dueDate: "Thu Feb 23 2023 15:32:13 GMT+0530 (India Standard Time)",
            id: "1381",
            image: false,
            priority: "high",
            title: "Admission, Staff & Schedule management",
          },
          {
            assign: "profile-2",
            attachments: [],
            commentIds: ["comment-5"],
            description: "Content of item 10",
            dueDate: "Mon Feb 27 2023 15:33:04 GMT+0530 (India Standard Time)",
            id: "8679",
            image: false,
            priority: "low",
            title: "Handling breaking changes",
          },
        ],
      };
      dispatch(slice.actions.getItemsSuccess(response.items));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserStory() {
  return async () => {
    try {
      const response = {
        userStory: [
          {
            acceptance: "",
            assign: "profile-2",
            columnId: "column-4",
            commentIds: ["comment-5"],
            description:
              "Wo gegew jubva wiej pisniidi ic jarge mide ho ufcakub culuw ambupan te fezop rikic ekbet sevzog.",
            dueDate: "Mon Feb 27 2023 15:18:32 GMT+0530 (India Standard Time)",
            id: "2647",
            priority: "low",
            title: "School Management Backend",
            itemIds: ["3542", "8903", "1381"],
          },
          {
            acceptance:
              "Gid gimija ufuebjop vuji ze ir wi jih aruvihdok keubagob aguhogug uge tabu cure.",
            assign: "profile-3",
            columnId: "column-1",
            commentIds: ["comment-3"],
            description:
              "Onekane urwa joduzzij la cipebo zafuw atawa labfi hilza inepehhow inujufise hum.",
            dueDate: "Thu Feb 23 2023 15:13:31 GMT+0530 (India Standard Time)",
            id: "7833",
            priority: "high",
            title: "Inventory Implementation & Design",
            itemIds: ["4398", "3410"],
          },
          {
            acceptance:
              "Kiz ovmen fup jazaj rozibco fecki rasizsed pake redufkav joczatufo.",
            assign: "profile-3",
            columnId: "column-4",
            commentIds: ["comment-3", "comment-4"],
            description:
              "Lum vofdip keci fid fos di fikga kiinte izugfi gihude tewsadez ha niprizbef catin adahup.",
            dueDate: "Wed Feb 15 2023 10:30:11 GMT+0530 (India Standard Time)",
            id: "6940",
            priority: "medium",
            title: "Theme migration from v4 to v5",
            itemIds: ["6739", "9589", "8679"],
          },
          {
            acceptance: "Itorinceg cokmu ku pahkaavo argut.",
            assign: "profile-1",
            columnId: "column-3",
            commentIds: ["comment-4"],
            description:
              "Nar noljaw hi fusurri haodabi ewaw uc moetucu hilzirzo ca nidzerog ziw.",
            dueDate: "Tue Feb 07 2023 15:17:40 GMT+0530 (India Standard Time)",
            id: "4656",
            priority: "low",
            title: "Lunch Beauty Application",
            itemIds: ["3950", "6924"],
          },
        ],
      };
      dispatch(slice.actions.getUserStorySuccess(response.userStory));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserStoryOrder() {
  return async () => {
    try {
      const response = { userStoryOrder: ["2647", "7833", "6940", "4656"] };
      dispatch(slice.actions.getUserStoryOrderSuccess(response.userStoryOrder));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addColumn(
  column: KanbanColumn,
  columns: KanbanColumn[],
  columnsOrder: string[]
) {
  return async () => {
    try {
      const response = await axios.post("/api/kanban/add-column", {
        column,
        columns,
        columnsOrder,
      });
      dispatch(slice.actions.addColumnSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editColumn(column: KanbanColumn, columns: KanbanColumn[]) {
  return async () => {
    try {
      const response = await axios.post("/api/kanban/edit-column", {
        column,
        columns,
      });
      dispatch(slice.actions.editColumnSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateColumnOrder(columnsOrder: string[]) {
  return async () => {
    try {
      const response = await axios.post("/api/kanban/update-column-order", {
        columnsOrder,
      });
      dispatch(slice.actions.updateColumnOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteColumn(
  columnId: string,
  columnsOrder: string[],
  columns: KanbanColumn[]
) {
  return async () => {
    try {
      const response = await axios.post("/api/kanban/delete-column", {
        columnId,
        columnsOrder,
        columns,
      });
      dispatch(slice.actions.deleteColumnSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addItem(
  columnId: number | string,
  columns: KanbanColumn[],
  item: KanbanItem,
  items: KanbanItem[],
  storyId: string,
  userStory: KanbanUserStory[]
) {
  return async () => {
    try {
      const response = await axios.post("/api/kanban/add-item", {
        columnId,
        columns,
        item,
        items,
        storyId,
        userStory,
      });
      dispatch(slice.actions.addItemSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editItem(
  columnId: string,
  columns: KanbanColumn[],
  item: KanbanItem,
  items: KanbanItem[],
  storyId: string,
  userStory: KanbanUserStory[]
) {
  return async () => {
    try {
      const response = await axios.post("/api/kanban/edit-item", {
        items,
        item,
        userStory,
        storyId,
        columns,
        columnId,
      });
      dispatch(slice.actions.editItemSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateColumnItemOrder(columns: KanbanColumn[]) {
  return async () => {
    try {
      const response = await axios.post("/api/kanban/update-item-order", {
        columns,
      });
      dispatch(slice.actions.updateColumnItemOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function selectItem(selectedItem: string | false) {
  return async () => {
    try {
      const response = await axios.post("/api/kanban/select-item", {
        selectedItem,
      });
      dispatch(slice.actions.selectItemSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addItemComment(
  itemId: string | false,
  comment: KanbanComment,
  items: KanbanItem[],
  comments: KanbanComment[]
) {
  return async () => {
    try {
      const response = await axios.post("/api/kanban/add-item-comment", {
        items,
        itemId,
        comment,
        comments,
      });
      dispatch(slice.actions.addItemCommentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteItem(
  itemId: string | false,
  items: KanbanItem[],
  columns: KanbanColumn[],
  userStory: KanbanUserStory[]
) {
  return async () => {
    try {
      const response = await axios.post("/api/kanban/delete-item", {
        columns,
        itemId,
        userStory,
        items,
      });
      dispatch(slice.actions.deleteItemSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addStory(
  story: any,
  userStory: KanbanUserStory[],
  userStoryOrder: string[]
) {
  return async () => {
    try {
      const response = await axios.post("/api/kanban/add-story", {
        userStory,
        story,
        userStoryOrder,
      });
      dispatch(slice.actions.addStorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editStory(
  story: KanbanUserStory,
  userStory: KanbanUserStory[]
) {
  return async () => {
    try {
      const response = await axios.post("/api/kanban/edit-story", {
        userStory,
        story,
      });
      dispatch(slice.actions.editStorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateStoryOrder(userStoryOrder: string[]) {
  return async () => {
    try {
      const response = await axios.post("/api/kanban/update-story-order", {
        userStoryOrder,
      });
      dispatch(slice.actions.updateStoryOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateStoryItemOrder(userStory: KanbanUserStory[]) {
  return async () => {
    try {
      const response = await axios.post("/api/kanban/update-storyitem-order", {
        userStory,
      });
      dispatch(slice.actions.updateStoryItemOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addStoryComment(
  storyId: string,
  comment: KanbanComment,
  comments: KanbanComment[],
  userStory: KanbanUserStory[]
) {
  return async () => {
    try {
      const response = await axios.post("/api/kanban/add-story-comment", {
        userStory,
        storyId,
        comment,
        comments,
      });
      dispatch(slice.actions.addStoryCommentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteStory(
  storyId: string,
  userStory: KanbanUserStory[],
  userStoryOrder: string[]
) {
  return async () => {
    try {
      const response = await axios.post("/api/kanban/delete-story", {
        userStory,
        storyId,
        userStoryOrder,
      });
      dispatch(slice.actions.deleteStorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import { lazy, useEffect, useState } from "react";

import Loadable from "components/Loadable";
import useAuth from "hooks/useAuth";
import { GuardProps } from "types/auth";
import { subscriptionExpired } from "utils/checkSubscriptionStatus";

const ExpiredPage = Loadable(
  lazy(() => import("pages/extra-pages/expiredSubScriptionPage"))
);

// ==============================|| GUEST GUARD ||============================== //

const SubscriptionGuard = ({ children }: GuardProps) => {
  const { user } = useAuth();
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    setExpired(subscriptionExpired(user));
  }, [user]);

  if (expired) {
    return <ExpiredPage />;
  } else {
    return children;
  }
};

export default SubscriptionGuard;

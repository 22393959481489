import { DatabaseOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

import { Product } from "types/license";
import { NavItemType } from "types/menu";

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const lend_db: NavItemType = {
  id: "group-lend-db",
  title: <FormattedMessage id="lend-db" />,
  type: "group",
  products: [Product.lend_db],
  children: [
    {
      id: "os-lend",
      title: <FormattedMessage id="os-lend" />,
      type: "collapse",
      icon: DatabaseOutlined,
      children: [
        {
          id: "lend_db",
          title: <FormattedMessage id="lend-db" />,
          type: "item",
          url: "/lenders/overview",
        },
        {
          id: "lender-deals",
          title: <FormattedMessage id="lender-deals" />,
          type: "item",
          url: "/lenders/deals",
        },
        {
          id: "lender-reports",
          title: <FormattedMessage id="lender_reports" />,
          type: "item",
          url: "/lenders/reports",
        },
      ],
    },
  ],
};

export default lend_db;

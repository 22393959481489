import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import * as Sentry from "@sentry/react";
import parse from "autosuggest-highlight/parse";
import { useFormikContext } from "formik";
import _ from "lodash";
import * as React from "react";

import { CreoneField } from "components/form/basic/creone_field";
import config from "config";
import { GooglePlaceResult } from "types/address";
import { FieldComponentProps } from "types/standardForm";

const GOOGLE_MAPS_API_KEY = config.google_maps.api_key || "";

const extractValue = (obj: any, data: any) => {
  let key = "long_name";
  if (typeof obj === "string") {
    return data[obj] && data[obj][key];
  } else {
    return data[obj.key][obj.attribute || key];
  }
};

export const parseAddressComponents = (
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[]
) => {
  let mapping: Record<string, object> = {};
  address_components.forEach(({ long_name, short_name, types }) => {
    types.forEach((type) => {
      mapping[type] = {
        long_name,
        short_name,
      };
    });
  });

  let output: Record<string, any> = {};
  let address = {
    line_1: ["street_number", "route"],
    line_2: null,
    city: "locality",
    state: {
      key: "administrative_area_level_1",
      attribute: "short_name",
    },
    postal_code: "postal_code",
    country: "country",
  };
  for (let key in address) {
    if (address.hasOwnProperty(key)) {
      const value = _.get(address, key);
      if (value) {
        if (Array.isArray(value)) {
          output[key] = value
            .filter((v) => mapping[v])
            .map((v) => extractValue(v, mapping))
            .join(" ");
        } else {
          output[key] = extractValue(value, mapping);
        }
      } else {
        output[key] = null;
      }
    }
  }
  return output;
};

function getPlaceDetails(placeId: string, callback: any) {
  placesService.current?.getDetails(
    { placeId },
    (response: GooglePlaceResult, status: string) => {
      if (response.address_components && response.geometry) {
        let address = parseAddressComponents(response.address_components);
        address.latitude = response.geometry["location"].lat();
        address.longitude = response.geometry["location"].lng();
        address.place_id = response.place_id;
        callback(address);
      } else {
        Sentry.captureException({ status, placeId, response });
      }
    }
  );
}

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService: any = { current: null };
const placesService: any = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

export default function GoogleMapsAutocomplete(
  field_props: FieldComponentProps
) {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  const { setFieldValue } = useFormikContext();

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        400
      ),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (!placesService.current && (window as any).google) {
      placesService.current = new (
        window as any
      ).google.maps.places.PlacesService(document.createElement("input"));
    }
    if (!placesService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) newOptions = [value];
        if (results) newOptions = [...newOptions, ...results];

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <CreoneField {...field_props}>
      <Autocomplete
        id="address-search-autocomplete"
        sx={{
          [`& .${autocompleteClasses.popupIndicator}`]: {
            transform: "none",
          },
        }}
        popupIcon={<SearchOutlinedIcon />}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        size={"medium"}
        noOptionsText="No locations"
        onChange={(event: any, newValue: PlaceType | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          if (typeof field_props.additionalChangeHandler === "function") {
            // @ts-ignore
            getPlaceDetails(_.get(newValue, "place_id"), (address: any) =>
              // @ts-ignore
              field_props.additionalChangeHandler(address, setFieldValue)
            );
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Start typing to find an address..."
            fullWidth
          />
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [
              match.offset,
              match.offset + match.length,
            ])
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <LocationOnIcon sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  {parts.map(
                    (
                      part: {
                        highlight: any;
                        text:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined;
                      },
                      index: React.Key | null | undefined
                    ) => (
                      <Box
                        key={index}
                        component="span"
                        sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                      >
                        {part.text}
                      </Box>
                    )
                  )}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </CreoneField>
  );
}

import {
  DeleteFilled,
  InfoCircleFilled,
  PrinterFilled,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Grid,
  IconButton,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
} from "@mui/material";
import { useFormikContext } from "formik";
import _ from "lodash";
import { Fragment, useEffect, useState } from "react";

import DeleteRecordDialog from "components/form/standard/DeleteRecordDialog";
import { handleScrollToTop } from "components/ScrollTop";
import { LightTooltip } from "pages/deal/components/InfoTooltip";
import { FormSubmitAction } from "types/standardForm";

function SaveErrorMessage() {
  const { submitCount, errors } = useFormikContext();

  const showErrorMessage = submitCount > 0 && _.size(errors);

  useEffect(() => {
    if (showErrorMessage) handleScrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount]);

  console.log(errors);

  return showErrorMessage ? (
    <Alert color="error" icon={<InfoCircleFilled />}>
      Please correct one or more errors on the form.
    </Alert>
  ) : (
    <></>
  );
}

export function StandardFormActions(props: {
  creating: boolean;
  canDelete: boolean;
  displayName: string;
  handleClose: (event?: object, reason?: string) => void;
  saveDisabled: boolean;
  handleDelete: () => void;
  handleSubmit: (
    e?: React.FormEvent<HTMLFormElement> | undefined
  ) => Promise<void>;
  showAddAnother: boolean;
  setSubmitAction?: React.Dispatch<React.SetStateAction<FormSubmitAction>>;
  saveText?: string;
  isNewEditableSections: boolean;
  handlePrint?: (summary: boolean) => void;
}) {
  const [open, setOpen] = useState(false);

  const handleDeleteDialogOpen = () => setOpen(true);
  const handleDeleteDialogClose = () => setOpen(false);

  // Print button popover state management
  const [printButtonAnchorEl, setPrintButtonAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const printOptionsOpen = Boolean(printButtonAnchorEl);

  const handlePrint = (summary: boolean) => {
    if (_.isFunction(props.handlePrint)) {
      props.handlePrint(summary);
    }
    setPrintButtonAnchorEl(null);
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault(); // Prevent default event action
    if (typeof props.setSubmitAction === "function")
      props.setSubmitAction(FormSubmitAction.save);
    await props.handleSubmit();
  };

  return (
    <Grid container style={{ display: "flex" }} alignItems="center">
      <Grid item xs sx={{ flexGrow: 1 }}>
        {!props.creating && props.canDelete && (
          <Fragment>
            <LightTooltip title={`Delete ${props.displayName}`}>
              <IconButton
                onClick={handleDeleteDialogOpen}
                size="large"
                color="error"
              >
                <DeleteFilled />
              </IconButton>
            </LightTooltip>
            <DeleteRecordDialog
              open={open}
              onClose={handleDeleteDialogClose}
              onConfirm={() => {
                props.handleDelete();
                handleDeleteDialogClose();
              }}
              displayName={props.displayName}
            />
          </Fragment>
        )}
        <>
          {_.isFunction(props.handlePrint) && (
            <>
              <LightTooltip title={"Print Contact"}>
                <IconButton
                  sx={{ ml: 0.5 }}
                  color="primary"
                  size="large"
                  onClick={(event) =>
                    setPrintButtonAnchorEl(event.currentTarget)
                  }
                >
                  <PrinterFilled />
                </IconButton>
              </LightTooltip>
              <Popover
                open={printOptionsOpen}
                anchorEl={printButtonAnchorEl}
                onClose={() => setPrintButtonAnchorEl(null)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <ListItemButton onClick={() => handlePrint(true)}>
                  <ListItemText primary="Print Summary" />
                </ListItemButton>
                <ListItemButton onClick={() => handlePrint(false)}>
                  <ListItemText primary="Print All" />
                </ListItemButton>
              </Popover>
            </>
          )}
        </>
      </Grid>
      <Grid item>
        <SaveErrorMessage />
      </Grid>
      <Grid item xs>
        {!props.isNewEditableSections && (
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            alignItems="center"
            sx={{ mr: 1 }}
          >
            <Button
              color="secondary"
              onClick={props.handleClose}
              size={"large"}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              size={"large"}
              variant="contained"
              disabled={props.saveDisabled}
              onClick={handleSubmit}
            >
              {props.saveText ?? "Save"}
            </Button>
            {props.showAddAnother && (
              <Button
                type="submit"
                size={"large"}
                variant="contained"
                disabled={props.saveDisabled}
                onClick={async (event) => {
                  event.preventDefault(); // Prevent default event action
                  if (typeof props.setSubmitAction === "function")
                    props.setSubmitAction(FormSubmitAction.saveAndAddAnother);
                  await props.handleSubmit();
                }}
                sx={{ minWidth: 190 }} // To keep from wrapping
              >
                Save & Add Another
              </Button>
            )}
          </Stack>
        )}
      </Grid>
    </Grid>
  );
}

// ==============================|| OVERRIDES - PAPER ||============================== //
export default function Paper() {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiPaper-rounded": {
            borderRadius: 8,
          },
        },
      },
    },
  };
}

import { createBaseSlice } from "store/reducers/common";
import { BaseState, Keyed } from "types/common";
import { DealStage } from "types/deal";
import { dealService } from "utils/axios";

// ----------------------------------------------------------------------

const initialState: BaseState<Keyed<DealStage>> = {
  loading: false,
  editing: false,
  saving: false,
  deleting: false,
  viewing: false,
  loadError: null,
  saveError: null,
  deleteError: null,
  data: {},
  current: null,
};

const slice = createBaseSlice({
  name: "dealStage",
  initialState,
  reducers: {},
});

// Reducer
export default slice.reducer;

export function syncDealStages() {
  dealService
    .post(`/stage/sync/`, null)
    .then(function (response) {})
    .catch(function (error) {
      console.error(error);
    });
}

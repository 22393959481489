import { CloseOutlined } from "@ant-design/icons";
import { IconButton, Stack, useTheme } from "@mui/material";
import React from "react";

import { LightTooltip } from "./InfoTooltip";

export function CloseButton(props: { onClose: () => void }) {
  const theme = useTheme();

  return (
    <Stack direction={"row"} justifyContent={"flex-end"}>
      <LightTooltip title={"Close"}>
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          color="secondary"
        >
          <CloseOutlined />
        </IconButton>
      </LightTooltip>
    </Stack>
  );
}

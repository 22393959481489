import { EventInput } from "@fullcalendar/common";

// ==============================|| CALENDAR TYPES  ||============================== //

export type DateRange = { start: number | Date; end: number | Date };
export enum CalendarView {
  dayGridWeek = "dayGridWeek",
  dayGridThreeDay = "dayGridThreeDay",
  listWeek = "listWeek",
  dayGridMonth = "dayGridMonth",
  openFullCalendarView = "openFullCalendarView",
}

export type CalendarProps = {
  calendarView: CalendarView;
  error: boolean;
  events: EventInput[];
  isLoader: boolean;
  isModalOpen: boolean;
  selectedEventId: null | string;
  selectedRange: null | { start: Date; end: Date };
};

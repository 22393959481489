import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { OptionFieldEnum } from "types/api/deal/enum";

import { dispatch } from "../index";

interface DealOptionState {
  editing: boolean;
  currentEditing: number | null;
  deleting: boolean;
  currentDeleting: number | null;
  merging: boolean;
  currentMerging: number[];
  currentMergingField: OptionFieldEnum;
  currentMergingTo: number | null;
}

const initialState: DealOptionState = {
  editing: false,
  currentEditing: null,
  deleting: false,
  currentDeleting: null,
  merging: false,
  currentMerging: [],
  currentMergingField: OptionFieldEnum.asset_type,
  currentMergingTo: null,
};

const slice = createSlice({
  name: "dealOptionV2",
  initialState,
  reducers: {
    // Start Editing
    startEditing(state: DealOptionState, action: PayloadAction<number>) {
      state.editing = true;
      state.currentEditing = action.payload;
    },
    // Stop Editing
    stopEditing(state: DealOptionState) {
      state.editing = false;
      state.currentEditing = null;
    },
    // Start Deleting
    startDeleting(state: DealOptionState, action: PayloadAction<number>) {
      state.deleting = true;
      state.currentDeleting = action.payload;
    },
    // Stop Deleting
    stopDeleting(state: DealOptionState) {
      state.deleting = false;
      state.currentDeleting = null;
    },
    // Start Merging
    startMerging(state: DealOptionState, action: PayloadAction<number[]>) {
      state.merging = true;
      state.currentMerging = action.payload;
    },
    // Set Merging Field
    setMergingField(
      state: DealOptionState,
      action: PayloadAction<OptionFieldEnum>
    ) {
      state.currentMergingField = action.payload;
    },
    // Update Merging
    updateMerging(state: DealOptionState, action: PayloadAction<number[]>) {
      state.currentMerging = action.payload;
    },
    // Set Merging-To
    setMergingTo(state: DealOptionState, action: PayloadAction<number>) {
      state.currentMergingTo = action.payload;
    },
    // Stop Merging
    stopMerging(state: DealOptionState) {
      state.merging = false;
      state.currentMerging = [];
      state.currentMergingTo = null;
    },
  },
});

// Reducer
export default slice.reducer;

export function startDeletingDealOption(id: number) {
  dispatch(slice.actions.startDeleting(id));
}

export function stopDeletingDealOption() {
  dispatch(slice.actions.stopDeleting());
}

export function startMergingDealOption(ids: number[]) {
  dispatch(slice.actions.startMerging(ids));
}

export function setMergingFieldDealOption(field: OptionFieldEnum) {
  dispatch(slice.actions.setMergingField(field));
}

export function updateMergingDealOption(ids: number[]) {
  dispatch(slice.actions.updateMerging(ids));
}

export function setMergingToDealOption(id: number) {
  dispatch(slice.actions.setMergingTo(id));
}

export function stopMergingDealOption() {
  dispatch(slice.actions.stopMerging());
}

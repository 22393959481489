import _ from "lodash";
import React, { ReactNode } from "react";
import * as Yup from "yup";

import { DatePicker } from "components/form/DatePicker";
import { SelectOption } from "components/form/SelectOption";
import {
  formatDateForDisplay,
  formatSimpleOptionForDisplay,
  formatSimpleOptionForDisplayMultiple,
} from "components/form/standard/utils/formatting";
import { TextFieldString } from "components/form/TextFieldString";
import { TextFieldTwoDecimal } from "components/form/TextFieldWholeNumber";
import {
  CompanyLookupField,
  OptionLookupField,
  OptionLookupFieldMultiple,
  SimpleOptionLookupMultiple,
  UserLookupField,
} from "pages/deal/components/LookupField";
import {
  SIZE_TYPE_OPTIONS,
  sizeTypeDisplay,
} from "pages/deal/constants/deal_enums";
import {
  PROSPECT_STATUS_OPTIONS,
  PROSPECT_TYPE_OPTIONS,
} from "pages/deal/constants/prospect";
import {
  dateSubmitHandler,
  simpleOptionSubmitHandler,
  simpleOptionSubmitHandlerMultiple,
  submitTwoDecimal,
} from "pages/deal/utils/deal_form";
import { formatWholeNumber } from "pages/deal/utils/reporting";
import { EditType } from "types/api/deal/contact_bulk";
import { ProspectStatusEnum } from "types/api/deal/enum";
import { SimpleOption } from "types/api/deal/form";
import { SizeType } from "types/deal";
import { FieldMetadataV2 } from "types/standardFormV2";

export const ContactBulkEditMetadata: Record<
  string,
  FieldMetadataV2 & {
    component?: ReactNode;
    isMultivalue?: boolean;
    formatForDisplay?: (x: any) => string;
    displayNamePlural?: string;
  }
> = {
  selected_field: {
    fieldName: "selected_field",
    displayName: "",
    _schema: Yup.string().required("Please select a field to update."),
    initialValue: null,
  },
  edit_type: {
    fieldName: "edit_type",
    displayName: "",
    _schema: Yup.string().nullable(),
    initialValue: EditType.append,
  },
  source_type: {
    fieldName: "source_type",
    displayName: "Prospect Source",
    initialValue: null,
    _schema: Yup.object().nullable(),
    component: (
      <OptionLookupField
        fieldName="source_type"
        displayName="Prospect Source"
      />
    ),
    submitHandler: simpleOptionSubmitHandler,
    submitFieldName: "source_type_id",
    formatForDisplay: formatSimpleOptionForDisplay,
  },
  submarkets: {
    fieldName: "submarkets",
    displayName: "Submarket",
    initialValue: [],
    _schema: Yup.array(),
    component: (
      <OptionLookupFieldMultiple
        fieldName="submarkets"
        displayName="Submarket"
      />
    ),
    submitHandler: simpleOptionSubmitHandlerMultiple,
    submitFieldName: "submarket_ids",
    isMultivalue: true,
    formatForDisplay: formatSimpleOptionForDisplayMultiple,
    displayNamePlural: "Submarkets",
  },
  client_industry: {
    fieldName: "client_industry",
    displayName: "Industry",
    initialValue: null,
    _schema: Yup.object().nullable(),
    component: (
      <OptionLookupField fieldName="client_industry" displayName="Industry" />
    ),
    submitHandler: simpleOptionSubmitHandler,
    submitFieldName: "client_industry_id",
    formatForDisplay: formatSimpleOptionForDisplay,
  },
  size_est: {
    fieldName: "size_est",
    displayName: "Estimated Size",
    initialValue: null,
    _schema: Yup.number().nullable().min(0, "Estimated Size must be positive."),
    component: (
      <TextFieldTwoDecimal fieldName="size_est" displayName="Estimated Size" />
    ),
    submitHandler: submitTwoDecimal,
    formatForDisplay: formatWholeNumber,
  },
  size_type: {
    fieldName: "size_type",
    displayName: "Size Type",
    initialValue: SizeType.square_feet,
    _schema: Yup.string().nullable(),
    component: (
      <SelectOption
        fieldName="size_type"
        displayName="Size Type"
        options={SIZE_TYPE_OPTIONS}
      />
    ),
    formatForDisplay: (x) => _.get(sizeTypeDisplay, x, ""),
  },
  lease_expiration: {
    fieldName: "lease_expiration",
    displayName: "Current Lease Expiration",
    initialValue: null,
    _schema: Yup.date()
      .nullable()
      .typeError("Lease Expiration must be a date."),
    component: (
      <DatePicker
        fieldName="lease_expiration"
        displayName="Current Lease Expiration"
      />
    ),
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay,
  },
  is_prospect: {
    fieldName: "is_prospect",
    displayName: "Is Prospect?",
    initialValue: "no",
    _schema: Yup.string(),
    component: (
      <SelectOption
        fieldName="is_prospect"
        displayName="Is Prospect?"
        options={[
          { key: "yes", label: "Yes" },
          { key: "no", label: "No" },
        ]}
      />
    ),
    submitHandler: (x: string) => x === "yes",
  },
  asset_types: {
    fieldName: "asset_types",
    displayName: "Asset Type",
    initialValue: [],
    _schema: Yup.array(),
    component: (
      <OptionLookupFieldMultiple
        fieldName="asset_types"
        displayName="Asset Type"
      />
    ),
    submitHandler: simpleOptionSubmitHandlerMultiple,
    submitFieldName: "asset_type_ids",
    isMultivalue: true,
    formatForDisplay: formatSimpleOptionForDisplayMultiple,
    displayNamePlural: "Asset Types",
  },
  prospect_types: {
    fieldName: "prospect_types",
    displayName: "Prospect Type",
    initialValue: [],
    _schema: Yup.array(),
    component: (
      <SimpleOptionLookupMultiple
        fieldName="prospect_types"
        displayName="Prospect Type"
        options={PROSPECT_TYPE_OPTIONS}
      />
    ),
    submitHandler: simpleOptionSubmitHandlerMultiple,
    isMultivalue: true,
    formatForDisplay: formatSimpleOptionForDisplayMultiple,
    displayNamePlural: "Prospect Types",
  },
  property_names: {
    fieldName: "property_names",
    displayName: "Property Name",
    initialValue: [],
    _schema: Yup.array(),
    component: (
      <OptionLookupFieldMultiple
        fieldName="property_names"
        displayName="Property Name"
      />
    ),
    submitHandler: simpleOptionSubmitHandlerMultiple,
    submitFieldName: "property_name_ids",
    isMultivalue: true,
    formatForDisplay: formatSimpleOptionForDisplayMultiple,
    displayNamePlural: "Property Names",
  },
  owner: {
    fieldName: "owner",
    displayName: "Owner",
    initialValue: null,
    _schema: Yup.object().nullable(),
    component: (
      <UserLookupField fieldName="owner" displayName="Contact Owner" />
    ),
    submitHandler: (x: SimpleOption) => x?.key,
    formatForDisplay: formatSimpleOptionForDisplay,
  },
  first_name: {
    fieldName: "first_name",
    displayName: "First Name",
    initialValue: "",
    _schema: Yup.string()
      .max(255, "First Name must be less than 256 characters.")
      .nullable(),
    component: (
      <TextFieldString fieldName="first_name" displayName="First Name" />
    ),
  },
  last_name: {
    fieldName: "last_name",
    displayName: "Last Name",
    initialValue: "",
    _schema: Yup.string()
      .nullable()
      .max(255, "Last Name must be less than 256 characters."),
    component: (
      <TextFieldString fieldName="last_name" displayName="Last Name" />
    ),
  },
  status: {
    fieldName: "status",
    displayName: "Status",
    initialValue: ProspectStatusEnum.open,
    _schema: Yup.string().nullable(),
    component: (
      <SelectOption
        fieldName="status"
        displayName="Status"
        options={PROSPECT_STATUS_OPTIONS}
      />
    ),
  },
  title: {
    fieldName: "title",
    displayName: "Title",
    initialValue: "",
    _schema: Yup.string()
      .max(255, "Title must be less than 256 characters.")
      .nullable(),
    component: <TextFieldString fieldName="title" displayName="Title" />,
  },
  company: {
    fieldName: "company",
    displayName: "Company",
    initialValue: null,
    _schema: Yup.object().nullable(),
    component: <CompanyLookupField fieldName="company" displayName="Company" />,
    submitHandler: simpleOptionSubmitHandler,
    submitFieldName: "company_id",
    formatForDisplay: formatSimpleOptionForDisplay,
  },
  notes_plaintext: {
    fieldName: "notes_plaintext",
    displayName: "Notes",
    initialValue: "",
    _schema: Yup.string()
      .max(10000, "Notes must be less than 10,000 characters.")
      .nullable(),
    component: (
      <TextFieldString
        fieldName="notes_plaintext"
        displayName="Notes"
        multiline={true}
      />
    ),
  },
  telephone1: {
    fieldName: "telephone1",
    displayName: "Phone",
    initialValue: "",
    _schema: Yup.string().nullable(),
    component: <TextFieldString fieldName="telephone1" displayName="Phone" />,
  },
  telephone2: {
    fieldName: "telephone2",
    displayName: "Mobile Phone",
    initialValue: "",
    _schema: Yup.string().nullable(),
    component: (
      <TextFieldString fieldName="telephone2" displayName="Mobile Phone" />
    ),
  },
  email_address: {
    fieldName: "email_address",
    displayName: "Email",
    initialValue: "",
    _schema: Yup.string()
      .max(255, "Email must be less than 256 characters.")
      .nullable(),
    component: (
      <TextFieldString fieldName="email_address" displayName="Email" />
    ),
  },
  contact_type: {
    fieldName: "contact_type",
    displayName: "Contact Type",
    initialValue: null,
    _schema: Yup.object().nullable(),
    component: (
      <OptionLookupField fieldName="contact_type" displayName="Contact Type" />
    ),
    submitHandler: simpleOptionSubmitHandler,
    submitFieldName: "contact_type_id",
    formatForDisplay: formatSimpleOptionForDisplay,
  },
  groups: {
    fieldName: "groups",
    displayName: "Group",
    initialValue: [],
    _schema: Yup.array(),
    component: (
      <OptionLookupFieldMultiple fieldName="groups" displayName="Group" />
    ),
    submitHandler: simpleOptionSubmitHandlerMultiple,
    submitFieldName: "group_ids",
    isMultivalue: true,
    displayNamePlural: "Groups",
  },
};

export const PERMISSIONS_DESCRIPTION_MAP = {
  deal: {
    all: "All deals",
    team: "Deals their team members are on",
    individual: "Deals they are on",
    none: "No deals",
  },
  contact: {
    all: "All contacts",
    team: "Contacts their team owns",
    individual: "Contacts they own",
    none: "No contacts",
  },
  company: {
    all: "All companies",
    team: "Companies their team owns",
    individual: "Companies they own",
    none: "No companies",
  },
};

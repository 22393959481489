import { filterEmpty } from "types/navigation/common";
import { DateRangeFilterType } from "types/navigation/dealNavigation";

export const DEFAULT_TABLE_SORT = { id: "name", desc: false };
export const baseDealFilters = {
  asset_type_id: filterEmpty,
  broker: filterEmpty,
  date_range: filterEmpty,
  deal_type: filterEmpty,
  broker_role: filterEmpty,
  property_name_id: filterEmpty,
  stage_id: filterEmpty,
  shared_deal: filterEmpty,
  sort: filterEmpty,
  sort_order: filterEmpty,
  status: filterEmpty,
  tenant_name_id: filterEmpty,
  date_range_type: DateRangeFilterType.close_date,
  has_outstanding_commission: filterEmpty,
  source_type_id: filterEmpty,
  client_industry_id: filterEmpty,
  submarket_id: filterEmpty,
  contact_id: filterEmpty,
};

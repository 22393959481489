import { Theme } from "@mui/material/styles";
import _ from "lodash";

import { filterEmpty } from "types/navigation/common";

export function isFilterEmpty<T>(value: T) {
  /**
   * Determines whether a given filter value is empty or not.
   *
   * @param value - The value to check for emptiness.
   * @returns `true` if the value is empty, `false` otherwise.
   * @template T - The type of the value being checked.
   */

  return (
    _.isNil(value) || _.isEqual(value, filterEmpty) || _.isEqual(value, [])
  );
}

export function getConditionalFilterSx(value: any, theme: Theme) {
  return isFilterEmpty(value)
    ? {}
    : {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        fontWeight: 600,
      };
}

export function removeEmptyFilterFromValue(x: string[] | string) {
  return _.isArray(x) ? _.filter(x, (x) => x !== filterEmpty[0]) : x;
}

import { PlusOutlined } from "@ant-design/icons";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import _ from "lodash";
import { Fragment, useEffect, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Column,
  ColumnInstance,
  Row,
  useColumnOrder,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import {
  handleDealFormNavigate,
  handlePreview,
} from "components/form/standard/utils/navigation";
import {
  DragPreview,
  HeaderSort,
  TablePagination,
  TableRowSelection,
} from "components/third-party/ReactTable";
import { useEffectUpdateOnly } from "hooks/useEffectUpdateOnly";
import { CSVExportV2 } from "pages/deal/components/CSVExportV2";
import {
  defaultColumnOrder,
  getHiddenDealColumns,
} from "pages/deal/sections/constants/dealTable";
import { convertSortFromReactTable } from "pages/deal/utils/deal";
import { RootState, useSelector } from "store";
import { setDealSort } from "store/reducers/dealNavigation";
import { DealExtended } from "types/deal";
import { DealStats } from "types/dealReporting";
import { FormIdentifier, RecordIdentifier } from "types/record";
import { GlobalFilter, renderFilterTypes } from "utils/react-table";

// Custom reducer to set the table state (after setState was deprecated)
const customReducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_CUSTOM_STATE":
      return {
        ...state,
        stats: action.value,
      };
    default:
      return state;
  }
};

interface PipelineOverviewTableProps {
  columns: Column[];
  data: Partial<DealExtended>[];
  stats?: DealStats;
  defaultPageSize: number;
  filters: any;
  getHeaderProps: (column: any) => {
    onClick: (e: any) => void;
    style: { cursor: string };
    title: string;
  };
  showColumns: string[];
  onRowsChange: (rows: any) => void;
  showAdd: boolean;
  showAggregate: boolean;
  showFiltering: boolean;
  showWidget: boolean;
  skipPageReset: boolean;
  initialSortBy: any;
  syncSort?: boolean;
  /**
   * An object of ids that correspond to ids used within tables.  It is a mapping to be used during exports
   */
  usersDisplay: { [option: string]: string } | null;
  tableSize?: "small" | "medium" | undefined;
  resizeColumns?: boolean; // A param to toggle the ability to resize columns
}

function PipelineOverviewTable({
  columns,
  data,
  stats,
  defaultPageSize,
  filters,
  showColumns,
  onRowsChange,
  showAdd,
  showAggregate,
  showFiltering,
  skipPageReset,
  initialSortBy,
  syncSort = false,
  tableSize,
}: PipelineOverviewTableProps) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const publishedFilters = useSelector(
    (state: RootState) => state.dealNavigation.published
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const filterTypes = useMemo(() => renderFilterTypes, []);
  const defaultColumn = useMemo(() => ({}), []);
  const initialHiddenColumns = useMemo(
    () => getHiddenDealColumns(showColumns),
    [showColumns]
  );
  const initialColumnOrder = useMemo(() => defaultColumnOrder, []);

  const initialState = useMemo(
    () => ({
      filters,
      stats,
      pageIndex: 0,
      pageSize: defaultPageSize,
      hiddenColumns: initialHiddenColumns,
      sortBy: [initialSortBy],
      columnOrder: initialColumnOrder,
    }),
    [filters, defaultPageSize, initialSortBy]
  );

  // @ts-ignore
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    rows,
    // @ts-ignore
    page,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    state: {
      // @ts-ignore
      globalFilter,
      // @ts-ignore
      selectedRowIds,
      // @ts-ignore
      pageIndex,
      // @ts-ignore
      pageSize,
      // @ts-ignore
      sortBy,
      // @ts-ignore
      columnOrder,
    },
    // @ts-ignore
    preGlobalFilteredRows,
    // @ts-ignore
    setGlobalFilter,
    // @ts-ignore
    setSortBy,
    dispatch: tableDispatch,
  } = useTable<Partial<DealExtended>>(
    {
      // @ts-ignore
      columns,
      data,
      // @ts-ignore
      defaultColumn,
      // @ts-ignore
      filterTypes,
      // @ts-ignore
      initialState,
      stateReducer: customReducer,
      autoResetPage: !skipPageReset,
      autoResetExpanded: !skipPageReset,
      autoResetGroupBy: !skipPageReset,
      autoResetSelectedRows: !skipPageReset,
      autoResetSortBy: !skipPageReset,
      autoResetFilters: !skipPageReset,
      autoResetRowState: !skipPageReset,
      autoResetHiddenColumns: !skipPageReset,
      autoResetGlobalFilter: !skipPageReset,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useColumnOrder
  );

  useEffect(() => onRowsChange(rows), [rows, onRowsChange]);

  // Sync current Sort By to filter store
  useEffectUpdateOnly(() => {
    if (syncSort) {
      const { sort, sort_order } = convertSortFromReactTable(
        _.head(sortBy) ?? {}
      );
      setDealSort(sort, sort_order);
    }
  }, [sortBy]);
  // If the initial sortBy was changed after the first render, push this update to the current state of the table
  useEffectUpdateOnly(() => {
    setSortBy([initialSortBy]);
  }, [initialSortBy]);

  // Manually reset the page index on change of Global Filter (if auto-reset has been disabled)
  useEffect(() => {
    if (skipPageReset) {
      if (pageIndex !== 0) {
        gotoPage(0);
      }
    }
  }, [globalFilter, skipPageReset]);

  const updateStats = (newValue: any) => {
    tableDispatch({
      type: "SET_CUSTOM_STATE",
      value: newValue,
    });
  };

  // Update the stats state object when stats data changes
  useEffect(() => {
    updateStats(stats);
  }, [stats]);

  return (
    <>
      <TableRowSelection selected={Object.keys(selectedRowIds).length} />
      <Stack spacing={3}>
        <Stack
          direction={matchDownSM ? "column" : "row"}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ px: 3, pb: 0 }}
        >
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            size="small"
          />

          <Stack
            direction={matchDownSM ? "column" : "row"}
            alignItems="center"
            spacing={1}
          >
            <CSVExportV2
              recordIdentifier={RecordIdentifier.Deal}
              filters={publishedFilters}
            />
            {showAdd && (
              <Button
                variant="contained"
                startIcon={<PlusOutlined />}
                onClick={() => {
                  handleDealFormNavigate(
                    null,
                    FormIdentifier.DealViewProForm,
                    navigate
                  );
                }}
              >
                Add Deal
              </Button>
            )}
          </Stack>
        </Stack>
        <Box sx={{ width: "100%", overflowX: "auto", display: "block" }}>
          <DndProvider backend={HTML5Backend}>
            <Table {...getTableProps()} size={tableSize}>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => {
                      return (
                        <TableCell
                          {...column.getHeaderProps([
                            { className: column.className },
                          ])}
                        >
                          <HeaderSort column={column} sort />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {showFiltering &&
                  headerGroups.map((group: any) => (
                    <TableRow {...group.getHeaderGroupProps()}>
                      {group.headers.map((column: any) => (
                        <TableCell
                          {...column.getHeaderProps([
                            { className: column.className },
                          ])}
                        >
                          {column.canFilter ? column.render("Filter") : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                {page.map(
                  (
                    row: { isSelected: boolean } & Row<Partial<DealExtended>>
                  ) => {
                    prepareRow(row);

                    return (
                      <Fragment key={row.original.id}>
                        <TableRow
                          {...row.getRowProps()}
                          sx={{
                            bgcolor: row.isSelected
                              ? alpha(theme.palette.primary.lighter, 0.35)
                              : "inherit",
                          }}
                        >
                          {row.cells.map((cell) => {
                            // @ts-ignore
                            const column: {
                              onClick: any;
                              className: any;
                            } & ColumnInstance = cell.column;
                            return (
                              <TableCell
                                onClick={
                                  column.onClick ??
                                  (() => {
                                    const id = row.original.id!;
                                    handlePreview(
                                      id,
                                      RecordIdentifier.Deal,
                                      searchParams,
                                      setSearchParams
                                    );
                                  })
                                }
                                {...cell.getCellProps([
                                  { className: column.className },
                                ])}
                                sx={{ cursor: "pointer" }}
                              >
                                {cell.render("Cell", {
                                  observeMinWidth: false,
                                })}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </Fragment>
                    );
                  }
                )}
              </TableBody>
              {showAggregate && (
                <TableFooter sx={{ borderBottomWidth: 2 }}>
                  {footerGroups.map((group) => (
                    <TableRow {...group.getFooterGroupProps()}>
                      {group.headers.map((column: any, index) => {
                        return (
                          <TableCell
                            {...column.getFooterProps([
                              { className: column.className },
                            ])}
                          >
                            {column.render("Footer")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableFooter>
              )}
            </Table>
            <DragPreview />
          </DndProvider>
        </Box>
        <Box sx={{ p: 2, py: 0 }}>
          <>
            * <em>Estimated</em>
          </>
          <TablePagination
            gotoPage={gotoPage}
            rowCount={rows.length}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        </Box>
      </Stack>
    </>
  );
}

export default PipelineOverviewTable;

// Example usage
import {
  FormIdentifier,
  RecordIdentifier,
  RecordMetadataType,
} from "types/record";

export const RecordMetadata: RecordMetadataType = {
  [RecordIdentifier.Activity]: {
    dataUrl: "/activity/tables/overview/",
    displayName: "Activity",
    displayNamePlural: "Activities",
    mainForm: FormIdentifier.ActivityForm,
  },
  [RecordIdentifier.Company]: {
    dataUrl: "/company/tables/overview/",
    displayName: "Company",
    displayNamePlural: "Companies",
    mainForm: FormIdentifier.CompanyForm,
  },
  [RecordIdentifier.Contact]: {
    dataUrl: "/contact/tables/overview/v2/",
    exportUrl: "/data_management/export/contact/",
    displayName: "Contact",
    displayNamePlural: "Contacts",
    mainForm: FormIdentifier.ContactForm,
  },
  [RecordIdentifier.Deal]: {
    dataUrl: "/tables/overview/v2/",
    exportUrl: "/data_management/export/deal/",
    displayName: "Deal",
    displayNamePlural: "Deals",
    mainForm: FormIdentifier.DealViewProForm,
  },
  [RecordIdentifier.Prospect]: {
    dataUrl: "/contact/tables/overview/v2/",
    exportUrl: "/data_management/export/contact/",
    displayName: "Prospect",
    displayNamePlural: "Prospects",
    mainForm: FormIdentifier.ContactForm,
  },
  [RecordIdentifier.OutstandingCommissionPayment]: {
    dataUrl: "/widgets/outstanding_commission_table/",
    exportUrl: "/data_management/export/outstanding_commission_payment/",
    displayName: "Commission Payment",
    displayNamePlural: "Commission Payments",
  },
  [RecordIdentifier.Email]: {
    dataUrl: "",
    exportUrl: "",
    displayName: "Email",
    displayNamePlural: "Emails",
  },
  [RecordIdentifier.FormLayout]: {
    dataUrl: "",
    exportUrl: "",
    displayName: "Form Layout",
    displayNamePlural: "Form Layouts",
  },
  [RecordIdentifier.FormField]: {
    dataUrl: "",
    exportUrl: "",
    displayName: "Form Field",
    displayNamePlural: "Form Fields",
  },
  [RecordIdentifier.Property]: {
    dataUrl: "/property/tables/overview",
    exportUrl: "",
    displayName: "Property",
    displayNamePlural: "Properties",
  },
};

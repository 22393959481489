export enum Product {
  prospects = "prospects",
  deal_view_pro = "deal_view_pro",
  deal_view = "deal_view",
  lend_db = "lend_db",
}

export enum BaseRoute {
  deals = "deals",
  prospects = "prospects",
  lenders = "lenders",
}

export const ROUTE_PRODUCTS = {
  [BaseRoute.deals]: [Product.deal_view, Product.deal_view_pro],
  [BaseRoute.prospects]: [Product.prospects],
  [BaseRoute.lenders]: [Product.lend_db],
};

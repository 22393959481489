export enum EditType {
  append = "append",
  replace = "replace",
}

export interface ContactShare {
  team_ids: number[];
  user_ids: number[];
}

export interface ContactDeleteBulk {
  is_filter: boolean;
  record_ids: number[];
}

export interface ContactShareBulk {
  edit_type: EditType;
  is_filter: boolean;
  record_ids: number[];
  team_ids: number[];
  user_ids: number[];
}

export interface ContactEditBulk {
  data: Record<string, any>;
  edit_type: EditType;
  is_filter: boolean;
  record_ids: number[];
}

import dayjs from "dayjs";
import _ from "lodash";
export const EMPTY_FIELD = "--";

export const formatStringForDisplay = (x: any) =>
  _.isNil(x) || x == "" ? EMPTY_FIELD : `${x}`;
export const formatSimpleOptionForDisplay = (x: any) =>
  !_.isNil(x) ? `${_.get(x, "label")}` : EMPTY_FIELD;
export const formatSimpleOptionForDisplayMultiple = (x: any) =>
  _.isArray(x) && _.size(x) > 0 ? _.join(_.map(x, "label"), ", ") : EMPTY_FIELD;
export const formatDateForDisplay = (x: any) =>
  _.isNil(x) ? EMPTY_FIELD : `${dayjs(x).format("M/D/YYYY")}`;

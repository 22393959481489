import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { useMemo, useState } from "react";

import { RecordMetadata } from "constants/record";
import { useUsersDisplay } from "contexts/UserDisplayContext";
import useAuth from "hooks/useAuth";
import { LightTooltip } from "pages/deal/components/InfoTooltip";
import { parseFiltersAsParamsObject } from "pages/deal/utils/deal";
import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { BaseFilter } from "types/api/deal/filters/common";
import { PermissionSet } from "types/permission";
import { RecordIdentifier } from "types/record";
import { dealService } from "utils/axios";

interface CSVExportV2Props {
  recordIdentifier: RecordIdentifier;
  filters: BaseFilter;
}

export const CSVExportV2 = ({
  recordIdentifier,
  filters,
}: CSVExportV2Props) => {
  const { checkPermission } = useAuth();
  const canExport = useMemo(
    () => checkPermission(PermissionSet.organization_data_exporter),
    []
  );

  const [isLoading, setIsLoading] = useState(false); // State to track loading status
  const queryParams = useMemo(
    () => parseFiltersAsParamsObject({ ...filters, skip: 0, limit: 10000 }),
    [filters]
  ); // 10,000 is the hard limit for Elasticsearch queries
  const usersDisplay = useUsersDisplay();

  const handleDownload = async () => {
    setIsLoading(true); // Start loading
    try {
      const response = await dealService.post(
        RecordMetadata[recordIdentifier].exportUrl ?? "",
        usersDisplay, // Body of the request
        {
          responseType: "blob", // Important for handling binary data like files
          params: queryParams, // Query params
        }
      );
      saveAs(
        response.data,
        `CRE OneSource ${RecordMetadata[recordIdentifier].displayNamePlural} ${dayjs().format("YYYY-MM-DD")}.csv`
      );
    } catch (error) {
      dispatch(
        openErrorNotification(`An error occurred while downloading the file.`)
      );
      Sentry.captureMessage(
        `An error occurred while exporting ${RecordMetadata[recordIdentifier].displayNamePlural}. Error: ${error}`
      );
    } finally {
      setIsLoading(false); // Stop loading regardless of the outcome
    }
  };

  return (
    <div>
      {canExport && (
        <LightTooltip title={"Export as CSV"}>
          <IconButton onClick={handleDownload}>
            <Stack direction={"row"} justifyContent={"center"}>
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <FileDownloadOutlinedIcon color="primary" />
              )}
            </Stack>
          </IconButton>
        </LightTooltip>
      )}
    </div>
  );
};

import { ReactElement } from "react";

import { AccessTokenRead } from "types/api/user_management/access_token";
// ==============================|| AUTH TYPES  ||============================== //

export type GuardProps = {
  children: ReactElement | null;
};

type Permission = {
  name: string;
  service: string;
};

type AssignedRoles = {
  id: string;
  name: string;
  permissions: Permission[];
};

export interface OpenID {
  id?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  display_name?: string;
  picture?: string;
  provider?: string;
}

interface User {
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  hierarchy_role: string;
  organization: object;
  hierarchy: object;
  hierarchy_id: number;
  organization_id: number;
  products: string[];
  assigned_role: AssignedRoles[];
  integrations: { [integration: string]: string | null };
  permissions: object;
  trial_end_date: number | undefined;
  expire_date: number;
  theme: string | null;
}

export interface TeamMember extends User {
  id: string;
}

export interface SimpleTeamMember {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  organization_id: number;
  hierarchy_id: number;
  permissions: Record<string, object>;
  team_member_ids: number[];
}

export type ServiceToken = {
  access_token: string;
  token_type: string;
};

export interface AuthProps {
  isLoggedIn: boolean;
  isInitialized?: boolean;
  user?: AccessTokenRead | null;
  token_header_payload?: string | null;
  token_header?: string | null;
}

export interface AuthActionProps {
  type: string;
  payload?: AuthProps;
}

export type UserRegister = {
  first_name: string;
  last_name: string;
  username: string;
  company: string;
  phone_number?: string;
  password: string | null;
  license_name: string;
};

export type JWTContextType = {
  isLoggedIn: boolean;
  isInitialized?: boolean;
  user: AccessTokenRead;
  logout: () => void;
  sso_login: (token_header: string) => void;
  refresh: () => void;
  register: (
    { first_name, last_name, username, company, password }: UserRegister,
    product_id: string
  ) => Promise<string>;
  sso_register: (
    user: AccessTokenRead,
    token_header: string
  ) => Promise<string>;
  sso_identify: (
    user_openid: OpenID,
    code: string | null,
    client_info: string | null,
    state: string | null,
    session_state: string | null
  ) => void;
  resendActivationLink: (data: { username: string }) => Promise<void>;
  resetPassword: (
    data: { password: string },
    change_token: string | null
  ) => Promise<void>;
  changePassword: (data: { password: string }) => Promise<void>;
  forgotPassword: (data: { username: string }) => Promise<void>;
  login: (
    email: string,
    password: string,
    token: string | null
  ) => Promise<void>;
  token_header: string;
  checkPermission: (path: string) => boolean;
  checkPermissions: (permissions: string[]) => boolean;
};

export enum UserSignOutReason {
  Logout = "logout",
  RefreshFailed = "refresh_failed",
  NoCookie = "no_cookie",
}

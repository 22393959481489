import { Container, DialogContent, DialogTitle, Grid } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import * as Sentry from "@sentry/react";
import _ from "lodash";

import StandardField from "components/form/standard/StandardField";
import { StandardForm } from "components/form/standard/StandardForm";
import MainCard from "components/MainCard";
import { ContactLimitedMetadata } from "constants/objectMetadata/contactMetadata";
import { CloseButton } from "pages/deal/components/CloseButton";
import { startEditingRecord } from "store/reducers/record";
import {
  Contact,
  ContactRead,
  ContactWithFollowup,
} from "types/api/deal/contact";
import { FormIdentifier, StandardFormProps } from "types/record";
import { FormSubmitAction } from "types/standardForm";
import { createSimpleContactAsync, updateContactAsync } from "utils/contact";

export const ContactNameOnlyForm = ({
  record,
  incomingChanges,
  onCancel,
  successCallback,
  open,
  showAddAnother,
}: StandardFormProps<ContactRead & ContactWithFollowup>) => {
  const createFnAsync = async (
    contact: ContactWithFollowup,
    submitAction: FormSubmitAction = FormSubmitAction.save
  ) => {
    try {
      const record = await createSimpleContactAsync(contact);

      if (typeof successCallback === "function" && _.isArray(record))
        successCallback(record);

      if (submitAction === FormSubmitAction.saveAndAddAnother) {
        await new Promise((resolve) => setTimeout(resolve, 200));
        startEditingRecord(
          null,
          FormIdentifier.ContactQuickCreateForm,
          {},
          true
        );
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const updateFnAsync = async (
    id: number | string,
    contact: Partial<Contact>
  ) => {
    try {
      const record = await updateContactAsync(id, contact);
      if (typeof successCallback === "function" && _.isArray(record))
        successCallback(record);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <StandardForm<ContactWithFollowup>
      metadata={ContactLimitedMetadata}
      record={record}
      updateFn={updateFnAsync}
      createFn={createFnAsync}
      displayName={"Contact"}
      onCancel={onCancel}
      incomingChanges={incomingChanges}
      showAddAnother={showAddAnother}
      fullWidth={false}
      open={open}
    >
      {({ StandardFormActionsInstance }) => (
        <>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <DialogTitle>{!!record ? "Edit" : "Add"} Contact</DialogTitle>
            </Grid>
            <Grid item sx={{ mr: 1.5 }}>
              <CloseButton onClose={onCancel} />
            </Grid>
          </Grid>
          <DialogContent>
            <Container>
              <Grid container spacing={3}>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={12}>
                    <MainCard>
                      <Grid container columnSpacing={2} rowSpacing={1}>
                        <Grid item xs={12} sm={6}>
                          <StandardField
                            field={ContactLimitedMetadata.first_name}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <StandardField
                            field={ContactLimitedMetadata.last_name}
                          />
                        </Grid>
                      </Grid>
                    </MainCard>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </DialogContent>
          <DialogActions>{StandardFormActionsInstance}</DialogActions>
        </>
      )}
    </StandardForm>
  );
};

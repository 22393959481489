import useLocalStorage from "hooks/useLocalStorage";
import { Product } from "types/license";

export const useLicenseTypeDev = (defaultValue: Product[]) => {
  const [enabledState, setEnabledState] = useLocalStorage(
    "license-type-dev",
    defaultValue
  );
  return [enabledState, setEnabledState];
};

import { Theme } from "@mui/material/styles";

import { FormIdentifier } from "types/record";

// Defining the structure for individual table map entries
export interface TableMapEntry {
  getColumns: (theme?: Theme, usersDisplay?: any) => any;
  loadUrl: string;
  editFormIdentifier: FormIdentifier;
  title: JSX.Element | string;
  defaultColumns: string[];
}

export enum TableIdentifier {
  ActivityCompletedTasks = "ActivityCompletedTasks",
  ActivityQuickView = "ActivityQuickView",
  ContactQuickView = "ContactQuickView",
  CompanyQuickView = "CompanyQuickView",
  ProspectQuickView = "ProspectQuickView",
}

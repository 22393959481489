// ==============================|| OVERRIDES - INPUT BASE ||============================== //

import { Theme } from "@mui/material/styles";

export default function InputBase(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          fontSize: "0.75rem",
        },
        root: {
          height: "initial",
          background: "white",
          "&.MuiInputBase-root": {
            borderRadius: "8px",
          },
          "&.Mui-disabled": {
            background: "white",
            WebkitTextFillColor: theme.palette.text.disabled,
            "&:hover": {
              borderColor: theme.palette.action.disabled,
            },
          },
        },
        multiline: {
          height: "initial",
        },
      },
    },
  };
}

//@ts-ignore
import { CSVBoxButton } from "@csvbox/react";
import {
  Alert,
  Box,
  Container,
  Divider,
  Grid,
  InputLabel,
  Link,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { useFormik } from "formik";
import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";

import LoadingButton from "components/@extended/LoadingButton";
import { FilterAutocomplete } from "components/filter/FilterBar/FilterComponents/Autocomplete/FilterAutocomplete";
import config from "config";
import { RecordMetadata } from "constants/record";
import useAuth from "hooks/useAuth";
import { getNewActivityImportColumns } from "pages/data_import/constants/newActivityColumns";
import { getNewCompanyImportColumns } from "pages/data_import/constants/newCompanyColumns";
import {
  getAllContactImportColumns,
  getNewContactImportColumns,
} from "pages/data_import/constants/newContactColumns";
import { getNewDealImportColumns } from "pages/data_import/constants/newDealColumns";
import { getUserFullName } from "pages/deal/utils/deal";
import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { SimpleOption } from "types/api/deal/form";
import { SimpleTeamMember } from "types/auth";
import { DealStatus } from "types/deal";
import { axiosUserServices, dealService } from "utils/axios";

enum ImportType {
  deal = "deal",
  contact = "contact",
  company = "company",
  activity = "activity",
}

export enum ActionType {
  insert = "insert",
  upsert = "upsert",
}

type ImportSettings = {
  licenseKey: string;
  importButtonKey: string;
  dynamicColumns: any;
  postUrl: string;
  successMessage: string;
  errorMessage: string;
};

type WrappedCSVBoxButtonProps = {
  licenseKey: string;
  username: string;
  clientUser: SimpleTeamMember | null;
  onImport: (result: boolean, data: { row_success: string }) => void;
  dynamicColumns: any;
  importDisabled: boolean;
  buttonLoading: boolean;
  setButtonLoading: React.Dispatch<React.SetStateAction<boolean>>;
  buttonText: string;
  updatedFields?: string[];
};

export function WrappedCSVBoxButton({
  licenseKey,
  username,
  clientUser,
  onImport,
  dynamicColumns,
  importDisabled,
  buttonLoading,
  setButtonLoading,
  buttonText,
  updatedFields,
}: WrappedCSVBoxButtonProps) {
  return (
    <CSVBoxButton
      // The CSVBoxButton component does not register changes for prop values after first render. By updating the key prop of a component, React will consider it as a completely new component instance and trigger a re-render.
      licenseKey={licenseKey}
      lazy={true} // Using lazy loading due to props not being re-loaded after first render for this component
      user={{
        user_id: username,
        userObj:
          clientUser &&
          JSON.stringify({
            id: clientUser?.id,
            team_id: clientUser?.hierarchy_id,
            org_id: clientUser?.organization_id,
            permissions: clientUser?.permissions,
            team_member_ids: clientUser?.team_member_ids,
          }),
        meta: JSON.stringify({
          updated_fields: updatedFields,
        }),
      }}
      onImport={onImport}
      loadStarted={() => {
        console.log("loadStarted");
      }}
      onReady={() => {
        console.log("onReady");
      }}
      onClose={() => {
        console.log("onclosed");
      }}
      onSubmit={(data: { import_id: any }) => {
        console.log("onSubmit");
        console.log(data.import_id);
        setButtonLoading(true);
      }}
      render={(
        launch: React.MouseEventHandler<HTMLButtonElement> | undefined,
        isLoading: boolean | undefined
      ) => {
        return (
          <LoadingButton
            disabled={isLoading || importDisabled}
            onClick={launch}
            variant={"contained"}
            loading={buttonLoading}
          >
            {buttonText}
          </LoadingButton>
        );
      }}
      dynamicColumns={dynamicColumns}
    ></CSVBoxButton>
  );
}

const UploadCSV = () => {
  const { checkPermission } = useAuth();
  const isSuperuser = useMemo(
    () => checkPermission("SuperUser.user.account_management"),
    []
  );
  const featureEnabled = useMemo(
    () => config.envName !== "production" || isSuperuser,
    [isSuperuser]
  );
  const [orgMembers, setOrgMembers] = useState<SimpleTeamMember[]>([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [importResult, setImportResult] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      username: "",
      dealStatus: DealStatus.closed,
      importType: ImportType.deal,
      actionType: ActionType.insert,
      updatedFields: [],
    },
    onSubmit: (values) => {
      axiosUserServices
        .get(`/organization/member/${formik.values.username}`)
        .then(function (response) {
          if (_.isArray(response.data)) {
            setOrgMembers(response.data);
          } else {
            dispatch(openErrorNotification(`Error loading team members.`));
          }
        })
        .catch(() => {
          dispatch(openErrorNotification(`Error loading team members.`));
        });
    },
  });

  const brokerValues = useMemo(() => {
    let values = _.map(orgMembers, (x) => ({
      value: `${x.id}`,
      display_label: getUserFullName(x),
    }));
    values.push({ value: "-1", display_label: "Other" });
    values.push({ value: "-2", display_label: "Company / House" });
    return values;
  }, [orgMembers]);

  const clientUser = useMemo(
    () => _.find(orgMembers, { username: _.toLower(formik.values.username) }),
    [formik.values.username, orgMembers]
  );

  const newDealImportColumns = useMemo(
    () =>
      _.filter(
        getNewDealImportColumns(brokerValues, formik.values.dealStatus),
        (x) => x._visibility.includes(formik.values.dealStatus)
      ),
    [brokerValues, formik.values.dealStatus]
  );

  const allContactImportColumns = useMemo(
    () => getAllContactImportColumns(brokerValues, clientUser),
    [brokerValues, clientUser]
  );
  const newContactImportColumns = useMemo(
    () => getNewContactImportColumns(brokerValues, clientUser),
    [brokerValues, clientUser]
  );
  const updateContactImportColumns = useMemo(
    () =>
      _.filter(allContactImportColumns, (x) =>
        ["id", ...formik.values.updatedFields].includes(x.column_name)
      ),
    [allContactImportColumns, formik.values.updatedFields]
  );

  const newCompanyImportColumns = useMemo(
    () => getNewCompanyImportColumns(),
    []
  );
  const newActivityImportColumns = useMemo(
    () => getNewActivityImportColumns(brokerValues, clientUser),
    [brokerValues, clientUser]
  );

  const updatedFieldOptions: SimpleOption[] = useMemo(
    () =>
      _.map(
        _.filter(
          allContactImportColumns,
          (x) => !["id"].includes(x.column_name) && !!_.get(x, "_updates_field")
        ),
        (x) => ({ key: x.column_name, label: x.display_label })
      ),
    [allContactImportColumns]
  );

  const updatedFieldKeys = useMemo(
    () =>
      _.map(formik.values.updatedFields, (x) =>
        _.get(
          _.find(allContactImportColumns, { column_name: x }),
          "_updates_field",
          ""
        )
      ),
    [formik.values.updatedFields]
  );

  const importSettings: ImportSettings = useMemo(() => {
    switch (formik.values.importType) {
      case ImportType.deal:
        return {
          licenseKey: config.csvBoxConfig.newDealImportLicenseKey,
          importButtonKey: `${formik.values.username}_${formik.values.dealStatus}`,
          dynamicColumns: newDealImportColumns,
          postUrl: `/data_management/import/deal/`,
          successMessage: `Deals imported successfully`,
          errorMessage: `An error occurred.`,
        };
      case ImportType.contact:
        return {
          licenseKey: config.csvBoxConfig.newContactImportLicenseKey,
          importButtonKey: `${formik.values.username}_contacts`,
          dynamicColumns:
            formik.values.actionType === ActionType.upsert
              ? updateContactImportColumns
              : newContactImportColumns,
          postUrl:
            formik.values.actionType === ActionType.upsert
              ? `/data_management/import/contact_update/`
              : `/data_management/import/contact/`,
          successMessage: `Contacts imported successfully`,
          errorMessage: `An error occurred.`,
        };
      case ImportType.company:
        return {
          licenseKey: config.csvBoxConfig.newCompanyImportLicenseKey,
          importButtonKey: `${formik.values.username}_companies`,
          dynamicColumns: newCompanyImportColumns,
          postUrl: `/data_management/import/company/`,
          successMessage: `Companies imported successfully`,
          errorMessage: `An error occurred.`,
        };
      case ImportType.activity:
        return {
          licenseKey: config.csvBoxConfig.newActivityImportLicenseKey,
          importButtonKey: `${formik.values.username}_activities`,
          dynamicColumns: newActivityImportColumns,
          postUrl: `/data_management/import/activity/`,
          successMessage: `Activities imported successfully`,
          errorMessage: `An error occurred.`,
        };
      default:
        return {
          licenseKey: "",
          importButtonKey: "",
          dynamicColumns: [],
          postUrl: "",
          successMessage: "",
          errorMessage: "",
        };
    }
  }, [
    formik.values,
    newDealImportColumns,
    newContactImportColumns,
    newCompanyImportColumns,
  ]);

  const handleImport = (result: boolean, data: { row_success: string }) => {
    setImportResult(null);

    if (result) {
      dealService
        .post(importSettings.postUrl, data)
        .then(() => {
          setImportResult("success");
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 502) {
            setImportResult("timeout");
          } else {
            setImportResult("error");
          }
          Sentry.captureMessage(
            `${importSettings.errorMessage} Error: ${error}`
          );
        })
        .finally(() => setButtonLoading(false));
    } else {
      dispatch(openErrorNotification(importSettings.errorMessage));
    }
  };

  const buttonText = useMemo(() => {
    const actionText =
      formik.values.actionType === ActionType.upsert
        ? "Bulk Edit"
        : "Import New";
    const importTypeText = _.get(RecordMetadata, [
      formik.values.importType,
      "displayNamePlural",
    ]);

    const forUserText = clientUser?.username
      ? `for ${clientUser?.first_name ?? ""} ${clientUser?.last_name ?? ""}`
      : "";

    return `${actionText} ${importTypeText} ${forUserText}`;
  }, [formik.values, clientUser]);

  return (
    <Container maxWidth="md">
      {featureEnabled && (
        <Paper elevation={3} sx={{ p: 3, mt: 5 }}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <form onSubmit={formik.handleSubmit}>
                <Stack>
                  <InputLabel htmlFor={"dealStatus"}>Import Type</InputLabel>
                  <ToggleButtonGroup
                    id={"importType"}
                    value={formik.values.importType}
                    exclusive
                    onChange={(
                      event: React.MouseEvent<HTMLElement>,
                      newAlignment: DealStatus | null
                    ) => {
                      if (newAlignment !== null) {
                        formik.setFieldValue("importType", newAlignment);
                      }
                    }}
                    size={"large"}
                  >
                    <ToggleButton value={ImportType.deal} aria-label="first">
                      Deals
                    </ToggleButton>
                    <ToggleButton
                      value={ImportType.contact}
                      aria-label="second"
                    >
                      Contacts
                    </ToggleButton>
                    <ToggleButton value={ImportType.company} aria-label="third">
                      Companies
                    </ToggleButton>
                    <ToggleButton
                      value={ImportType.activity}
                      aria-label="fourth"
                    >
                      Activities
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <br />
                  <Divider />
                  <br />
                  {formik.values.importType === ImportType.contact && (
                    <Stack direction={"row"} spacing={2} sx={{ pb: 1 }}>
                      <Box>
                        <InputLabel htmlFor={"actionType"}>
                          New or Update
                        </InputLabel>
                        <ToggleButtonGroup
                          id={"actionType"}
                          value={formik.values.actionType}
                          exclusive
                          onChange={(
                            event: React.MouseEvent<HTMLElement>,
                            newAlignment: boolean
                          ) => {
                            if (newAlignment !== null) {
                              formik.setFieldValue("actionType", newAlignment);
                            }
                          }}
                          size={"large"}
                        >
                          <ToggleButton
                            value={ActionType.insert}
                            aria-label="first"
                          >
                            New
                          </ToggleButton>
                          <ToggleButton
                            value={ActionType.upsert}
                            aria-label="second"
                          >
                            Update
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                      {formik.values.actionType === ActionType.upsert && (
                        <Box>
                          <InputLabel>Fields to Update</InputLabel>
                          <FilterAutocomplete
                            label={"Fields to Update"}
                            value={formik.values.updatedFields}
                            onChange={(value) =>
                              formik.setFieldValue("updatedFields", value)
                            }
                            options={updatedFieldOptions}
                            isFilterBar={false}
                          />
                        </Box>
                      )}
                    </Stack>
                  )}
                  {formik.values.importType === ImportType.deal && (
                    <Fragment>
                      <Link
                        href="https://drive.google.com/drive/folders/1QoTubTDMoQbPARMSuTN__FRlKyR-25S_?usp=drive_link"
                        target="_blank"
                        underline="hover"
                        sx={{ fontWeight: 600 }}
                      >
                        Import Templates
                      </Link>
                      <br />
                      <InputLabel htmlFor={"dealStatus"}>
                        Deal Status
                      </InputLabel>
                      <ToggleButtonGroup
                        id={"dealStatus"}
                        value={formik.values.dealStatus}
                        exclusive
                        onChange={(
                          event: React.MouseEvent<HTMLElement>,
                          newAlignment: DealStatus | null
                        ) => {
                          if (newAlignment !== null) {
                            formik.setFieldValue("dealStatus", newAlignment);
                          }
                        }}
                      >
                        <ToggleButton
                          value={DealStatus.closed}
                          aria-label="first"
                        >
                          Closed
                        </ToggleButton>
                        <ToggleButton
                          value={DealStatus.open}
                          aria-label="second"
                        >
                          Open
                        </ToggleButton>
                        <ToggleButton
                          value={DealStatus.lost}
                          aria-label="third"
                        >
                          Lost
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <br />
                    </Fragment>
                  )}
                  <InputLabel htmlFor={"username"} required={true}>
                    Client Username
                  </InputLabel>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <TextField
                      name="username"
                      onChange={formik.handleChange}
                      value={formik.values.username}
                      required={true}
                    ></TextField>
                    <Box>
                      <LoadingButton variant={"contained"} type={"submit"}>
                        Load
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Stack>
              </form>
            </Grid>
            {!!orgMembers.length && (
              <Grid item xs={12}>
                Org members:{" "}
                {_.map(
                  orgMembers,
                  (x, idx) =>
                    `${x.first_name} ${x.last_name}${idx === orgMembers.length - 1 ? "" : ", "}`
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Box>
                  {importResult === "success" && (
                    <Alert color={"success"}>
                      {importSettings.successMessage}
                    </Alert>
                  )}
                  {importResult === "timeout" && (
                    <Alert color={"warning"} severity={"info"}>
                      A timeout occurred.
                    </Alert>
                  )}
                  {importResult === "error" && (
                    <Alert color={"error"} severity={"error"}>
                      {importSettings.errorMessage}
                    </Alert>
                  )}
                </Box>
                <WrappedCSVBoxButton
                  key={importSettings.importButtonKey}
                  licenseKey={importSettings.licenseKey}
                  username={formik.values.username}
                  clientUser={clientUser ?? null}
                  onImport={handleImport}
                  dynamicColumns={importSettings.dynamicColumns}
                  importDisabled={
                    !orgMembers.length ||
                    !clientUser ||
                    !clientUser?.id ||
                    !clientUser?.hierarchy_id ||
                    !clientUser?.organization_id
                  }
                  buttonLoading={buttonLoading}
                  setButtonLoading={setButtonLoading}
                  buttonText={buttonText}
                  updatedFields={updatedFieldKeys}
                />
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Container>
  );
};

export default UploadCSV;

import _ from "lodash";

import useAuth from "hooks/useAuth";
import { getUserFullName } from "pages/deal/utils/deal";
import { SimpleOption } from "types/api/deal/form";

export const useGetCurrentUserAsOption = (): SimpleOption => {
  const { user } = useAuth();

  return {
    key: _.toInteger(user?.user_id),
    label: getUserFullName(user),
  };
};

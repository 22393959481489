import { Search } from "@mui/icons-material";
import {
  AutocompleteProps,
  Checkbox,
  CircularProgress,
  Link,
  TextField,
} from "@mui/material";
import Autocomplete, {
  autocompleteClasses,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import { AxiosInstance } from "axios";
import { useField, useFormikContext } from "formik";
import _ from "lodash";
import { Fragment, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useAsyncDebounce } from "react-table";

import { CreoneField } from "components/form/basic/creone_field";
import { getBaseQueryOptionsService } from "pages/deal/utils/api";
import {
  parseDealOptionAsSimpleOption,
  parseRecordAsSimpleOption,
} from "pages/deal/utils/deal_form";
import { startEditingRecord } from "store/reducers/record";
import { RecordActionResponse } from "types/api/deal/api";
import { ContactCreate } from "types/api/deal/contact";
import { SimpleOption } from "types/api/deal/form";
import { Hierarchy } from "types/api/user_management/organization";
import { Role } from "types/api/user_management/user";
import { Company } from "types/company";
import { DealOption } from "types/dealOption";
import { FormIdentifier } from "types/record";
import { FieldComponentProps } from "types/standardForm";
import { axiosUserServices, dealService } from "utils/axios";
import { createCompanyAsync } from "utils/company";
import { createSimpleContactAsync } from "utils/contact";
import { createDealOption } from "utils/dealOption";

// Backend vs Frontend filtering logic
const filter = createFilterOptions();

const filterOptionsFrontendAddNew: AutocompleteProps<
  any,
  any,
  any,
  any
>["filterOptions"] = (options, params) => {
  const filtered = filter(options, params);

  const { inputValue } = params;
  // Suggest the creation of a new value
  const isExisting = options.some(
    (option) => _.toLower(`${inputValue}`) === _.toLower(`${option?.label}`)
  );
  if (inputValue !== "" && !isExisting) {
    const addValueText = `+ Add "${inputValue}"`;
    filtered.push({
      inputValue: inputValue,
      label: addValueText,
      key: null,
    });
  }
  return filtered;
};

const filterOptionsFrontend: AutocompleteProps<
  any,
  any,
  any,
  any
>["filterOptions"] = (options, params) => {
  return filter(options, params);
};

const filterOptionsBackend = (x: any) => x;

type addNewValueFuncType = (
  newValue: string,
  setValue: (value: any) => void,
  refetch: () => void,
  setInputVal: (value: string) => void
) => void;

function BaseLookupField(
  props: FieldComponentProps & {
    dataOptions: any;
    dataIsLoading: any;
    refetch: () => void;
    showLabel?: boolean;
    showError?: boolean;
    onInputChange?: (x: any) => void;
    addNewValue?: addNewValueFuncType;
    openRecord?: (id: number) => void;
    multiple?: boolean;
    backendFiltering?: boolean;
  }
) {
  const {
    dataIsLoading,
    dataOptions,
    refetch,
    onInputChange,
    addNewValue,
    openRecord,
    multiple = false,
    backendFiltering = true,
  } = props;

  const { setFieldValue, values, errors } = useFormikContext();
  const [field, , helpers] = useField(props.fieldName);
  const { value: fieldValue } = field;
  const { setValue, setTouched } = helpers;

  const [open, setOpen] = useState(false);
  const [inputVal, setInputVal] = useState("");

  const loading = open && dataIsLoading;

  const showAsLink = useMemo(() => typeof openRecord === "function", []);
  const searchIconClasses = showAsLink
    ? { [`& .${autocompleteClasses.popupIndicator}`]: { transform: "none" } }
    : {};
  const autocreateValue = useMemo(
    () => typeof addNewValue === "function" && multiple,
    []
  );

  // Single vs Multiple value set
  const setValueSingle = (value: any) => {
    // Execute additional change handler if exists
    if (typeof props.additionalChangeHandler === "function") {
      // @ts-ignore
      props.additionalChangeHandler(value, setFieldValue, fieldValue, values);
    }
    // Set field value
    setValue(value);
  };

  const setValueMultiple = (value: any) => {
    let newVal = value;
    // Adjust multiple value to match expected
    if (!_.isNil(value) && !_.isArray(value)) {
      if (_.isNil(fieldValue)) {
        newVal = [value];
      } else {
        newVal = [...fieldValue, value];
      }
    }

    // Execute additional change handler if exists
    if (typeof props.additionalChangeHandler === "function") {
      // @ts-ignore
      props.additionalChangeHandler(newVal, setFieldValue, fieldValue, values);
    }
    // Set field value
    setValue(newVal);
  };

  // Single vs Multiple onChange
  const onChangeSingle: AutocompleteProps<any, any, any, any>["onChange"] = (
    event,
    newValue,
    reason,
    details
  ) => {
    if (newValue && _.isNil(newValue.key) && !_.isNil(newValue.inputValue)) {
      // "Add ..." was selected. Use the value from the inputValue state.
      if (typeof addNewValue === "function") {
        addNewValue(
          _.trim(newValue.inputValue),
          setValueSingle,
          refetch,
          setInputVal
        );
      } else {
        console.error("Empty value selected.");
      }
    } else {
      // Existing option value was selected or cleared
      setValueSingle(newValue);
    }
  };

  const onChangeMultiple: AutocompleteProps<any, any, any, any>["onChange"] = (
    event,
    newValue,
    reason,
    details
  ) => {
    // Get the last option added (supports single and multiple cases)
    const selectedValue = details?.option ?? null;

    if (
      selectedValue &&
      _.isNil(selectedValue.key) &&
      !_.isNil(selectedValue.inputValue)
    ) {
      // "Add ..." was selected. Use the value from the inputValue state.
      if (typeof addNewValue === "function") {
        addNewValue(
          _.trim(selectedValue.inputValue),
          setValueMultiple,
          refetch,
          setInputVal
        );
      } else {
        console.error("Empty value selected.");
      }
    } else if (selectedValue && typeof selectedValue === "string") {
      // String value was selected on blur
      if (typeof addNewValue === "function") {
        addNewValue(
          _.trim(selectedValue),
          setValueMultiple,
          refetch,
          setInputVal
        );
      } else {
        console.error("Empty value selected.");
      }
    } else {
      // Existing option value was selected or cleared
      setValueMultiple(newValue);
    }
  };

  const onChange = useMemo(
    () => (multiple ? onChangeMultiple : onChangeSingle),
    [multiple, onChangeMultiple, onChangeSingle]
  );

  const filterOptions = useMemo(
    () =>
      backendFiltering
        ? filterOptionsBackend
        : typeof addNewValue === "function"
          ? filterOptionsFrontendAddNew
          : filterOptionsFrontend,
    [backendFiltering, addNewValue]
  );

  return (
    <Fragment>
      <CreoneField {...props}>
        <Autocomplete
          id={`${props.fieldName}-autocomplete`}
          multiple={multiple}
          size={"medium"}
          autoHighlight
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          freeSolo={autocreateValue}
          disableCloseOnSelect={multiple}
          onKeyDown={(event) => {
            if (event.key === "Tab") {
              event.key = "Enter"; // will call `onChange`
            } else if (
              showAsLink &&
              event.key === "Backspace" &&
              fieldValue &&
              inputVal === ""
            ) {
              // Clear the field value and input value
              // @ts-ignore Ignore null synthetic event
              onChange(null, null, "clear");
            }
          }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          value={fieldValue}
          inputValue={inputVal}
          onInputChange={(event, newInputValue, reason) => {
            let val = newInputValue;
            if (showAsLink) {
              val = reason === "reset" ? "" : newInputValue;
            }
            setInputVal(val);
            if (backendFiltering && typeof onInputChange === "function")
              onInputChange(val);
          }}
          onChange={onChange}
          filterOptions={filterOptions}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          getOptionLabel={(option) => {
            // e.g. value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.label;
          }}
          options={dataOptions}
          loading={loading}
          disabled={props.disabled}
          onBlur={(event) => {
            if (autocreateValue && inputVal && _.trim(inputVal)) {
              // @ts-ignore
              onChange(null, null, "blur", { option: inputVal });
            }
            setTouched(true);
          }}
          popupIcon={showAsLink ? <Search /> : undefined}
          renderInput={(params) => (
            <TextField
              {...params}
              size={"medium"}
              sx={{ minWidth: 150 }}
              placeholder={
                fieldValue && showAsLink ? undefined : props.displayName
              }
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <Fragment>
                    {!!fieldValue && showAsLink && (
                      <Link
                        key={_.get(fieldValue, "key", null)}
                        onClick={() =>
                          typeof openRecord === "function" &&
                          openRecord(_.toNumber(_.get(fieldValue, "key", null)))
                        }
                        sx={{ fontWeight: 600, cursor: "pointer", ml: 1 }}
                      >
                        {_.get(fieldValue, "label", null)}
                      </Link>
                    )}
                    {params.InputProps.startAdornment}
                  </Fragment>
                ),
                endAdornment: (
                  <Fragment>
                    {loading ? <CircularProgress color="inherit" /> : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              {multiple && (
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
              )}
              {option.label}
            </li>
          )}
          sx={{
            "& .MuiAutocomplete-tag": {
              bgcolor: "primary.lighter",
              border: "1px solid",
              borderColor: "primary.light",
              "& .MuiSvgIcon-root": {
                color: "primary.main",
                "&:hover": {
                  color: "primary.dark",
                },
              },
            },
            ...searchIconClasses,
          }}
        />
      </CreoneField>
    </Fragment>
  );
}

function LookupField(
  props: FieldComponentProps & {
    showLabel?: boolean;
    showError?: boolean;
    service: AxiosInstance;
    loadUrl: string;
    addNewValue?: addNewValueFuncType;
    openRecord?: (id: number) => void;
    multiple?: boolean;
    backendFiltering?: boolean;
    params?: Record<string, string>;
  }
) {
  const { loadUrl, service, params = {} } = props;
  const [debouncedInputValue, setDebouncedInputValue] = useState("");

  const onInputChange = useAsyncDebounce((value) => {
    setDebouncedInputValue(value || "");
  }, 200);

  const queryParams = debouncedInputValue
    ? new URLSearchParams({ search: debouncedInputValue, ...params })
    : new URLSearchParams({ ...params });

  // Locate the option field name, even if nested
  const queryOptions = useMemo(
    () =>
      getBaseQueryOptionsService<Array<SimpleOption>>(
        service,
        loadUrl,
        queryParams
      ),
    [queryParams]
  );

  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery<Array<SimpleOption>>(queryOptions);

  return (
    <BaseLookupField
      {...props}
      dataOptions={data}
      dataIsLoading={isLoading}
      onInputChange={onInputChange}
      refetch={refetch}
    />
  );
}

export function ContactLookupField(
  props: FieldComponentProps & { isProComponent?: boolean; showLabel?: boolean }
) {
  const addNewValue: addNewValueFuncType = async (
    newValue,
    setValue,
    refetch,
    setInputVal
  ) => {
    if (newValue) {
      // Split the value by spaces
      const names = _.split(newValue.trim(), " ");
      // First name is the first element
      const firstName = names[0] || "";
      // Last name is the rest of the elements joined back into a string
      const lastName = names.length > 1 ? names.slice(1).join(" ") : "";

      const incomingChanges = { first_name: firstName, last_name: lastName };

      // Create new contact silently
      const recordActionResponses: RecordActionResponse[] =
        await createSimpleContactAsync(incomingChanges as ContactCreate);

      const newOptionValue: SimpleOption = {
        key: _.get(recordActionResponses, "0.id", null),
        label: _.get(recordActionResponses, "0.name", ""),
      };

      setInputVal("");
      setValue(newOptionValue);
    } else {
      startEditingRecord(
        null,
        FormIdentifier.ContactNameOnlyForm,
        {},
        false,
        (recordActionResponses) => {
          const newOptionValue: SimpleOption = {
            key: _.get(recordActionResponses, "0.id", null),
            label: _.get(recordActionResponses, "0.name", ""),
          };

          setValue(newOptionValue);
        }
      );
    }
  };

  const openRecord = (id: number) =>
    startEditingRecord(id, FormIdentifier.ContactForm);

  return (
    <LookupField
      {...props}
      service={dealService}
      loadUrl={"/form/lookup/contact?add_new=true"}
      addNewValue={addNewValue}
      openRecord={openRecord}
    />
  );
}

export function CompanyLookupField(
  props: FieldComponentProps & { isProComponent?: boolean; showLabel?: boolean }
) {
  const addNewValue: addNewValueFuncType = async (
    newValue,
    setValue,
    refetch,
    setInputVal
  ) => {
    if (newValue) {
      const incomingChanges = { name: newValue };
      // Create new company silently
      const recordActionResponses = await createCompanyAsync(
        incomingChanges as Company
      );
      const newOptionValue: SimpleOption = {
        key: _.get(recordActionResponses, "0.id", null),
        label: _.get(recordActionResponses, "0.name", ""),
      };

      setInputVal("");
      setValue(newOptionValue);
    } else {
      // Open new company form
      startEditingRecord(
        null,
        FormIdentifier.CompanyForm,
        {},
        false,
        (recordActionResponses) => {
          const newOptionValue: SimpleOption = {
            key: _.get(recordActionResponses, "0.id", null),
            label: _.get(recordActionResponses, "0.name", ""),
          };
          setValue(newOptionValue);
        }
      );
    }
  };

  const openRecord = (id: number) =>
    startEditingRecord(id, FormIdentifier.CompanyForm);

  return (
    <LookupField
      {...props}
      service={dealService}
      loadUrl={"/form/lookup/company?add_new=true"}
      addNewValue={addNewValue}
      openRecord={openRecord}
    />
  );
}

export function UserLookupField(
  props: FieldComponentProps & {
    isProComponent?: boolean;
    showLabel?: boolean;
    showError?: boolean;
  }
) {
  return (
    <LookupField
      {...props}
      service={axiosUserServices}
      loadUrl={"/organization/lookup/user"}
      backendFiltering={false}
    />
  );
}

export function UserLookupFieldWithOther(
  props: FieldComponentProps & {
    isProComponent?: boolean;
    showLabel?: boolean;
    showError?: boolean;
  }
) {
  return (
    <LookupField
      {...props}
      service={axiosUserServices}
      loadUrl={"/organization/lookup/user"}
      backendFiltering={false}
      params={{ include_other: "true" }}
    />
  );
}

export function UserLookupFieldMultiple(
  props: FieldComponentProps & {
    isProComponent?: boolean;
    showLabel?: boolean;
    showError?: boolean;
  }
) {
  return (
    <LookupField
      {...props}
      service={axiosUserServices}
      loadUrl={"/organization/lookup/user"}
      backendFiltering={false}
      multiple={true}
    />
  );
}

export function TeamLookupField(
  props: FieldComponentProps & { isProComponent?: boolean; showLabel?: boolean }
) {
  const addNewValue: addNewValueFuncType = async (
    newValue,
    setValue,
    refetch,
    setInputVal
  ) => {
    const incomingChanges = { name: newValue };

    startEditingRecord(
      null,
      FormIdentifier.TeamForm,
      incomingChanges,
      false,
      (updatedRecord) => {
        const newOptionValue = parseRecordAsSimpleOption<Hierarchy>(
          updatedRecord as Hierarchy,
          "name"
        );
        setValue(newOptionValue);
      }
    );
  };

  const openRecord = (id: number) =>
    startEditingRecord(id, FormIdentifier.TeamForm);

  return (
    <LookupField
      {...props}
      service={axiosUserServices}
      loadUrl={"/organization/lookup/team"}
      backendFiltering={false}
      addNewValue={addNewValue}
      openRecord={openRecord}
    />
  );
}

export function RoleLookupField(
  props: FieldComponentProps & { isProComponent?: boolean; showLabel?: boolean }
) {
  const addNewValue: addNewValueFuncType = async (
    newValue,
    setValue,
    refetch,
    setInputVal
  ) => {
    const incomingChanges = { name: newValue };

    startEditingRecord(
      null,
      FormIdentifier.RoleForm,
      incomingChanges,
      false,
      (updatedRecord) => {
        const newOptionValue = parseRecordAsSimpleOption<Role>(
          updatedRecord as Role,
          "name"
        );
        setValue(newOptionValue);
      }
    );
  };

  const openRecord = (id: number) =>
    startEditingRecord(id, FormIdentifier.RoleForm);

  return (
    <LookupField
      {...props}
      service={axiosUserServices}
      loadUrl={"/organization/lookup/role"}
      backendFiltering={false}
      addNewValue={addNewValue}
      openRecord={openRecord}
    />
  );
}

export function ProductLookupField(
  props: FieldComponentProps & { isProComponent?: boolean; showLabel?: boolean }
) {
  return (
    <LookupField
      {...props}
      service={axiosUserServices}
      loadUrl={"/organization/lookup/app_product"}
      backendFiltering={false}
      multiple={true}
    />
  );
}

export function OptionLookupField(
  props: FieldComponentProps & { isProComponent?: boolean; showLabel?: boolean }
) {
  // Locate the option field name, even if nested
  const optionFieldName = useMemo(
    // TODO: Refactor to remove need for followup_ replacement
    () =>
      _.replace(
        _.last(_.split(props.fieldName, ".")) ?? props.fieldName,
        "followup_",
        ""
      ),
    [props.fieldName]
  );

  const addNewValue = (
    newValue: string,
    setValue: (value: any) => void,
    refetch: () => void
  ) => {
    // Create a new value from the user input
    const newDealOption: DealOption = {
      field: optionFieldName,
      value: newValue,
    };

    createDealOption(newDealOption).then((x) => {
      const newOptionValue = parseDealOptionAsSimpleOption(x as DealOption);
      setValue(newOptionValue);
      refetch();
    });
  };

  return (
    <LookupField
      {...props}
      service={dealService}
      loadUrl={`/form/lookup/option/${optionFieldName}`}
      addNewValue={addNewValue}
      backendFiltering={false}
    />
  );
}

const MULTIPLE_OPTION_TO_FIELD_NAME_MAP = {
  submarkets: "submarket",
  property_names: "property_name",
  asset_types: "asset_type",
  groups: "group",
};

export function OptionLookupFieldMultiple(
  props: FieldComponentProps & { isProComponent?: boolean; showLabel?: boolean }
) {
  // Locate the option field name, even if nested
  const baseFieldName = useMemo(
    // TODO: Refactor to remove need for followup_ replacement
    () =>
      _.replace(
        _.last(_.split(props.fieldName, ".")) ?? props.fieldName,
        "followup_",
        ""
      ),
    [props.fieldName]
  );

  const optionFieldName = _.get(
    MULTIPLE_OPTION_TO_FIELD_NAME_MAP,
    baseFieldName
  );

  const addNewValue: addNewValueFuncType = async (
    newValue,
    setValue,
    refetch,
    setInputVal
  ) => {
    // Create a new value from the user input
    const newDealOption: DealOption = {
      field: optionFieldName,
      value: newValue,
    };

    createDealOption(newDealOption).then((x) => {
      const newOptionValue = parseDealOptionAsSimpleOption(x as DealOption);
      setValue(newOptionValue);
      refetch();
    });
  };

  return (
    <LookupField
      {...props}
      service={dealService}
      loadUrl={`/form/lookup/option/${optionFieldName}`}
      addNewValue={addNewValue}
      multiple={true}
      backendFiltering={false}
    />
  );
}

export function SimpleOptionLookupMultiple(
  props: FieldComponentProps & { isProComponent?: boolean; showLabel?: boolean }
) {
  return (
    <BaseLookupField
      {...props}
      dataOptions={props.options as SimpleOption[]}
      dataIsLoading={false}
      refetch={() => {}}
      backendFiltering={false}
      multiple={true}
    />
  );
}

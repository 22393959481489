import { EditOutlined } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Grid, IconButton, Link, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Dispatch, ReactNode, SetStateAction, useMemo } from "react";

import Avatar from "components/@extended/Avatar";
import { LightTooltip } from "pages/deal/components/InfoTooltip";

export interface TimelineEntryProps {
  datetime: ReactNode;
  color: "info" | "error" | "success" | "primary";
  avatarContents: string | ReactNode;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  activityBody: string | ReactNode;
  activityFooter?: string | ReactNode;
  activityBodyPreview?: string;
  link?: string;
  editing?: boolean;
  onEdit?: () => void;
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  isExpandable?: boolean;
  actionMenu?: ReactNode;
}

const ExpandMore = styled((props: { expand: boolean } & any) => {
  const { expand, ...other } = props;
  return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(-90deg)" : "rotate(0deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const TimelineEntry = ({
  datetime,
  color,
  avatarContents,
  title,
  subtitle,
  activityBody,
  activityBodyPreview,
  activityFooter,
  onEdit,
  expanded,
  setExpanded,
  editing = false,
  isExpandable = true,
  actionMenu,
}: TimelineEntryProps) => {
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const activityBodyContent = useMemo(() => {
    if (expanded || editing) {
      // When expanded or editing an activity, return the activity body param
      return activityBody;
    } else if (activityBodyPreview) {
      if (!isExpandable) {
        // Body is collapsed but preview is not truncated
        return activityBodyPreview;
      } else {
        // Body is collapsed and preview is truncated
        return (
          <span>
            {activityBodyPreview}...{" "}
            <Link
              component="button"
              variant="body2"
              onClick={() => setExpanded(true)}
            >
              Show More
            </Link>
          </span>
        );
      }
    } else {
      return null;
    }
  }, [expanded, editing, activityBodyPreview, activityBody, isExpandable]);

  return (
    <Grid container spacing={1} columns={24} alignItems={"flex-start"}>
      <Grid item>
        <Box sx={{ width: "40px" }}>
          <Avatar color={color}>{avatarContents}</Avatar>
        </Box>
      </Grid>
      <Grid item xs={1}>
        {isExpandable && (
          <LightTooltip title={expanded ? "Collapse" : "Expand"}>
            <div>
              <ExpandMore
                expand={expanded}
                color={"primary"}
                onClick={handleExpandClick}
                sx={{ cursor: "pointer" }}
              />
            </div>
          </LightTooltip>
        )}
      </Grid>
      <Grid item xs={true} sx={{ pt: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={true}>
            <Grid container alignItems={"baseline"} spacing={2}>
              <Grid item style={{ flexGrow: 1 }} xs>
                <Typography component="div" align="left" variant="subtitle1">
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography align="right" variant="caption" color="secondary">
                  {datetime}
                </Typography>
              </Grid>
            </Grid>
            {!!subtitle && (
              <Typography color="textSecondary" sx={{ pb: 1 }}>
                {subtitle}
              </Typography>
            )}
            <Stack direction={"column"}>
              <Typography color="textPrimary" align="left" variant="caption">
                {activityBodyContent}
              </Typography>
              {!!activityFooter && activityFooter}
            </Stack>
          </Grid>
          <Grid item xs={1}>
            {!editing && typeof onEdit === "function" && (
              <LightTooltip title={"Edit Activity"}>
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    e.preventDefault();
                    onEdit();
                  }}
                  color="primary"
                >
                  <EditOutlined />
                </IconButton>
              </LightTooltip>
            )}
            {actionMenu}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const filterEmpty = [""];
export const filterUnassigned = [null];

export enum SortOrderEnum {
  asc = "asc",
  desc = "desc",
}

export interface BaseFilter {
  search?: string | null;
  skip?: number;
  limit?: number;

  // An array of field names
  sort?: string[];
  // An array of sort orders, either asc or desc
  sort_order?: SortOrderEnum[] | typeof filterEmpty;
}

export interface BaseNavigation {
  hiddenFilters: string[];
  disabledFilters: string[];
}

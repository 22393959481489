//material-ui
import { Fade } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";

// custom styles
const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  "&.notistack-MuiContent-default": {
    backgroundColor: theme.palette.primary.main,
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: theme.palette.error.main,
  },
  "&.notistack-MuiContent-success": {
    backgroundColor: theme.palette.success.main,
  },
  "&.notistack-MuiContent-info": {
    backgroundColor: theme.palette.info.main,
  },
  "&.notistack-MuiContent-warning": {
    backgroundColor: theme.palette.warning.main,
  },
}));

// ===========================|| SNACKBAR - NOTISTACK ||=========================== //

const Notistack = ({ children }: any) => {
  return (
    <StyledSnackbarProvider
      maxSnack={3}
      dense={false}
      iconVariant={undefined}
      hideIconVariant={false}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      TransitionComponent={Fade}
    >
      {children}
    </StyledSnackbarProvider>
  );
};

export default Notistack;

import { Edit } from "@mui/icons-material";
import { Box, Button, Drawer, Stack } from "@mui/material";
import { isNumber, toNumber } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { RecordEditModal } from "modals/RecordEditModal";
import { CloseButton } from "pages/deal/components/CloseButton";
import { stopViewingDeals } from "store/reducers/dealV2";
import { clearEditingRecordStack } from "store/reducers/record";
import { FormIdentifier, RecordDetails } from "types/record";

const DealPreviewDrawer: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [recordId, setRecordId] = useState<number | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const preview = searchParams.get("preview");
    if (preview && isNumber(toNumber(preview))) {
      setRecordId(toNumber(preview));
    } else {
      setRecordId(null);
    }
  }, [searchParams]);

  const handleClose = () => {
    searchParams.delete("preview");
    searchParams.delete("type");
    setSearchParams(searchParams);
  };

  const dealRecordDetails: RecordDetails = {
    id: recordId,
    formIdentifier: FormIdentifier.DealPreviewForm,
    incomingChanges: {},
    isQuickCreate: false,
    callbackFunction: () => {},
  };

  return (
    <Drawer
      sx={{
        ml: !!recordId ? 3 : 0,
        flexShrink: 0,
        zIndex: 1301,
        overflowX: "hidden",
        width: { xs: 320, md: 400 },
        "& .MuiDrawer-paper": {
          width: { xs: 320, md: 400 },
          border: "none",
          borderRadius: "0px",
        },
      }}
      variant="temporary"
      anchor="right"
      open={!!recordId}
      ModalProps={{ keepMounted: true }}
      onClose={handleClose}
    >
      <Box px={3} py={2}>
        <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
          <Box>
            <CloseButton onClose={handleClose} />
          </Box>
        </Stack>
        <RecordEditModal
          key={"preview-record-drawer"}
          record={dealRecordDetails}
          handleClose={handleClose}
          isDialog={false}
        />
        <Stack direction={"row"} justifyContent={"flex-start"} pt={4}>
          <Box>
            <Button
              variant="outlined"
              startIcon={<Edit />}
              onClick={() => {
                stopViewingDeals();
                clearEditingRecordStack();
                navigate(`/deals/${recordId}`);
              }}
            >
              Edit
            </Button>
          </Box>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default DealPreviewDrawer;

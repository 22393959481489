import { PlusOutlined } from "@ant-design/icons";
import { Box, Button, Grid } from "@mui/material";
import _ from "lodash";
import React from "react";

export function ActivityTimeline({ children, addNewActivity }: any) {
  return (
    <>
      {!_.isEmpty(children) ? (
        <Grid
          container
          spacing={3}
          alignItems="center"
          sx={{
            position: "relative",
            "&>*": {
              position: "relative",
              zIndex: "5",
            },
            "&:after": {
              content: '""',
              position: "absolute",
              top: 8,
              left: 43,
              width: 2,
              height: "100%",
              background: "#ebebeb",
              zIndex: "1",
            },
          }}
        >
          {children}
        </Grid>
      ) : (
        <Box
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            startIcon={<PlusOutlined />}
            onClick={addNewActivity}
          >
            Add
          </Button>
        </Box>
      )}
    </>
  );
}

import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { Activity } from "types/api/deal/activity";
import { dealService } from "utils/axios";
import { openNotification } from "utils/notistack";
import { handleRecordActionResponseV4 } from "utils/record";

export async function createActivityAsync(activity: Partial<Activity>) {
  try {
    const response = await dealService.post(`/activity/`, activity);
    handleRecordActionResponseV4(response);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification("Error creating activity."));
    throw error;
  }
}

export async function updateActivityAsync(
  id: number | string,
  activity: Partial<Activity>
) {
  try {
    const response = await dealService.put(`/activity/${id}`, activity);
    handleRecordActionResponseV4(response);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification("Error updating activity."));
    throw error;
  }
}

export async function updateActivityBulkAsync(
  ids: number[],
  activity: Partial<Activity>
) {
  const requestData = {
    activity_ids: ids,
    data: activity,
  };

  try {
    await dealService.post(`/activity/bulk-update`, requestData);
    openNotification("Activities updated.");
  } catch (error) {
    dispatch(openErrorNotification("Error updating activities."));
    throw error;
  }
}

export async function deleteActivityAsync(id: number | string) {
  try {
    const response = await dealService.delete(`/activity/${id}`);
    handleRecordActionResponseV4(response);
  } catch (error) {
    dispatch(openErrorNotification("Error deleting activity."));
    throw error;
  }
}

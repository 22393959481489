import { Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { useFormikContext } from "formik";
import _ from "lodash";

import { useUsersDisplay } from "contexts/UserDisplayContext";
import { FieldComponentProps } from "types/standardForm";

export const DataDisplay = ({
  fieldName,
  displayName,
}: FieldComponentProps) => {
  const { values } = useFormikContext();
  const value = _.get(values, fieldName);

  return (
    <Stack direction={"row"} spacing={1}>
      <Typography variant={"subtitle2"}>{displayName}:</Typography>
      <Typography variant={"subtitle2"}>{value}</Typography>
    </Stack>
  );
};

export const DataUserDisplay = ({
  fieldName,
  displayName,
}: FieldComponentProps) => {
  // Get org members data
  const usersDisplay = useUsersDisplay();

  const { getFieldProps } = useFormikContext();
  const { value } = getFieldProps(fieldName);

  return (
    <Stack direction={"row"} spacing={1}>
      <Typography variant={"subtitle2"}>{displayName}:</Typography>
      <Typography variant={"subtitle2"}>
        {_.get(usersDisplay, value, "--")}
      </Typography>
    </Stack>
  );
};

export const DataDateTimeDisplay = ({
  fieldName,
  displayName,
}: FieldComponentProps) => {
  const { values } = useFormikContext();
  const value = _.get(values, fieldName);
  const formattedDateTime = format(new Date(value), "MM/dd/yyyy HH:mm:ss");

  return (
    <Stack direction={"row"} spacing={1}>
      <Typography variant={"subtitle2"}>{displayName}:</Typography>
      <Typography variant="subtitle2">
        {value ? formattedDateTime : "--"}
      </Typography>
    </Stack>
  );
};

import { BaseFilter } from "types/navigation/common";

export interface ActivityFilter extends BaseFilter {
  is_complete?: string[];
  date_range?: string[] | null;
  completed_date?: string[] | null;
  activity_owner?: (number | string)[];
}

export const defaultActivityModalColumns = [];

import { AutoFixHigh } from "@mui/icons-material";
import { Button, DialogContent, DialogTitle, Grid } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import * as Sentry from "@sentry/react";
import { useFormikContext } from "formik";
import _ from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import IconButton from "components/@extended/IconButton";
import { StandardGridField } from "components/form/forms/deal/sections/DealFormSection";
import DynamicVisibilitySection from "components/form/standard/DynamicVisibilitySection";
import StandardField from "components/form/standard/StandardField";
import { StandardForm } from "components/form/standard/StandardForm";
import { DealMetadata } from "constants/objectMetadata/dealMetadata";
import { EditingProvider } from "contexts/EditingContext";
import { Deal, DealStatus } from "types/deal";
import { StandardFormProps } from "types/record";
import { updateDealAsync } from "utils/deal";

const DealClosedFormHeader = () => {
  const [showButton, setShowButton] = useState(true);
  const { setValues } = useFormikContext();

  const handleAutoFill = () => {
    setValues((values: any) => {
      const updatedValues = {
        transaction_value: _.get(
          values,
          DealMetadata.transaction_value_est.fieldName
        ),
        commission: _.get(values, DealMetadata.commission_est.fieldName),
        size: _.get(values, DealMetadata.size_est.fieldName),
        commission_split: _.get(
          values,
          DealMetadata.commission_split.fieldName
        ).map((commission: any) => ({
          ...commission,
          actual_percent: commission.estimate_percent,
        })),
      };
      return { ...values, ...updatedValues };
    });
    setShowButton(false);
  };

  return (
    <Grid container justifyContent="space-between">
      <DialogTitle>Closed Deal</DialogTitle>
      {showButton && (
        <Button
          size="small"
          onClick={handleAutoFill}
          sx={{ textTransform: "none", pr: 2 }}
        >
          <IconButton>
            <AutoFixHigh />
          </IconButton>
          Auto-fill from estimates
        </Button>
      )}
    </Grid>
  );
};

export const DealClosedForm = ({
  record,
  incomingChanges,
  onCancel,
  successCallback,
  open,
}: StandardFormProps<Deal>) => {
  const navigate = useNavigate();

  const updateFn = async (id: number | string, deal: Partial<Deal>) => {
    try {
      const record = await updateDealAsync(id, deal, navigate);
      if (typeof successCallback === "function") successCallback(record);
    } catch (error) {
      Sentry.captureException("Unable to update a Deal actuals.");
    }
  };

  return (
    <StandardForm<Partial<Deal>>
      metadata={DealMetadata}
      record={record}
      updateFn={updateFn}
      displayName={"Notes"}
      onCancel={onCancel}
      incomingChanges={incomingChanges}
      open={open}
      fullWidth={true}
      maxWidth={"md"}
    >
      {({ StandardFormActionsInstance }) => (
        <>
          <DealClosedFormHeader />
          <DialogContent dividers>
            <Grid container columnSpacing={5} rowSpacing={4} columns={24}>
              <EditingProvider>
                <Grid item xs={24} sm={6}>
                  <StandardField field={DealMetadata.close_date_est} />
                </Grid>
                <Grid item xs={24} sm={6}>
                  <StandardField field={DealMetadata.transaction_value_est} />
                </Grid>
                <Grid item xs={24} sm={6}>
                  <StandardField field={DealMetadata.commission_est} />
                </Grid>
                <Grid item xs={24} sm={3}>
                  <StandardField field={DealMetadata.size_est} />
                </Grid>
                <Grid item xs={24} sm={3}>
                  <StandardField field={DealMetadata.size_type} />
                </Grid>
              </EditingProvider>
              <DynamicVisibilitySection
                visibilityFunction={(x) =>
                  _.get(x, "status") === DealStatus.closed
                }
              >
                <Grid item xs={24} sm={6}>
                  <StandardField field={DealMetadata.close_date} />
                </Grid>
                <Grid item xs={24} sm={6}>
                  <StandardField field={DealMetadata.transaction_value} />
                </Grid>
                <Grid item xs={24} sm={6}>
                  <StandardField field={DealMetadata.commission} />
                </Grid>
                <StandardGridField
                  item
                  xs={24}
                  sm={3}
                  field={DealMetadata.size}
                />
                <StandardGridField
                  item
                  xs={24}
                  sm={3}
                  field={DealMetadata.size_type}
                />
                <StandardGridField
                  item
                  xs={24}
                  sm={6}
                  field={DealMetadata.lease_expiration}
                />
              </DynamicVisibilitySection>
            </Grid>
            <Grid container columnSpacing={2} rowSpacing={1}>
              <StandardGridField
                item
                xs={12}
                field={DealMetadata.commission_split}
                estimateReadOnly
              />
              <StandardGridField
                item
                xs={12}
                field={DealMetadata.commission_payments}
              />
            </Grid>
          </DialogContent>
          <DialogActions>{StandardFormActionsInstance}</DialogActions>
        </>
      )}
    </StandardForm>
  );
};

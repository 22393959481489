import { Container, DialogContent, Grid } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import * as Sentry from "@sentry/react";

import StandardField from "components/form/standard/StandardField";
import { StandardForm } from "components/form/standard/StandardForm";
import MainCard from "components/MainCard";
import { CommissionGoalMetadata } from "pages/deal/constants/dealCommissionGoal";
import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { CommissionGoal } from "types/dealCommissionGoal";
import { FormIdentifier, StandardFormProps } from "types/record";
import { dealService } from "utils/axios";
import { handleRecordActionResponseV2 } from "utils/record";

export const CommissionGoalForm = ({
  record,
  incomingChanges,
  successCallback,
  onCancel,
  open,
}: StandardFormProps<CommissionGoal>) => {
  const createFn = async (commission_goal: CommissionGoal) => {
    try {
      const response = await dealService.post(
        `/commission_goal/`,
        commission_goal
      );
      handleRecordActionResponseV2(
        "Commission Goal",
        "created",
        FormIdentifier.CommissionGoalForm,
        response
      );
      if (typeof successCallback === "function") {
        successCallback(response.data.data);
      }
    } catch (error) {
      dispatch(openErrorNotification(`Error creating Commission Goal.`));
      Sentry.captureException("Unable to create Commission Goal.");
    }
  };

  const updateFn = async (id: number, commission_goal: CommissionGoal) => {
    try {
      const response = await dealService.put(
        `/commission_goal/${id}`,
        commission_goal
      );
      handleRecordActionResponseV2(
        "Commission Goal",
        "updated",
        FormIdentifier.CommissionGoalForm,
        response
      );
      if (typeof successCallback === "function") {
        successCallback(response.data.data);
      }
    } catch (error) {
      dispatch(openErrorNotification(`Error updating Commission Goal.`));
      Sentry.captureException("Unable to update Commission Goal.");
    }
  };

  return (
    <StandardForm<CommissionGoal>
      metadata={CommissionGoalMetadata}
      record={record}
      updateFn={updateFn}
      createFn={createFn}
      displayName={"Commission Goal"}
      onCancel={onCancel}
      incomingChanges={incomingChanges}
      open={open}
    >
      {({ StandardFormActionsInstance }) => (
        <>
          <DialogContent>
            <Container sx={{ py: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MainCard title={"Commission Goal Details"}>
                    <Grid container columnSpacing={2} rowSpacing={1}>
                      <Grid item xs={12} sm={6}>
                        <StandardField
                          field={CommissionGoalMetadata.goal_amount}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <StandardField
                          field={CommissionGoalMetadata.goal_deals}
                        />
                      </Grid>
                    </Grid>
                  </MainCard>
                </Grid>
              </Grid>
            </Container>
          </DialogContent>
          <DialogActions>{StandardFormActionsInstance}</DialogActions>
        </>
      )}
    </StandardForm>
  );
};

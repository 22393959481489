import { Check } from "@mui/icons-material";
import { Stack } from "@mui/material";

import { activityTableColumns } from "components/table/columns/activity";
import {
  companyTableColumns,
  defaultCompanyTableColumns,
} from "components/table/columns/company";
import { contactTableColumns } from "components/table/columns/contact";
import { prospectTableColumns } from "components/table/columns/prospect";
import { RecordMetadata } from "constants/record";
import { defaultActivityModalColumns } from "types/navigation/activityNavigation";
import { FormIdentifier } from "types/record";
import { TableIdentifier, TableMapEntry } from "types/tableView";

// Defining the type for the entire tableIdentifierMap
export type TableIdentifierMapType = {
  [key in TableIdentifier]: TableMapEntry;
};

export const tableIdentifierMap: TableIdentifierMapType = {
  [TableIdentifier.ActivityCompletedTasks]: {
    getColumns: activityTableColumns,
    loadUrl: RecordMetadata.activity.dataUrl,
    editFormIdentifier: FormIdentifier.ActivityForm,
    title: (
      <Stack direction={"row"}>
        <Check color={"primary"} />
        {"\u00A0"}
        {"\u00A0"} Completed Activities
      </Stack>
    ),
    defaultColumns: defaultActivityModalColumns,
  },
  [TableIdentifier.ActivityQuickView]: {
    getColumns: activityTableColumns,
    loadUrl: RecordMetadata.activity.dataUrl,
    editFormIdentifier: FormIdentifier.ActivityForm,
    title: RecordMetadata.activity.displayNamePlural,
    defaultColumns: defaultActivityModalColumns,
  },
  [TableIdentifier.ContactQuickView]: {
    getColumns: contactTableColumns,
    loadUrl: RecordMetadata.contact.dataUrl,
    editFormIdentifier: FormIdentifier.ContactForm,
    title: RecordMetadata.contact.displayNamePlural,
    defaultColumns: [],
  },
  [TableIdentifier.ProspectQuickView]: {
    getColumns: prospectTableColumns,
    loadUrl: RecordMetadata.contact.dataUrl,
    editFormIdentifier: FormIdentifier.ContactForm,
    title: RecordMetadata.prospect.displayNamePlural,
    defaultColumns: [],
  },
  [TableIdentifier.CompanyQuickView]: {
    getColumns: companyTableColumns,
    loadUrl: RecordMetadata.company.dataUrl,
    editFormIdentifier: FormIdentifier.CompanyForm,
    title: RecordMetadata.company.displayNamePlural,
    defaultColumns: defaultCompanyTableColumns,
  },
};

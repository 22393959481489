import { UserOutlined } from "@ant-design/icons";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";

import Avatar from "components/@extended/Avatar";
import { TimelineEntry } from "components/form/forms/sections/ActivityTimeline/TimelineEntry";
import { useCounter } from "contexts/CounterContext";
import { useUsersDisplay } from "contexts/UserDisplayContext";
import { RecordEditModal } from "modals/RecordEditModal";
import { LightTooltip } from "pages/deal/components/InfoTooltip";
import {
  formatDateString,
  formatDateTimeString,
} from "pages/deal/utils/reporting";
import { getActivityPreviewFormat } from "pages/prospects/utils/activity";
import { Activity, ActivityRead } from "types/api/deal/activity";
import { FormIdentifier, RecordDetails } from "types/record";

interface EditableActivityTimelineEntryProps {
  record: ActivityRead | Partial<Activity>;
  defaultExpanded: boolean;
}

export const EditableActivityTimelineEntry = ({
  record,
  defaultExpanded,
}: EditableActivityTimelineEntryProps) => {
  // Get org members data
  const usersDisplay = useUsersDisplay();
  const {
    increment: incrementOpenActivityCount,
    decrement: decrementOpenActivityCount,
  } = useCounter();

  const [expanded, setExpanded] = useState(defaultExpanded);
  const [editing, setEditing] = useState(false);
  const {
    datetime,
    ownerName,
    avatarContents,
    title,
    activityBodyPreview,
    activityBody,
  } = getActivityPreviewFormat(record, usersDisplay);

  const onEdit = () => {
    setEditing(true);
    incrementOpenActivityCount();
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const activityRecordDetails: RecordDetails = {
    id: _.get(record, "id", null),
    formIdentifier: FormIdentifier.ActivityForm,
    incomingChanges: {},
    isQuickCreate: false,
    callbackFunction: () => {},
  };

  const handleClose = () => {
    setEditing(false);
    decrementOpenActivityCount();
  };

  const isExpandable = useMemo(
    () => !editing && _.size(activityBody) !== _.size(activityBodyPreview),
    [editing, activityBody, activityBodyPreview]
  );

  return (
    <TimelineEntry
      datetime={
        editing ? null : (
          <Stack
            direction={"row"}
            spacing={1}
            onClick={isExpandable ? handleExpandClick : undefined}
            sx={{ cursor: isExpandable ? "pointer" : "inherit" }}
          >
            {!!ownerName && (
              <LightTooltip
                title={
                  <div onClick={(event) => event.stopPropagation()}>
                    Activity Owner
                  </div>
                }
              >
                <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                  <Avatar alt={ownerName ?? ""} size={"xs"}>
                    <UserOutlined />
                  </Avatar>
                  <Box sx={{ pt: 0.25 }}>{ownerName}</Box>
                </Stack>
              </LightTooltip>
            )}
            {!!datetime && (
              <LightTooltip
                title={
                  <div onClick={(event) => event.stopPropagation()}>
                    {formatDateTimeString(datetime)}
                  </div>
                }
              >
                <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                  <Box sx={{ pt: 0.25 }}>{formatDateString(datetime)}</Box>
                </Stack>
              </LightTooltip>
            )}
          </Stack>
        )
      }
      color={"primary"}
      avatarContents={avatarContents}
      title={
        editing ? null : isExpandable ? (
          <Box onClick={handleExpandClick} sx={{ cursor: "pointer" }}>
            {title}
          </Box>
        ) : (
          <Box>{title}</Box>
        )
      }
      activityBody={
        editing ? (
          <RecordEditModal
            key={"test"}
            record={activityRecordDetails}
            handleClose={handleClose}
            isDialog={false}
          />
        ) : (
          activityBody
        )
      }
      activityBodyPreview={activityBodyPreview}
      expanded={expanded}
      setExpanded={setExpanded}
      onEdit={onEdit}
      editing={editing}
      isExpandable={isExpandable}
    />
  );
};
